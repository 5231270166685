import * as registrationsActions from '../../../constants/registrations.registrations.actions';

export default function registrations(state, action){
    const n_state = { ...state };
    switch(action.type){
        case registrationsActions.UPDATE_REGISTRATIONS:
        case registrationsActions.GET_REGISTRATIONS:
            return { ...state, registrations : { ...state.registrations, ...action.payload } }

        case registrationsActions.DELETE_REGISTRATIONS:
            action.payload.forEach((RFID) => {
                if(RFID in n_state.registrations){
                    state.registrants.filter((registrant) => registrant.registrationRelationID === RFID).forEach((registrant) => {
                        delete n_state.registrants[registrant.RID];
                    });
                    delete n_state.registrations[RFID];
                }
            });
            return n_state;
        
        default:
            return state;
    }
}