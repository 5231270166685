import * as globalsActions from "../constants/registrations.globals.actions"

export const getRegistrationsAndRegistrantsAndPayments__action = (data) => {
    return {
        type : globalsActions.GET_REGISTRATIONS_AND_REGISTRANTS_AND_PAYMENTS,
        payload : data
    }
}

export const updateRegistrationsAndRegistrantsAndPayments__action = (data) => {
    return {
        type : globalsActions.UPDATE_REGISTRATIONS_AND_REGISTRANTS_AND_PAYMENTS,
        payload : data
    }
}

export const deleteRegistrationsAndRegistrantsAndPayments__action = (data) => {
    return {
        type : globalsActions.DELETE_REGISTRATIONS_AND_REGISTRANTS_AND_PAYMENTS,
        payload : data
    }
}