import { apiRequest } from "../../functions/api";

export const getSejoursModels = (data) => {
    return apiRequest({
            url : '/sejours.models/get',
            data
        }).then((res) => {
            if(res.data.http_code === 200 && res.data.status === "success"){
                return res.data.data;
            }
        })
        .catch(() => {});
}