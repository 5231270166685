import React, { Component } from 'react';
import { Breadcrumb } from 'react-bootstrap';

class CBreadcrumb extends Component{
    getProps(){
        const props = { ...this.props };
        delete props.children;

        props.className += props.className ? " breadcrumb" : "breadcrumb";

        return props;
    }
    render(){
        return(<nav aria-label="breadcrumb">
            <ol {...this.getProps()}>
                {this.props.children}
            </ol>
        </nav>)
    }
    
}

export default CBreadcrumb
