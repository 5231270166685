import React, { Component } from "react";
import { Route, Switch, withRouter } from "react-router-dom";
import RegistrationsPayments from "./payments";
import RegistrationsRegistrants from "./registrants";
import RegistrationDetailsPage from "./registration";

class RegistrationsRouter extends Component{
    constructor(props){
        super(props);
    }
    render(){
        return(
            <Switch>
                <Route exact path={`${this.props.path}/payments`} component={RegistrationsPayments} />
                <Route exact path={`${this.props.path}/registrants`} component={RegistrationsRegistrants} />
                <Route path={`${this.props.path}/:RFID`} component={RegistrationDetailsPage} />
            </Switch>
        )
    }
    
}

export default RegistrationsRouter;