import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { Form, Button, Alert } from 'react-bootstrap';
import { isEmpty } from '../../../functions/common';
import { apiRequest } from '../../../functions/api';
import { connect } from 'react-redux';
import { setUser } from '../../../actions/app.actions';
import LoaderBox from '../../shared/loaders/loader.box';
import { CForm } from '../../custom-boostrap/CForm/index.js';

class Login extends Component {
    constructor(props) {
        super(props);
        this.state = {
            email : '',
            password : '',
            errorLogVariant : '',
            errorLogText : '',
            loading: false,
            loadingMess : ""
        }
    }

    logIn(properties = {}){
        this.setState({ loading : true, loadingMess : "Connexion en cours" });
        apiRequest({
                url:'/auth.login/post',
                data : properties
        })
        .then((res) => {
            this.setState({ loading : false });
            if(res.data.http_code !== 200){
                if(res.data.message){
                    this.setState({
                        errorLogVariant : "danger",
                        errorLogText : res.data.message
                    });
                }else{
                    this.setState({
                        errorLogVariant : "danger",
                        errorLogText : 'Une erreur est survenue. Veuillez réessayer.'
                    });
                }
            }else{
                if(res.data.data.user){
                    this.setState({
                        errorLogVariant : "success",
                        errorLogText : `Bienvenue ${res.data.data.user.firstName} !`
                    });                    
                    this.props.dispatch(setUser({ ...res.data.data.user, loggedIn : true }));
                    if(!isEmpty(this.props.redirect)){
                        this.props.history.push(this.props.redirect);
                    }else{
                        this.props.history.push('/dashboard');
                    }
                }else{
                    this.setState({
                        errorLogVariant : "danger",
                        errorLogText : "Erreur du serveur"
                    })
                }
            }
        })
    }

    handleLogin(e){
        e.preventDefault();
        this.logIn({
            "email" : this.state.email,
            "password" : this.state.password
        });
    }

    render(){
        return (
            <div className="d-flex align-items-center auth px-0">
                <div className="row w-100 mx-0">
                    <div className="col-lg-4 mx-auto">
                            <LoaderBox show={this.state.loading} mess={this.state.loadingMess} variant="lg" className="card">
                                <div className="card text-left py-5 px-4 px-sm-5">
                                    <div className="brand-logo">
                                        <img src={`${process.env.REACT_APP_RESSOURCES_URL}/gesprit/gesprit-logo.png`} alt="Logo Ges'prit" />
                                    </div>
                                    <h4>Bonjour !</h4>
                                    <h6 className="font-weight-light">Connectez-vous pour poursuivre.</h6>
                                    <Form onSubmit={this.handleLogin.bind(this)} className="pt-3">
                                        <Alert variant={this.state.errorLogVariant}>{this.state.errorLogText}</Alert>
                                        <Form.Group className="form-group search-field">
                                            <Form.Control type="email" placeholder="Email" id="email" size="lg" className="h-auto" onChange={(e) => this.setState({ email : e.target.value })} value={this.state.email} />
                                        </Form.Group>
                                        <Form.Group className="form-group search-field">
                                            <CForm.Control.Password placeholder="Mot de passe" id="password" size="lg" className="h-auto" onChange={(e) => this.setState({ password : e.target.value })} value={this.state.password}/>
                                        </Form.Group>
                                        <div className="mt-3">
                                            <Button className="btn btn-block btn-primary btn-lg font-weight-medium auth-form-btn" type="submit">Connexion</Button>
                                        </div>
                                    <div className="my-2 d-flex justify-content-between align-items-center">
                                        <div className="form-check">
                                        <label className="form-check-label text-muted">
                                            <input type="checkbox" className="form-check-input"/>
                                            <i className="input-helper"></i>
                                            Rester connecté
                                        </label>
                                        </div>
                                        <Link to={'/reset-password' + (!isEmpty(this.state.email) ? `?email=${this.state.email}` : '')} className="auth-link text-muted">Mot de passe oublié</Link>
                                    </div>
                                    </Form>
                                </div>
                            </LoaderBox>
                    </div>
                </div>
            </div>
        );
    }
};
const mapStateToProps = (state) => ({ storage : state });
const LoginConnected = connect(mapStateToProps, null)(Login)
export default withRouter(LoginConnected);