import React, { Component } from 'react';
import { mergeProps } from '../../../functions/react.utilities';

class FieldsetCard extends Component{
    constructor(props) {
        super(props);
        this.fieldsetProps = this.getFieldsetProps();
    }
    
    getFieldsetProps(){
        return mergeProps({
            sameNameProps : ["disabled", "className"],
            props : this.props
        });
    }

    render(){
        return(
            <fieldset {...this.fieldsetProps} className="fieldset-card">
                <legend>{this.props.fieldsetTitle}</legend>
                {this.props.children}
            </fieldset>
        );
    }
} 

export default FieldsetCard;