import globals from "./sub-reducers/registrations/globals.reducer";
import registrants from "./sub-reducers/registrations/registrants.reducer";
import registrations from "./sub-reducers/registrations/registrations.reducer";
import payments from "./sub-reducers/registrations/payments.reducer";

const initialState = { 
    registrations : {},
    registrants : {},
    payments : {},
};

export default function (state = initialState, action) {
    let n_state = registrations(state, action);
    n_state = registrants(n_state, action);
    n_state = payments(n_state, action);
    n_state = globals(n_state, action);

    return n_state;
}
