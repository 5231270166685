import React, { Component } from 'react';
import { Link } from 'react-router-dom';

class PageInBuilding extends Component {
    render() {
        return (
            <div style={{ height : "100%", display : "flex", flexFlow : "column", alignItems : "center", justifyContent : "center", marginTop : "30px", marginBottom : "30px" }}>
                <h1 className="text-center text-warning"><i className="mdi mdi-worker"/></h1>
                {this.props.sub ?
                    <h4 className="text-center">Espace en cours de construction !</h4>
                :
                    <h2 className="text-center">Page en cours de construction !</h2>
                }
                {!this.props.noLink ? 
                    <div className="mt-4 d-flex align-items-center justify-content-center">
                        <Link to="/" className="btn btn-primary">Tableau de bord</Link>
                    </div>
                : null }
            </div>
        );
    }
}

export default PageInBuilding;