import React, { Component } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { deleteSejoursModelsEventsPrices__action, getSejoursModelsEventsPrices__action } from "../../../actions/sejours.globals.actions";
import { getSejoursModels } from "../../../api/sejours/models";
import ModelsContainer from "../../../components/pages/sejours/models/container";
import LoaderBox from "../../../components/shared/loaders/loader.box";
import { ALL_CONST } from "../../../constants/api";
import { isEmpty, lengthObject, setPageTitle } from "../../../functions/common";
import { apiRequest } from '../../../functions/api';
import { pushNotification } from '../../../functions/notifications';
import { getSejoursModels__action } from '../../../actions/sejours.models.actions';
import { ALREADY_NAME } from "../../../constants/api/sejours";
import ConfirmDialog from "../../../components/custom-boostrap/ConfirmDialog";
import { getDeepState, setDeepState } from "../../../functions/react.utilities";

class SejoursModels extends Component{
    constructor(props) {
        super(props);

        this.setDeepState = setDeepState(this);
        this.getDeepState = getDeepState(this);

        this.state = {
            selection : [],
            pendingSelection : {},
            loading : false,
            modals : {
                add : false,
                delete : false
            },
            forms : {
                add : {
                    name : "",
                    isValid : false,
                    loading : false,
                    loadingMess : "Chargement en cours...",
                    errors : {
                        name : ""
                    }
                }
            },
            loaders : {}
        }

        this.formsConfig = {
            add : {
                nameChange : () => {}
            }
        }
        setPageTitle("Modèles | Séjours");
        console.log(this.props.match);
        this.toggleSelectAll = this.toggleSelectAll.bind(this);
        this.onSelectCard = this.onSelectCard.bind(this);
        this.editModel = this.editModel.bind(this);
        this.launchCreateModel = this.launchCreateModel.bind(this);
        this.cancelCreateModel = this.cancelCreateModel.bind(this);
        this.createModel = this.createModel.bind(this);
        this.launchDeleteModels = this.launchDeleteModels.bind(this);
        this.cancelDeleteModels = this.cancelDeleteModels.bind(this);
        this.deleteModels = this.deleteModels.bind(this);
    }

    componentDidMount(){
        getSejoursModels({
            SIDS : ALL_CONST,
            events : true,
            prices : true
        })
        .then((res) => {
            const state = { ...this.state };
            this.props.dispatch(getSejoursModelsEventsPrices__action(res));

            Object.keys(res.models).forEach((s) => {
                state.loaders[s] = {
                    show : false,
                    mess : ""
                }
            });
            this.setState(state);
        })

        console.log(this);
    }

    createModel(e = false){
        if(e) {
            e.preventDefault();
        }
        const getStateWithLoaderDissmissed = () => {
            const state = { ...this.state };
            state.forms.add.loading = false;
            return state;
        }
        const state = { ...this.state };
        if(!isEmpty(state.forms.add.name)){
            state.forms.add.loading = true;
            state.forms.add.loadingMess = "Création en cours";
            apiRequest({
                url : '/sejours.models/post',
                data : {
                    model : {
                        name : state.forms.add.name,
                        enabled : false
                    }
                }
            })
            .then((res) => {
                console.log(res);
                const state = getStateWithLoaderDissmissed();
                if(res.data.http_code === 200){
                    if(res.data.status === "success"){
                        if(res.data.data.model){
                            const SID = res.data.data.model.SID;
                            this.props.dispatch(getSejoursModels__action({ [SID] : res.data.data.model }));
                            pushNotification({
                                type : "success",
                                icon : "mdi mdi-plus",
                                title : "Modèle ajouté",
                                content : <p>Le modèle a été ajouté avec succès.</p>
                            });
                            state.modals.add = false;
                        }else{
                            state.forms.add.errors.name = "Une erreur est survenue. Réessayez.";
                        }
                    }else{
                        if(res.data.errors){
                            if(res.data.errors.name){
                                if(res.data.errors.name === ALREADY_NAME){
                                    state.forms.add.errors.name = "Nom déjà utilisé";
                                }else{
                                    state.forms.add.errors.name = "Une erreur est survenue. Réessayez.";
                                }
                            }else{
                                state.forms.add.errors.name = "Une erreur est survenue. Réessayez.";
                            }
                        }else{
                            state.forms.add.errors.name = "Une erreur est survenue. Réessayez.";
                        }
                    }
                }else{
                    pushNotification({
                        type : "error",
                        title : "Erreur du serveur",
                        icon : "mdi mdi-server-network-off",
                        content : <p>Erreur du serveur lors de la création du modèle ({res.data.status_code})</p>
                    });
                }
                this.setState(state);
            });

        }else{  
            state.forms.add.errors.name = "Entrez un nom";
        }
        this.setState(state);
    }

    launchCreateModel(){
        this.setDeepState((s) => {
            s.modals.add = true;
            s.forms.add.errors = {
                name : ""
            }
            s.forms.add.name = "";
            s.forms.add.isValid = false;
            s.forms.add.loading = false;
        });

        this.formsConfig.add.nameChange = (e) => {
            this.setDeepState((s) => {
                s.forms.add.name = e.target.value;
                s.forms.add.isValid = !isEmpty(s.forms.add.name)
            });
        }
    }

    cancelCreateModel(){
        this.setDeepState(s => s.modals.add = false);
    }

    editModel(){
        if(this.state.selection.length === 1){
            this.props.history.push(`/sejours/models/${this.state.selection[0]}`)
        }
    }

    deleteModels(){
        if(this.state.selection.length > 0){
            const state = this.getDeepState();
            this.state.selection.forEach((SID) => {
                state.loaders[SID] = { show : true, mess : "Suppression en cours..." }
            });
            state.selection = [];

            const pendingKeys = Object.keys(state.pendingSelection);
            const pendingSelectionID = (pendingKeys.length > 0) ? (parseInt(pendingKeys[pendingKeys.length - 1]) + 1).toString() : "0";
            state.pendingSelection[pendingSelectionID] = this.state.selection;
        
            state.modals.delete = false;

            apiRequest({
                url : "/sejours.models/delete",
                data : {
                    multiple : true,
                    SIDS : state.pendingSelection[pendingSelectionID]
                }
            })
            .then((res) => {
                const state = this.getDeepState();
                const selection = [...this.state.pendingSelection[pendingSelectionID]];
                const errorNotification = (m) => pushNotification({
                    type : "error",
                    title : `Suppression ${(selection.length > 1) ? "des modèles" : "du modèle"} impossible`,
                    icon : "mdi mdi-close",
                    content : <p>{m}</p>
                });

                if(res.data.http_code === 200){
                    if(res.data.status === "success"){
                        if(res.data.data.deleted){
                            let events = [];
                            let prices = [];

                            selection.forEach((s) => {
                                events = events.concat(this.props.sejours.models[s].events);
                                prices = prices.concat(this.props.sejours.models[s].prices);
                            });

                            const toDelete = {
                                models : selection,
                                events : events,
                                prices : prices
                            }

                            this.props.dispatch(deleteSejoursModelsEventsPrices__action(toDelete));
                            pushNotification({
                                type : "success",
                                title : (selection.length > 1) ? "Modèles supprimés" : "Modèle supprimé",
                                icon : "mdi mdi-delete-forever",
                                content : <p>{`${(selection.length > 1) ? "Modèles supprimés" : "Modèle supprimé"} avec succès !`}</p>
                            });
                        }else{
                            errorNotification(`Erreur du serveur (UNKNOWN)`);
                        }
                    }else{
                        errorNotification(`Erreur du serveur (${res.data.status_code})`);
                    }
                }else{
                    errorNotification(`Erreur du serveur (${res.data.status_code})`);
                }

                //Annulation des loaders
                selection.forEach((s) => {
                    state.loaders[s] = {
                        show : false,
                        mess : ""
                    }
                });
                //Nettoyage de la sélection d'attente
                delete state.pendingSelection[pendingSelectionID];
                this.setState(state);
            });

            this.setState(state);
        }
    }

    launchDeleteModels(){
        this.setDeepState(s => s.modals.delete = true);
    }

    cancelDeleteModels(){
        this.setDeepState(s => s.modals.delete = false);
    }

    onSelectCard({ e, SID }){
        let selection = [...this.state.selection];
        if(selection.indexOf(SID) >= 0){
            selection.splice(selection.indexOf(SID), 1);
        }else{
            selection.push(SID);
        }
        this.setState({ selection });
    }
    toggleSelectAll(){
        if(this.state.selection.length === lengthObject(this.props.sejours.models)){
            this.setState({ selection : [] })
        }else{
            const selection = Object.keys(this.props.sejours.models);
            this.setState({ selection });
        }
    }

    modelsContainerRender(){
        if(lengthObject(this.props.sejours.models) > 0){
            return (
                <ModelsContainer selection={this.state.selection} loaders={this.state.loaders} onSelect={this.onSelectCard} models={this.props.sejours.models}/> 
            )
        }else{
            return <p className="no-models text-center"><i className="mdi mdi-information text-primary mr-2" />Aucun modèle</p>
        }
    }


    render(){
        return (
            <React.Fragment>
                <h2 className="pb-3 mb-3 border-bottom">Modèles</h2>
                <p className="text-muted font-italic"><i className="mdi mdi-alert text-danger"/> Attention, la supression de modèles étant encore icomplète et en construction, si vous supprimez un modèle dont des événements possèdent des inscrits, il ne sera plus récupérable.</p>
                <div className="btn-container-inline-left pb-3 mb-3 border-bottom">
                    <Button onClick={this.launchCreateModel} disabled={this.state.loading}><i className="mdi mdi-plus"/>Ajouter</Button>
                    <Button variant="info" disabled={this.state.selection.length !== 1 || this.state.modals.add} onClick={this.editModel}><i className="mdi mdi-pencil"/>Modifier</Button>
                    <Button variant="info" disabled={!(this.state.selection.length > 0) || true} title="Indisponible"><i className="mdi mdi-content-copy"/>Dupliquer</Button>
                    <Button variant="danger" disabled={this.state.selection.length < 1 || this.state.modals.delete} onClick={this.launchDeleteModels}><i className="mdi mdi-delete-forever"/>Supprimer</Button>
                </div>
                <div className="btn-container-inline-right pb-3 mb-3">
                <Button variant="secondary" disabled={!(lengthObject(this.props.sejours.models) > 0)} onClick={this.toggleSelectAll}><i className="mdi mdi-checkbox-multiple-marked-outline"/>Tout sélectionner</Button>
                </div>
                <div>
                    { this.modelsContainerRender() }
                </div>
                <Modal show={this.state.modals.add} onHide={this.cancelCreateModel}>
                    <Modal.Header>Ajouter un modèle</Modal.Header>
                    <LoaderBox inline show={this.state.forms.add.loading} mess={this.state.forms.add.loadingMess}>
                        <Modal.Body>
                            <Form onSubmit={this.createModel}>
                                <Form.Group className="form-group mb-0">
                                    <Form.Label>Nom du modèle</Form.Label>
                                    <Form.Control type="text" placeholder="Nom du modèle" value={this.state.forms.add.name} onChange={this.formsConfig.add.nameChange.bind(this)}/>
                                    { this.state.forms.add.errors.name ? <Form.Control.Feedback type="invalid">{ this.state.forms.add.errors.name }</Form.Control.Feedback> : null }
                                </Form.Group>
                            </Form>
                        </Modal.Body>
                    </LoaderBox>
                    <Modal.Footer>
                        <Button variant="secondary" disabled={this.state.forms.add.loading} onClick={this.cancelCreateModel}>Annuler</Button>
                        <Button disabled={!this.state.forms.add.isValid || this.state.forms.add.loading} onClick={this.createModel}>Créer</Button>
                    </Modal.Footer>
                </Modal>
                <ConfirmDialog 
                    show = {this.state.modals.delete}
                    modalTitle = "Supprimer des modèles"
                    decline = {{ text : "Annuler", variant : "secondary", onClick : this.cancelDeleteModels }}
                    confirm = {{ text : "Valider", variant : "danger", onClick : this.deleteModels }}
                    onHide = {this.cancelDeleteModels}
                >
                    <p><i className="mdi mdi-alert text-danger"/> Attention, la supression de modèles étant encore icomplète et en construction, si vous supprimez un modèle dont des événements possèdent des inscrits, il ne sera plus récupérable.</p>
                    <p>Supprimez des modèles seulement si vous êtes certains de ce que vous faites.</p>
                    <p>Supprimer {`${(this.state.selection.length > 1) ? `les ${this.state.selection.length} modèles sélectionnés` : "le modèle sélectionné"} ?`}</p>
                </ConfirmDialog>
            </React.Fragment>
        )
    }
}

const mapStateToProps = (state) => ({ sejours : state.sejours });

export default connect(mapStateToProps, null)(withRouter(SejoursModels));