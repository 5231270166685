import React, { Component } from "react";
import {
    PAYMENT_FAILED_STATUS,
    PAYMENT_PENDING_STATUS,
    PAYMENT_VALIDATED_STATUS
} from "../../../../constants/api/payments";
import { Badge } from "react-bootstrap";
import moment from "moment/moment";
import { pushNotification } from "../../../../functions/notifications";

export class PaymentSummary extends Component {
    constructor(props) {
        super(props);
    }

    static statusBadgeConfig = {
        [PAYMENT_FAILED_STATUS]: {text: "Échoué", color: 'danger'},
        [PAYMENT_PENDING_STATUS]: {text: "En attente", color: 'warning'},
        [PAYMENT_VALIDATED_STATUS]: {text: "Validé", color: 'success'}
    };

    getBadge(status) {
        let config = PaymentSummary.statusBadgeConfig[status];

        if (!config)
            config = PaymentSummary.statusBadgeConfig[PAYMENT_FAILED_STATUS];
        return <Badge bg={config.color}>{config.text}</Badge>
    }

    copyRefToClipboard() {
        navigator.clipboard.writeText(this.props.payment.reference);
        pushNotification({
            type: "primary",
            title: "Référence copiée",
            icon: "mdi mdi-clipboard-check",
            content: <p>La référence a été copiée dans le presse-papier</p>
        });
    }

    render() {
        const amount = this.props.payment.amount / 100;
        const amountText = parseFloat(amount).toFixed(2) > 0 ? `${parseFloat(amount).toFixed(2)}€` : "Gratuit";
        const initDate = moment(this.props.payment.initDate);
        const canceledDate = moment(this.props.payment.canceledDate);
        const paidDate = moment(this.props.payment.paidDate);

        console.log(this.props.payment);
        return <div>
            <div className='registrations-payement-infos'>
                <div>
                    <p className="text-muted text-center m-0">
                        <i className="mdi mdi-sync-circle text-primary" aria-hidden="true"/>&nbsp;
                        {this.props.payment.nbTries} {this.props.payment.nbTries === 1 ? 'essai' : 'essais'}
                    </p>
                </div>
                <div>
                    <p className="text-muted text-center m-0">
                        <i className="mdi mdi-currency-usd text-success" aria-hidden="true"/>&nbsp;{amountText}
                    </p>
                </div>
                <div>
                    {this.getBadge(this.props.payment.status)}
                </div>
            </div>
            <p className="text-muted mb-1">Référence Monético : <b className={"text-primary cursor-pointer"} onClick={this.copyRefToClipboard.bind(this)}>{this.props.payment.reference}</b></p>
            <div className='registrations-payment-chronology'>
                <p className="mb-1"><span
                    className="text-muted">Initié le :</span> {initDate.format("dddd DD MMMM YYYY à HH:mm:ss")}</p>
                {this.props.payment.status === PAYMENT_VALIDATED_STATUS ?
                    <p className="mb-1"><span
                        className="text-muted">Payé le :</span> {paidDate.format("dddd DD MMMM YYYY à HH:mm:ss")}</p>
                    :
                    null
                }
                {this.props.payment.status === PAYMENT_FAILED_STATUS ?
                    <p className="mb-1"><span
                        className="text-muted">Annulé le :</span> {canceledDate.format("dddd DD MMMM YYYY à HH:mm:ss")}
                    </p>
                    :
                    null
                }
            </div>
        </div>
    }
}