import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import App from './App.js';
import { Provider } from 'react-redux';
import { applyMiddleware, createStore } from 'redux';
import thunk from 'redux-thunk';
import rootReducer from './reducers';

//devTools
import { composeWithDevTools } from 'redux-devtools-extension';
import logger from 'redux-logger';


//MomentJS
import MomentUtils from '@date-io/moment';
import "moment/locale/fr";


//MUI Date Pickers
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { defaultMaterialTheme } from '../src/styles/components/mui/theme';
import { ThemeProvider } from '@material-ui/styles';

const store = createStore(
    rootReducer, composeWithDevTools(applyMiddleware(thunk, logger))
);

ReactDOM.render(
    <Provider store={store}>
        <BrowserRouter>
            <MuiPickersUtilsProvider locale="fr" utils={MomentUtils}>
                <ThemeProvider theme={defaultMaterialTheme}>
                    <App />
                </ThemeProvider>
            </MuiPickersUtilsProvider>
        </BrowserRouter>
    </Provider>
, document.getElementById('root'));
