export function mergeProps({ sameNameProps = [], notSameNameProps = [], escapeProps = [], defaultProps = {}, props = {} }){
    let finalProps = {};
    sameNameProps.forEach((prop) => {
        if(props[prop]) finalProps[prop] = props[prop]
    });
    notSameNameProps.forEach((prop) => {
        if(props[prop[0]]) finalProps[prop[1]] = props[prop[0]]
    })
    escapeProps.forEach((prop) => {
        if(finalProps.hasOwnProperty(prop)) delete finalProps[prop];
    })
    return {...defaultProps, ...finalProps}
}


export function getURLParams(l, ...p){
   let params = {};
   let searcher = new URLSearchParams(l.search);
   p.forEach((param) => {
       params[param] = searcher.get(param);
   });
   return params;
}

export function getURLParam(l, p){
    let searcher = new URLSearchParams(l.search);
    return searcher.get(p);
}

/**
 * Set state in depth
 * @param {*} thisArg 
 * @returns {Function}
 */
export function setDeepState(thisArg){
    /**
     * @param {function} f
     */
    function main(f){
        let state = { ...thisArg.state };
        f(state);
        thisArg.setState(state);
    }

    return main;
}

/**
 * Get a copy of the current state
 * @param {*} thisArg 
 * @returns {Function}
 */
 export function getDeepState(thisArg){
     function main(){
        return { ...thisArg.state };
    }

    return main
}