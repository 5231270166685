import React, { Component } from 'react';
import PageInBuilding from '../../../../../../components/pages/building/page';

class RegistrantsTab extends Component {
    render() {
        return (
            <React.Fragment>
                {/*<p>Ici les dossiers des inscrits</p>*/}
                <PageInBuilding sub noLink/>
            </React.Fragment>
        );
    }
}

export default RegistrantsTab;