import React, { Component } from "react";
import { Route, Switch, withRouter } from "react-router-dom";
import SejoursModels from '../models/index';
import SejourModel from '../models/model/index';

class SejourModelsRouter extends Component{
    constructor(props){
        super(props);
        console.log(props);
    }
    render(){
        return(
            <Switch>
                {/* Models */}
                <Route exact path={this.props.path} component={SejoursModels} />

                {/* Model */}
                <Route exact path={`${this.props.path}/:SID`} component={SejourModel} />
                <Route path={`${this.props.path}/:SID/:tab`} component={SejourModel} />
            </Switch>
        )
    }
    
}

export default SejourModelsRouter;