import React, { Component } from "react";
import SejourCard from "./card";

class ModelsContainer extends Component{
    constructor(props) {
        super(props);
        console.log(props);
        this.onSelect = this.onSelect.bind(this);
    }

    getLoader(SID){
        if(Object.keys(this.props.loaders).indexOf(SID) >= 0){
            return this.props.loaders[SID];
        }else{
            return { show : false, mess : "" }
        }
    }

    getSelection(){
        if(this.props.selection){
            return this.props.selection;
        }else{
            return [];
        }
    }

    onSelect(d){
        if(this.props.onSelect){
            this.props.onSelect(d);
        }
    }

    renderCards(){
        return Object.keys(this.props.models).map((k, i) => {
            const model = this.props.models[k];
            const selected = this.props.selection.indexOf(model.SID) >= 0;
            return <SejourCard selected={selected} loader={this.getLoader(model.SID)} sejour={model} key={i} onSelectChange={this.onSelect}/>
        });
    }

    render(){
        return (
            <div className="ml-0 mr-0 row">
                { this.renderCards() } 
            </div>
        )
    }
    
}

export default ModelsContainer;