import React, { Component } from 'react'
import { Button, Form, Row } from 'react-bootstrap';
import LoaderBox from '../../../shared/loaders/loader.box';
import { apiRequest } from "../../../../functions/api";
import moment from 'moment';
import { pushNotification } from '../../../../functions/notifications';
import { isEmpty } from '../../../../functions/common';
import { Link } from "react-router-dom";

class RegistrationsRegistrantsSearcher extends Component {
    constructor(props) {
        super(props);

        this.onChange = this.onChange.bind(this);
        this.onValid = this.onValid.bind(this);

        this.state = {
            name: "",
            firstName: "",
            email: "",
            registrationRelationID: "",
            registrantID: "",
            isPayer: "any",

            loading: false,
            loadingMess: "Recherche en cours",
            sejours: {},
            registrants: {},
            events: {},
            results: -1
        };
    }

    getRegistrants() {
        const filters = {};
        const stringsFiltersKeys = [ 'name', 'firstName', 'email', 'registrationRelationID', 'registrantID' ];

        for (const key of stringsFiltersKeys) {
            if (!isEmpty(this.state[key])) {
                filters[key] = this.state[key];
            }
        }

        if (this.state.isPayer !== "any") {
            filters.isPayer = this.state.isPayer === "true";
        }

        this.setState({
            loading: true,
            loadingMess: "Recherche en cours",
            sejours: {},
            registrants: {},
            events: {},
            results: -1
        });

        apiRequest({
            url: '/sejours.registrations.registrants/get',
            data: {
                filters,
                page: 0,
                limit: 100000
            }
        })
            .then((res) => {
                const newState = {...this.state, loading: false};
                if (res.data.http_code === 200 && res.data.status === "success") {
                    const {sejours, registrants, events} = res.data.data;

                    newState.sejours = sejours;
                    newState.registrants = registrants.values;
                    newState.events = events;
                    newState.results = Object.values(registrants.values).length;
                } else {
                    pushNotification({
                        type: "error",
                        title: "Erreur du serveur",
                        content: <p>Une erreur inconnue est survenue. Veuillez réessayer.</p>,
                        icon: "mdi mdi-close"
                    });
                }
                this.setState(newState);
            })
    }

    onChange(prop) {
        const setter = (e) => {
            this.setState({[prop]: e.target.value});
        }

        return setter.bind(this);
    }

    onValid(e) {
        e.preventDefault();
        if (!this.state.loading) {
            this.getRegistrants();
        }
    }

    renderResults() {
        if (this.state.results === -1) {
            return null;
        } else if (this.state.results === 0) {
            return this.renderNotFound();
        }

        const sortedRegistrants = Object.values(this.state.registrants).sort((a, b) => {
            if (a.name > b.name) return 1;
            if (a.name < b.name) return -1;
            if (a.firstName > b.firstName) return 1;
            if (a.firstName < b.firstName) return -1;
            return 0;
        });

        return (
            <>
                <div className="mt-4 row mx-0">
                    <h5 className='text-success col-12 mb-3'>{this.state.results} {this.state.results === 1 ? "Résultat" : "Résultats"}</h5>
                    {sortedRegistrants.map((data, i) => {
                        const name = data.name.toUpperCase();
                        const firstName = data.firstName.charAt(0).toUpperCase() + data.firstName.slice(1).toLowerCase();
                        const sejour = this.state.sejours[data.sejourRelationID];
                        const event = this.state.events[data.eventRelationID];
                        const startDate = event ? moment(event.startDate).format('DD/MM/YYYY') : "Inconnu";
                        const endDate = event ? moment(event.endDate).format('DD/MM/YYYY') : "Inconnu";
                        return (
                            <div key={i} className="col-xl-6 col-sm-12 grid-margin">
                                <div className="card card-second">
                                    <div className="card-body">
                                        <h5><i className='mdi mdi-account-circle pr-1'/>{name} {firstName} <span
                                            className={"font-weight-normal text-muted"}>#{data.RID}</span></h5>
                                        <div className="row">
                                            <div className="col-md-6 col-sm-12">
                                                <b>Nom :</b> {name}
                                            </div>
                                            <div className="col-md-6 col-sm-12">
                                                <b>Prénom :</b> {firstName}
                                            </div>
                                        </div>
                                        <div className="row mt-1">
                                            <div className="col-12">
                                                <b>Email :</b> <a className='no-link'
                                                                  href={`mailto:${data.email}`}>{data.email}</a>
                                            </div>
                                        </div>
                                        <div className="row mt-1">
                                            <div className="col-12">
                                                <b>Téléphone :</b> <a className='no-link'
                                                                      href={`tel:+33${data.phone}`}>{data.phone}</a>
                                            </div>
                                        </div>
                                        <div className="row mt-1">
                                            <div className="col-12">
                                                <b>Adresse :</b>
                                                <address>
                                                    <p>{data.address}</p>
                                                    <p>{data.postalCode} - {data.city}</p>
                                                    <p>{data.country}</p>
                                                </address>
                                            </div>
                                            <div className="col-12">
                                                <div className={"mb-1"}>
                                                    <b>Inscription: </b>
                                                    <Link to={`/registrations/${data.registrationRelationID}`}>
                                                        <span className={"text-muted"}>#{data.registrationRelationID}</span>
                                                        <i className={"text-muted mdi mdi-open-in-new"} />
                                                    </Link>
                                                </div>
                                                <ul className={"ml-2 mb-0"}>
                                                    <li><b>Sejour:</b> {
                                                        sejour ? <Link
                                                            to={`/sejours/models/${sejour.SID}`}>{sejour.name}</Link> : "Inconnu"
                                                    }</li>
                                                    <li><b>Événement:</b> {
                                                        event ? <Link
                                                            to={`/sejours/events/${event.EID}`}>Du {startDate} au {endDate}</Link> : "Inconnu"}
                                                    </li>
                                                    <li><b>Est le payeur: </b> {data.isPayer ?
                                                        <i className={"text-success mdi mdi-check"}/> :
                                                        <i className={"text-danger mdi mdi-close"}/>}</li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        );
                    })}
                </div>
            </>
        );
    }

    renderNotFound() {
        return (
            <div className="mt-4 row mx-0">
                <div className="col">
                    <p className='text-center'>Aucun résultat</p>
                </div>
            </div>
        );
    }

    render() {
        return (
            <div>
                <div className="row">
                    <div className="col grid-margin stretch-card">
                        <div className="card">
                            <LoaderBox show={this.state.loading} mess={this.state.loadingMess} className="card" inline>
                                <div className="card-body">
                                    <h4 className="card-title col-12">Rechercher des inscrits</h4>
                                    <Form
                                        className='model-tab-form col-12'
                                        onSubmit={this.onFormSubmit}
                                    >
                                        <Row>
                                            <Form.Group className='col-4 form-group'>
                                                <Form.Label>Nom</Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    placeholder="Doe"
                                                    name="registrantName"
                                                    value={this.state.name}
                                                    onChange={this.onChange('name')}
                                                    autoComplete="off"
                                                />
                                                <Form.Control.Feedback type='invalid'>
                                                </Form.Control.Feedback>
                                            </Form.Group>
                                            <Form.Group className='col-4 form-group'>
                                                <Form.Label>Prénom</Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    placeholder="John"
                                                    name="registrantFirstName"
                                                    value={this.state.firstName}
                                                    onChange={this.onChange('firstName')}
                                                    autoComplete="off"
                                                />
                                                <Form.Control.Feedback type='invalid'>
                                                </Form.Control.Feedback>
                                            </Form.Group>
                                            <Form.Group className='col-4 form-group'>
                                                <Form.Label>Email</Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    placeholder="johndoe@domain.com"
                                                    name="registrantEmail"
                                                    value={this.state.email}
                                                    onChange={this.onChange('email')}
                                                    autoComplete="off"
                                                />
                                                <Form.Control.Feedback type='invalid'>
                                                </Form.Control.Feedback>
                                            </Form.Group>
                                        </Row>
                                        <Row>
                                            <Form.Group className='col-4 form-group'>
                                                <Form.Label>
                                                    Identifiant d'inscrit
                                                </Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    placeholder="SR_xxxxxxxxxxxxx"
                                                    name="registrantID"
                                                    value={this.state.registrantID}
                                                    onChange={this.onChange('registrantID')}
                                                    autoComplete="off"
                                                />
                                                <Form.Control.Feedback type='invalid'>
                                                </Form.Control.Feedback>
                                            </Form.Group>
                                            <Form.Group className='col-4 form-group'>
                                                <Form.Label>
                                                    Identifiant d'inscription
                                                </Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    placeholder="SRFxxxxxxxxxxxxx"
                                                    name="registrantregistrationRelationID"
                                                    value={this.state.registrationRelationID}
                                                    onChange={this.onChange('registrationRelationID')}
                                                    autoComplete="off"
                                                />

                                                <Form.Control.Feedback type='invalid'>
                                                </Form.Control.Feedback>
                                            </Form.Group>
                                            <Form.Group className='col-4 form-group'>
                                                <Form.Label>Est la personne qui réglé le paiement</Form.Label>
                                                <Form.Select className="form-control" value={this.state.isPayer}
                                                             onChange={this.onChange('isPayer')}>
                                                    <option value={"any"} key="isPayer_Any">Peu importe</option>
                                                    <option value={"true"} key="isPayer_True">Oui</option>
                                                    <option value={"false"} key="isPayer_False">Non</option>
                                                </Form.Select>
                                            </Form.Group>
                                        </Row>
                                        <div className="btn-container-inline-right mb-0">
                                            <Button
                                                variant="primary"
                                                type="button"
                                                disabled={this.state.loading}
                                                onClick={this.onValid}
                                            >
                                                <i className="mdi mdi-magnify"/>Rechercher
                                            </Button>
                                        </div>
                                    </Form>
                                    {this.renderResults()}
                                </div>
                            </LoaderBox>
                        </div>
                    </div>
                </div>
            </div>
        );
    };
}

export default RegistrationsRegistrantsSearcher;
