import React, { Component } from "react";

const authorizedFormat = [
    "image/png",
    "image/jpeg",
    "image/jpg",
    "image/bmp",
    "image/gif"
]

class ImagePicker extends Component{
    constructor(props) {
        super(props);
        this.state = {
            hovered : false,
            files : []
        }
        this.files = [];
        this.onChange = this.onChange.bind(this);

        this.onChangeProps = this.props.onChange ? this.props.onChange : () => {}
        this.onChangeProps = this.onChangeProps.bind(this);

        this.inputFile = React.createRef();
    }

    setBG(){
        return this.props.src ? this.props.src : (process.env.REACT_APP_RESSOURCES_URL + '/images/placeholders/placeholder-image-picker.jpeg')
    }

    getClasses(){
        const classes = {
            picker : "image-picker form-control"
        }

        if(this.state.hovered && !this.props.disabled || this.state.fileSelectorOpened){
            classes.picker += " active";
        }

        return classes
    }

    onChange(e){
        e.persist();
        if(e.target.files.length > 0){
            if(authorizedFormat.indexOf(e.target.files[0].type) >= 0){
                this.files = e.target.files;
                this.setState({ files : e.target.files });
                this.onChangeProps({ files : e.target.files, event : e });
            }else{
                this.onChangeProps({ files : [], event : e });
            }
        }else{
            this.onChangeProps({ files : e.target.files, event : e });
        }
        this.inputFile.current.value = "";
    }

    getTypes(){
        let types = "";
        authorizedFormat.forEach((t, i) => {
            types += t;
            if(i < authorizedFormat.length - 1){
                types += ", ";
            }
        });
        return types;
    }

    render(){
        const { picker } = this.getClasses();
        return (
            <label className="image-picker-label" onMouseOver={() => this.setState({ hovered : true })} onMouseLeave={() => this.setState({ hovered : false })}>
                <input type="file" hidden accept={this.getTypes()} disabled={this.props.disabled ? true : false} onChange={this.onChange} ref={this.inputFile}/>
                <div className={picker} style={{ backgroundImage : `url('${this.setBG()}')`}} >
                    <img src={this.setBG()} />
                    <div className="hovering-message">Sélectionner un fichier</div>
                </div>
            </label>
        )
    }
    
}

export default ImagePicker;