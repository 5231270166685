import React, { Component } from 'react';
import { Badge } from 'react-bootstrap';
import { MODEL_RELATION_TYPE } from '../../../../constants/api/sejours';
import { lengthObject } from '../../../../functions/common';

class PricesTagsContainer extends Component {
    constructor(props) {
        super(props);
        this.onRemove = this.onRemove.bind(this);
    }

    onRemove(PID, e){
        e.preventDefault();
        if(this.props.onRemove){
            this.props.onRemove(PID);
        }
    }

    pricesTagsRender(){
        if(lengthObject(this.props.prices) > 0){
          return Object.entries(this.props.prices).map(([PID, price]) => {
            if(price){
              return (<Badge className="price-tag" pill key={PID}>
                        <Badge className={`price-relation-type-badge ${price.sejourRelationType === MODEL_RELATION_TYPE ? "relation-model" : "relation-global"}`} pill>{price.sejourRelationType === MODEL_RELATION_TYPE ? "M" : "G"}</Badge>
                        {price.label}&nbsp;-&nbsp;{parseFloat(price.amount / 100).toFixed(2) > 0 ? `${parseFloat(price.amount / 100).toFixed(2)}€` : "Gratuit"}
                        <button className="btn-close" aria-label="Retirer de la liste"  title="Retirer le tarif" onClick={(e) => this.onRemove(PID, e)}/>
                      </Badge>);
            }else{
              return null;
            }
          })
        }else{
          return (
            <p className="text-center text-muted m-2">Aucun tarif ajouté</p>
          )
        }
      }
    
    render() {
        return (
            <div className={`prices-tags-list${this.props.className ? ` ${this.props.className}` : ""}`}>
            { this.pricesTagsRender() }
            </div>
        );
    }
}

export default PricesTagsContainer;