import * as pricesActions from '../constants/sejours.prices.actions';

export const getSejoursPrices__action = (data) => {
    return {
        type : pricesActions.GET_SEJOURS_PRICES,
        payload : data
    }
};

export const updateSejoursPrices__action = (data) => {
    return {
        type : pricesActions.UPDATE_SEJOURS_PRICES,
        payload : data
    }
};

export const deleteSejoursPrices__action = (data) => {
    return {
        type : pricesActions.DELETE_SEJOURS_PRICES,
        payload : data
    }
};