import React, { Component } from "react";
import * as moment from "moment";
import { Link } from "react-router-dom";
import LoaderBox from "../../../shared/loaders/loader.box";

class EventCard extends Component {
    constructor(props) {
        super(props);
        this.onSelectChange = this.onSelectChange.bind(this);
    }
  
    onSelectChange(e){
        if(this.props.onSelectChange){
            e.persist();
            this.props.onSelectChange({ e, EID : this.props.event.EID });
        }
    }

    getDisplayingInfos(){
        const infos = {}
        infos.nbRegistrants = this.props.event.registrants ? this.props.event.registrants.length : 0;
        if(infos.nbRegistrants === 0 || infos.nbRegistrants > 1){
            infos.registrantsDesc = "inscrits";
        }else{
            infos.registrantsDesc = "inscrit";
        }
        infos.startDate = `Du ${moment(this.props.event.startDate).format('DD MMMM YYYY')}`;
        infos.endDate = `au ${moment(this.props.event.endDate).format('DD MMMM YYYY')}`;
        infos.visible = this.props.event.visible;

        if(this.props.sourceModel){
            infos.modelName = this.props.sourceModel.name;
            infos.modelSID = this.props.sourceModel.SID;
        }

        infos.complete = infos.nbRegistrants >= this.props.event.complete;
        infos.prices = this.props.event.prices;

        return infos;
    }

    render() {
        const infos = this.getDisplayingInfos();
        return (
        <div className="col-xl-4 col-sm-6 grid-margin">
            <div className={`card selectable${this.props.selected ? " selected" : ""}${this.props.cardSecond ? " card-second" : ""}`}>
                <div className={`card-selector${this.props.loader.show ? " hidden" : ""}`}>
                    <div className="form-check">
                        <label className="form-check-label text-muted">
                            <input type="checkbox" className="form-check-input" checked={this.props.selected} onChange={this.onSelectChange}/>
                            <i className="input-helper"></i>
                        </label>
                    </div>
                </div>
                <LoaderBox inline variant="sm" {...this.props.loader}>
                    <div className="card-body">
                        {this.props.sourceModel ? 
                            <Link to={`/sejours/models/${infos.modelSID}`}><p className="mb-1">{infos.modelName}</p></Link> 
                        : null }
                        <div className="row">
                            <div className="col-9">
                                <h5 className="mb-0">{infos.startDate}</h5>
                                <h5 className="mb-0">{infos.endDate}</h5>
                            </div>
                            <div className="col-3">
                                <div className="text-right">
                                    <p className="mb-0 display-3 text-success lh-1">{infos.nbRegistrants}</p>
                                    <p className="mb-0 text-success font-weight-medium lh-1">{infos.registrantsDesc}</p>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-lg-10 col-md-12">
                                <div className="row">
                                    <p className="col-6 text-muted mb-0 mt-0">
                                        <i className="mr-1 mdi mdi-check-circle text-success"/>
                                        {infos.complete ? "Complet" : "Incomplet"}
                                    </p>
                                    <p className="col-6 text-muted mb-0 mt-0">
                                        <i className={`mr-1 mdi ${infos.visible ? "mdi-eye" : "mdi-eye-off"} text-warning`}/>
                                        {infos.visible ? "Visible" : "Masqué"}
                                    </p>
                                </div>
                                <div className="row">
                                    <p className="col-6 text-muted mb-0 mt-0">
                                        <i className="mr-1 mdi mdi-currency-eur text-info"/>
                                        {infos.prices.length > 0 ? `${infos.prices.length} ${(infos.prices.length > 1 ? "tarifs" : "tarif")}` : "Pas de tarifs"}
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="btn-container-inline-left wrap mb-0 mt-2">
                            <Link to={`/sejours/events/${this.props.event.EID}`} className="btn btn-primary btn-sm ml-0">Editer</Link>
                            <Link to={`/sejours/events/${this.props.event.EID}/registrations`} className="btn btn-secondary btn-sm ml-0">Inscrits</Link>
                        </div>
                    </div>
                </LoaderBox>
            </div>
        </div>
        );
    }
}

export default EventCard;
