import * as sejoursPricesActions from '../../../constants/sejours.prices.actions';

export default function prices(state, action){
    const n_state = { ...state };
    switch(action.type){
        case sejoursPricesActions.GET_SEJOURS_PRICES:
            return { ...state, prices : { ...state.prices,  ...action.payload } }

        case sejoursPricesActions.UPDATE_SEJOURS_PRICES:
            action.payload.forEach((price) => {
                n_state.prices[price.PID] = price;
                const modelSIDS = price.sejourRelationIDS;
                modelSIDS.forEach((SID) => {
                    if(n_state.models.hasOwnProperty(SID)){
                        const pricePos = n_state.models[SID].prices.indexOf(price.PID);
                        if(!(pricePos >= 0)){
                            n_state.models[SID].prices.push(price.PID);
                        }
                    }
                });
            });
            return n_state;


        case sejoursPricesActions.DELETE_SEJOURS_PRICES:
            action.payload.PIDS.forEach((PID) => {
                if(n_state.prices.hasOwnProperty(PID)){
                    delete n_state.prices[PID];
                }
            });
            n_state.models = { ...n_state.models, ...action.payload.models }
            n_state.events = { ...n_state.events, ...action.payload.events }
            return n_state;
        
        default:
            return state;
    }
}