import React, { Component } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import { connect } from "react-redux";
import { getSejoursModels__action } from "../../../../../actions/sejours.models.actions";
import ImagePicker from "../../../../../components/custom-boostrap/InputFile/image.picker";
import * as apiConst from "../../../../../constants/api";
import { apiRequest } from "../../../../../functions/api";
import { isEmpty } from "../../../../../functions/common";
import { pushNotification } from "../../../../../functions/notifications";

class ModelTab extends Component{
    errors = {
        empty : "EMPTY",
        alreadyName : "ALREADY_NAME",
        incorrectFileSize : "INCORRECT_FILE_SIZE"
    }

    constructor(props) {
        super(props);
        this.state = {
            edit : false,
            isValid : true,
            model : {
                name : "",
                description : "",
                descriptionImage : ""
            },
            errors : {
                name : "",
                description : "",
                descriptionImage : ""
            }
        }

        this.descriptionImage = false;
        this.onImageChange = this.onImageChange.bind(this);
        this.onCancel = this.onCancel.bind(this);
        this.onClearImage = this.onClearImage.bind(this);
        this.onClickEditSave = this.onClickEditSave.bind(this);
        this.onFormSubmit = this.onFormSubmit.bind(this);
        this.getValue = this.getValue.bind(this);

        this.revokeImage = function (){};

        this.setLoader = this.props.setLoader || function (){};
    }

    setFeedBack({ name, errors = new Map()}) {
        if(this.state.errors[name]){
            return (
                <React.Fragment>
                    <Form.Control.Feedback type="invalid">{ errors.get(this.state.errors[name]) }</Form.Control.Feedback>
                </React.Fragment>
            )
        }
    }
    setControlErrors(errors = []){
        let errorsWithMessages = new Map();
        errors.forEach(error => {
            errorsWithMessages.set(error[0], error[1]);
        });
        return errorsWithMessages;
    }

    onImageChange({ event, files }){
        if(files.length > 0){
            this.descriptionImage = files[0];
            let URLPrevImage = URL.createObjectURL(this.descriptionImage);
            this.revokeImage = () => URL.revokeObjectURL(URLPrevImage);
            this.onValueChange(URLPrevImage, "descriptionImage");
        }
    }

    onClearImage(){
        this.revokeImage();
        this.revokeImage = () => {};
        this.descriptionImage = false;
        this.setState({ model : { ...this.state.model, descriptionImage : "" }});
        this.onValueChange("", "descriptionImage");
    }

    onCancel(){
        this.revokeImage();
        this.revokeImage = () => {};
        this.descriptionImage = false;
        this.setState({
            edit : false,
            isValid : true,
            model : {
                ...this.state.model,
                name : this.props.state.sejours.models[this.props.SID].name || "",
                description : this.props.state.sejours.models[this.props.SID].description || "",
                descriptionImage : this.props.state.sejours.models[this.props.SID].descriptionImage || ""
            }
        });
    }

    onValueChange(v, prop){
        let model = { ...this.state.model, [prop] : v }
        let errors = { 
            ...this.state.errors,
            name : "",
            description : ""
        }
        let isValid = true;

        const setError = (err) => {
            errors[err.property] = err.code;
            isValid = false;
        }

        if(isEmpty(model.name)){ setError({ code : this.errors.empty, property : "name" })}

        if(isValid){
            isValid = (
                this.props.state.sejours.models[this.props.SID].name != model.name ||
                this.props.state.sejours.models[this.props.SID].description != model.description ||
                this.props.state.sejours.models[this.props.SID].descriptionImage != model.descriptionImage
            )
        }
        
        this.setState({
            ...this.state,
            isValid,
            errors,
            model : {
                ...this.state.model,
                [prop] : v
            }
        });
    }

    onClickEditSave(){
        if(this.state.edit && this.state.isValid){
            this.setLoader({ loading : true, mess : "Modification en cours..." });
            const data = {
                SID : this.props.SID,
                model : this.state.model
            }
            const request = {
                url : '/sejours.models/put',
                data : data
            }
            
            if(!isEmpty(this.state.model.descriptionImage) && this.descriptionImage){
                request.upload = {
                    descriptionImage : this.descriptionImage
                }
            }else if(!isEmpty(this.props.state.sejours.models[this.props.SID]) && isEmpty(this.state.model.descriptionImage)){
                request.data.model.descriptionImage = apiConst.DELETE;
            }
            
            apiRequest(request)
            .then((res) => {
                if(res.data){
                    if(res.data.http_code === 200){
                        if(res.data.status === "success"){
                            if(res.data.data.model){
                                this.setState({ edit : false });
                                this.props.dispatch(getSejoursModels__action({ [this.props.SID] : res.data.data.model }))
                                pushNotification({
                                    type : "success",
                                    title : "Modèle enregistré",
                                    icon : "mdi mdi-check",
                                    content : <p>Les modifications apportées au modèle ont été enregistrées.</p>
                                });
                            } 
                        }else{
                            if(res.data.errors){

                            }else{
                                pushNotification({
                                    type : "error",
                                    title : "Erreur du serveur",
                                    icon : "mdi mdi-close",
                                    content : <p>Une erreur du serveur est survenue ({res.data.status_code})</p>
                                });
                            }
                        }
                    }else{
                        pushNotification({
                            type : "error",
                            title : "Erreur du serveur",
                            icon : "mdi mdi-close",
                            content : <p>Une erreur du serveur est survenue ({res.data.status_code})</p>
                        });
                    }
                }
            })
            .finally(() => {
                this.setLoader({ loading : false });
            })
        }else{
            this.setState({ 
                edit : true, 
                isValid : false, 
                model : {
                    name : this.props.state.sejours.models[this.props.SID].name || "",
                    description : this.props.state.sejours.models[this.props.SID].description || "",
                    descriptionImage : this.props.state.sejours.models[this.props.SID].descriptionImage || ""
                }
            });
        }
    }

    getValue(prop){
        
        if(this.state.edit){
            return this.state.model[prop];
        }else{
            return this.props.state.sejours.models[this.props.SID][prop];
        }
    }

    onFormSubmit(e){
        e.preventDefault();
        this.onClickEditSave();
    }

    componentDidUpdate(){
        if(!this.props.selectedTab && this.state.edit){
            this.onCancel();
        }
    }

    render(){
        return (
            <React.Fragment>
                <div className="btn-container-inline-right wrap">
                    {this.state.edit ? <Button size="sm" variant="secondary" onClick={this.onCancel}><i className="mdi mdi-close"/>Annuler</Button> : null }
                    <Button size="sm" variant="primary" onClick={this.onClickEditSave} disabled={!this.state.isValid}><i className={this.state.edit ? "mdi mdi-content-save" : "mdi mdi-pencil" }/>{this.state.edit ? "Enregistrer" : "Modifier" }</Button>
                </div>
                <Form className="model-tab-form" onSubmit={this.onFormSubmit}>
                    <Row className="mb-3">
                        <Col lg={8}>
                            <Form.Group className="form-group">
                                <Form.Label>Nom</Form.Label>
                                <Form.Control type="text" placeholder="Nom du séjour" readOnly={!this.state.edit} value={this.getValue("name")} onChange={(e) => this.onValueChange(e.target.value, "name")}/>
                                {this.state.edit ? this.setFeedBack({name : 'name', errors : this.setControlErrors([[this.errors.empty, "Entrez un nom"], [this.errors.alreadyName, "Nom de séjour déjà utilisé"]])}) : null }
                            </Form.Group>

                            <Form.Group className="form-group">
                                <Form.Label>Description</Form.Label>
                                <Form.Control as="textarea" placeholder="Description du séjour" readOnly={!this.state.edit} value={this.getValue("description")} onChange={(e) => this.onValueChange(e.target.value, "description")}/>
                            </Form.Group>
                        </Col>
                        <Col>
                            <Form.Group className="form-group image-picker-group">
                                <Form.Label>Image</Form.Label>
                                <ImagePicker src={this.getValue("descriptionImage")} disabled={!this.state.edit} onChange={this.onImageChange}/>
                                { this.state.edit ?
                                    <div className="btn-container-inline-right mt-2">
                                        <Button size="sm" variant="danger" disabled={isEmpty(this.state.model.descriptionImage)} title={isEmpty(this.state.model.descriptionImage) ? "Impossible" : ""} onClick={this.onClearImage}><i className="mdi mdi-broom"/>Supprimer l'image</Button>
                                    </div>
                                : null }
                                {this.state.edit ? this.setFeedBack({name : 'imageDescription', errors : this.setControlErrors([[this.errors.incorrectFileSize, "Poids du fichier trop important"]])}) : null }
                            </Form.Group>
                        </Col>
                    </Row>
                </Form>
            </React.Fragment>
        )
    }
    
}

const mapStateToProps = (state) => ({ state });

export default connect(mapStateToProps, null)(ModelTab);