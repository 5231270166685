import { createTheme } from "@material-ui/core";
import { themeColors } from "../../variables";

export const defaultMaterialTheme = createTheme({
    palette : {
        primary : {
            main : themeColors.primary
        },
        secondary : {
            main : themeColors.secondary
        },
        success : {
            main : themeColors.success
        },
        info : {
            main : themeColors.info
        },
        warning : {
            main : themeColors.warning
        },
        error : {
            main : themeColors.danger
        }
    }
})