import React, { Component } from 'react';
import LoginForm from '../components/pages/login/login.form';
import { setPageTitle } from '../functions/common';
import { getURLParam } from '../functions/react.utilities';

class Login extends Component {

    constructor(props) {
        super(props);
        setPageTitle("Connexion");
        this.redirect = getURLParam(props.location, "redirect");
    }
    

    render(){
        return (
            <LoginForm redirect={this.redirect}/>
        );
    }
};

export default Login;