import React, { Component } from 'react';
import { Link } from 'react-router-dom';

class CBreadcrumbItem extends Component{
    getProps(){
        let props = { ...this.props };
        delete props.className;
        delete props.active;
        delete props.to;
        delete props.children;
        delete props.linkProps;

        return props;
    }
    getActive(){
        return (<li className="breadcrumb-item active" aria-current="page" {...this.getProps()}>
            <span {...this.props.linkProps}>
                {this.props.children}
            </span>
        </li>)
    }

    getInactive(){
        return (<li className="breadcrumb-item" {...this.getProps()}>
            <Link to={this.props.to} {...this.props.linkProps}>
                {this.props.children}
            </Link>
        </li>)
    }
    render(){
        return this.props.active ? this.getActive() : this.getInactive();
    }
    
}

export default CBreadcrumbItem
