import React, { Component } from "react";
import { Badge, Button, Dropdown } from "react-bootstrap";
import { connect } from "react-redux";
import { updateSejoursModelsEventsPrices__action } from "../../../../../../actions/sejours.globals.actions";
import ConfirmDialog from "../../../../../../components/custom-boostrap/ConfirmDialog";
import EditAddPriceModal from "../../../../../../components/pages/sejours/prices/modals/edit-add-modal";
import ContextMenu from "../../../../../../components/shared/context-menu";
import { MODEL_RELATION_TYPE } from "../../../../../../constants/api/sejours";
import { apiRequest } from "../../../../../../functions/api";
import { isEmpty, lengthObject } from "../../../../../../functions/common";
import { pushNotification } from "../../../../../../functions/notifications";
import { getDeepState, setDeepState } from "../../../../../../functions/react.utilities";
import AddModal from "./add-modal";
import RemoveModal from "./remove-modal";

class PricesTab extends Component{
    constructor(props) {
        super(props);

        this.setDeepState = setDeepState(this);
        this.getDeepState = getDeepState(this);

        this.setDeepState = this.setDeepState.bind(this);
        this.getDeepState = this.getDeepState.bind(this);
        this.setState = this.setState.bind(this);

        this.setLoader = this.props.setLoader || function (){};

        this.state = {
            modals : {
                add : false,
                delete : false
            },
            selection : [],
            editConfigs : false,
            configsSaving : false,
            configs : this.getConfigs()
        }
        this.selection = {};

        this.launchAddPrices = this.launchAddPrices.bind(this);
        this.launchRemovePricesWithButton = this.launchRemovePricesWithButton.bind(this);

        this.launchRemovePriceWithContext = this.launchRemovePriceWithContext.bind(this);
        this.launchConsultPriceWithContext = this.launchConsultPriceWithContext.bind(this);
        this.launchEditPricesConfigurations = this.launchEditPricesConfigurations.bind(this);
        this.cancelPricesConfigurations = this.cancelPricesConfigurations.bind(this);
        this.savePricesConfigurations = this.savePricesConfigurations.bind(this);

        this.onSelectionChange = this.onSelectionChange.bind(this);
    }


    getEventFromStore(){
        return this.props.sejours.events[this.props.EID];
    }

    getEventPricesFromStore(){
        const event = this.getEventFromStore();
        let prices = {};
        event.prices.forEach((PID) => {
            if(PID in this.props.sejours.prices){
                prices[PID] = this.props.sejours.prices[PID];
            }
        });
        return prices;
    }

    getSourceModelPricesFromStore(){
        let prices = {};
        const sourceModel = this.getSourceModelFormStore();
        sourceModel.prices.forEach((PID) => {
            if(sourceModel.prices.indexOf(PID) >= 0){
                prices[PID] = this.props.sejours.prices[PID];
            }
        });
        return prices;
    }

    getSourceModelFormStore(){
        return { ...this.props.sejours.models[this.getEventFromStore().sejourRelationID] };
    }

    getPIDSOfSelection(){
        const prices = this.getModelPricesFromStore();
        return Object.keys(prices).filter((PID, i) => (i in this.selection));
    }

    getConfigs(){
        const event = this.getEventFromStore();
        let configs = { ...event.pricesConfig };
        Object.entries(event.pricesConfig).forEach(([PID, config]) => {
            if(!(PID in configs)){
                configs[PID] = {
                    limitEvent : 0,
                    limitRegistration : 0,
                    enableLimitEvent : false,
                    enableLimitRegistration : false
                }
            }
            configs[PID] = { ...configs[PID], ...config }
        });
        return configs;
    }

    //Buttons bar functions
    launchAddPrices(){
        this.setDeepState(s => { s.modals.add = true });
    }
    
    launchRemovePricesWithButton(){
        if(this.state.selection.length > 0){
            this.setDeepState(s => { 
                s.modals.delete = true;
            });
        }
    }


    //Context menu functions
    launchConsultPriceWithContext(PID){
        alert(`Option indisponible pour le moment ${PID} !`);
    }
    launchRemovePriceWithContext(PID){
        this.setDeepState(s => {
            s.selection = [PID];
            s.modals.delete = true;
        });
    }

    launchEditPricesConfigurations(){
        this.setDeepState((s) => {
            s.configs = this.getConfigs();
            s.editConfigs = true;
        });
    }
    cancelPricesConfigurations(){
        this.setDeepState(s => {
            s.modals.editConfigs = false;
            s.editConfigs = false;
            s.configSaving = false;
            s.configs = this.getConfigs();
        });
    }
    savePricesConfigurations(){
        if(this.state.editConfigs){
            let newState = this.getDeepState();
            newState.configsSaving = true;
            let pricesConfig = {};
            Object.entries(this.state.configs).forEach(([PID, config]) => {
                pricesConfig[PID] = {
                    limitEvent : isEmpty(config.limitEvent) ? 0 : config.limitEvent,
                    enableLimitEvent : config.enableLimitEvent ? true: false,
                    limitRegistration : isEmpty(config.limitRegistration) ? 0 : config.limitRegistration,
                    enableLimitRegistration : config.enableLimitRegistration ? true : false
                };
            });
            apiRequest({
                url : '/sejours.events.prices.configurations/put',
                data : {
                    EID : this.props.EID,
                    configurations : pricesConfig
                }
            })
            .then((res) => {
                const errorNotification = (m) => pushNotification({
                    type : "error",
                    title : "Impossible de mettre à jour les configurations tarifaires",
                    icon : "mdi mdi-close",
                    content : <p>{m}</p>
                });

                const newState = this.getDeepState();
                newState.configsSaving = false;
                newState.editConfigs = false;

                if(res.data.http_code === 200){
                    if(res.data.status === "success"){
                        this.props.dispatch(updateSejoursModelsEventsPrices__action({
                            events : res.data.data.events ? res.data.data.events : {}
                        }));
                        pushNotification({
                            type : "success",
                            title : "Modifications enregistrées",
                            icon : "mdi mdi-check",
                            content : <p>Les configurations tarifaires ont été enregistrées et appliquées avec succès.</p>
                        });
                    }else{
                        if(res.data.errors){
                            errorNotification(`Erreur du serveur. Veuillez réessayer.`);
                        }else if(res.data.status_code){
                            errorNotification(`Erreur du serveur (${res.data.status_code})`);
                        }else{
                            errorNotification(`Erreur du serveur (UNKNOWN)`);
                        }
                    }
                }else{
                    errorNotification(`Erreur du serveur (${res.data.http_code})`);
                }
                this.setState(newState);
                this.setLoader({ loading : false });
            });

            this.setLoader({ loading : true, mess : "Modifications en cours... "});
            this.setState(newState);
        }
    }

    onSelectionChange(s){
        if(Object.keys(s).length !== Object.keys(this.selection).length){
            this.forceUpdate();
        }
        this.selection = s;
    }

    getHeader(){
        const nbSourceModelPrices = lengthObject(this.getSourceModelPricesFromStore());
        return(
            <div className="row">
                <div className="col-6 btn-container-inline-left wrap">
                    <Button size="sm" variant="primary" disabled={!(nbSourceModelPrices > 0)} onClick={this.launchAddPrices}><i className="mdi mdi-plus"/>Ajouter</Button>
                    <Button size="sm" variant="danger" disabled={!(this.state.selection.length > 0)} onClick={this.launchRemovePricesWithButton}><i className="mdi mdi-delete-forever"/>Retirer</Button>
                </div>
                <div className="col-6 btn-container-inline-right wrap">
                    {this.state.editConfigs ? 
                        <React.Fragment>
                            <Button size="sm" variant="secondary" onClick={this.cancelPricesConfigurations}><i className="mdi mdi-close" />Annuler</Button>
                            <Button size="sm" variant="info" onClick={this.savePricesConfigurations}><i className="mdi mdi-content-save" />Enregistrer</Button>
                        </React.Fragment>
                    :
                        <Button size="sm" variant="info" disabled={!(lengthObject(this.getEventPricesFromStore()) > 0)} onClick={this.launchEditPricesConfigurations}><i className="mdi mdi-pencil" />Éditer les maximums</Button>
                    }
                </div>
            </div>
        )
    }

    getRow(price, i){
        const selectRow = (e) => {
            this.setDeepState(s => {
                const PIDpos = s.selection.indexOf(price.PID);
                if(!(PIDpos >= 0)){
                    s.selection.push(price.PID);
                }else{
                    s.selection.splice(PIDpos, 1);
                }
            });
        }

        const { Menu, show } = ContextMenu.Menu({ id : price.PID });
        const d = {
            amount : `${(price.amount / 100).toFixed(2)} €`,
            description : price.description,
            badge : {
                letter : price.sejourRelationType === MODEL_RELATION_TYPE ? "M" : "G",
                bg : price.sejourRelationType === MODEL_RELATION_TYPE ? "relation-model" : "relation-global",
                title : price.sejourRelationType === MODEL_RELATION_TYPE ? "Tarif propre au modèle" : "Tarif global"
            },
            selected : this.state.selection.indexOf(price.PID) >= 0,
            pricesConfig : price.PID in this.state.configs ? {...this.state.configs[price.PID]} : {
                enableLimitEvent : false,
                enableLimitRegistration : false,
                limitEvent : 0,
                limitRegistration : 0
            }
        }
        if(this.state.editConfigs){
            d.pricesConfig.limitEventD = (d.pricesConfig.limitEvent === 0 && !d.pricesConfig.enableLimitEvent) ? "" : d.pricesConfig.limitEvent;
            d.pricesConfig.limitRegistrationD = (d.pricesConfig.limitRegistration === 0 && !d.pricesConfig.enableLimitRegistration) ? "" : d.pricesConfig.limitRegistration;
        }else{
            d.pricesConfig.limitEventD = d.pricesConfig.enableLimitEvent ? d.pricesConfig.limitEvent : '∞';
            d.pricesConfig.limitRegistrationD = d.pricesConfig.enableLimitRegistration ? d.pricesConfig.limitRegistration : '∞';
        }

        if(price.amount === 0){
            d.amount = "Gratuit";
        }

        if(price.description.length > 70){
            d.description = price.description.substr(0, 70) + "...";
        }

        const onChange = (prop, e) => {
            let state = this.getDeepState();
            switch (prop) {
                case "limitEvent":
                case "limitRegistration":
                    let isNotEmpty = !isEmpty(e.target.value) || parseInt(e.target.value) === 0;
                    if(!isNaN(e.target.value)){
                        if(isNotEmpty){
                                state.configs[price.PID][prop] = parseInt(e.target.value);
                        }else{
                            state.configs[price.PID][prop] = "";
                        }
                    }else{
                        isNotEmpty = false;
                    }

                    if(prop === "limitEvent"){
                        state.configs[price.PID]["enableLimitEvent"] = isNotEmpty;
                    }else if(prop === "limitRegistration"){
                        state.configs[price.PID]["enableLimitRegistration"] = isNotEmpty;
                    }
                    break;
                    
                case "enableLimitEvent":
                case "enableLimitRegistration":
                    let empty = true;
                    if(prop === "enableLimitEvent"){
                        empty = isEmpty(state.configs[price.PID]["limitEvent"]) && !(parseInt(state.configs[price.PID]["limitEvent"]) >= 0);
                    }else if(prop === "enableLimitRegistration"){
                        empty = isEmpty(state.configs[price.PID]["limitRegistration"]) && !(parseInt(state.configs[price.PID]["limitRegistration"]) >= 0);
                    }
                    if(!empty){
                        state.configs[price.PID][prop] = e.target.checked ? false : true;
                    }
                    break;
            }
            this.setState(state);
        }

        return(
            <React.Fragment key={i}>
                <div className={`price-row${d.selected ? " selected" : ""}`} onContextMenu={show}>
                    <div className="left-side">
                        <div className="row-selector">
                            <div className="form-check form-check-muted m-0">
                                <label className="form-check-label">
                                    <input  type="checkbox" 
                                            className="form-check-input" 
                                            checked={d.selected} 
                                            onChange={selectRow}
                                    />
                                    <i className="input-helper" />
                                </label>
                            </div>
                        </div>
                        <div className="price-infos">
                            <div className="price-desc-group">
                                <p className="price-label">
                                    <Badge className={`price-relation-type-badge ${d.badge.bg}`} title={d.badge.title} pill>{d.badge.letter}</Badge>
                                    {price.label}
                                </p>
                                <p className="price-desc">
                                    {d.description}
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="right-side">
                        <div className="price-amount-options">
                            <p className="price-amount display-5 text-success lh-1">{d.amount}</p>
                            <div className="btn-container-inline-center align-center justify-center m-0">
                                <Button disabled size="md" variant="success" className="btn-rounded btn-icon" onClick={() => this.launchConsultPriceWithContext(price.PID)} title={false ? "Voir dans le panneau des tarifs" : "Option indisponible"}><i className="mdi mdi-eye mr-0"/></Button>
                            </div>
                        </div>
                        <div className="max-inputs-group">
                            <div className="max-inputs-group-label">Maximum par</div>
                            <div className="inputs-container">
                                <div className="input-container">
                                    <label className="limit-label" htmlFor={`price-register-${price.PID}`}>Inscription</label>
                                    <input  type="text" 
                                            id={`price-register-${price.PID}`} 
                                            value={d.pricesConfig.limitRegistrationD} 
                                            readOnly={!this.state.editConfigs} 
                                            placeholder="∞"
                                            onChange={(e) => onChange("limitRegistration", e, this)}
                                    />
                                    <label className="no-limit-checkbox">
                                        {this.state.editConfigs ? <>
                                            <input  type="checkbox" 
                                                    tabIndex="-1"
                                                    checked={!d.pricesConfig.enableLimitRegistration} 
                                                    disabled={!this.state.editConfigs} 
                                                    onChange={(e) => onChange("enableLimitRegistration", e, this)}
                                            />
                                            Illimité </>
                                        : <>
                                            { d.pricesConfig.enableLimitRegistration ? "Limité" : "Illimité" }
                                        </> }
                                    </label>
                                </div>
                                <div className="input-container">
                                    <label className="limit-label" htmlFor={`price-event-${price.PID}`}>Événement</label>
                                    <input  type="text" 
                                            id={`price-event-${price.PID}`} 
                                            value={d.pricesConfig.limitEventD} 
                                            readOnly={!this.state.editConfigs} 
                                            placeholder="∞"
                                            onChange={(e) => onChange("limitEvent", e, this)}
                                    />
                                    <label className="no-limit-checkbox">
                                        {this.state.editConfigs ? <>
                                            <input  type="checkbox" 
                                                    tabIndex="-1"
                                                    checked={!d.pricesConfig.enableLimitEvent} 
                                                    disabled={!this.state.editConfigs} 
                                                    onChange={(e) => onChange("enableLimitEvent", e, this)}
                                            />
                                            Illimité </>
                                        : <>
                                            { d.pricesConfig.enableLimitEvent ? "Limité" : "Illimité" }
                                        </> }
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>
                    <Menu>
                        <ContextMenu.Item onClick={() => this.launchConsultPriceWithContext(price.PID)}><span><i className="mdi mdi-eye text-success"/> Consulter</span></ContextMenu.Item>
                        <ContextMenu.Item onClick={() => this.launchRemovePriceWithContext(price.PID)}><span><i className="mdi mdi-close text-danger"/> Retirer</span></ContextMenu.Item>
                    </Menu>
                </div>

            </React.Fragment>
        )
    }

    getTable(){
        const prices = this.getEventPricesFromStore();
        if(lengthObject(prices) > 0){
            return (
                <div className="prices-rows">
                    {Object.values(prices).map((price, i) => this.getRow(price, i))}
                </div>
            )
        }else{
           return (<p className="col-12 text-muted text-center">Aucun tarif ajouté</p>)
        }
    }
    render(){
        return (
            <div className="prices-tab">
                { this.getHeader() }
                { this.getTable() }
                <RemoveModal 
                    show={this.state.modals.delete}
                    EID={this.props.EID}
                    PIDS={this.state.selection}
                    parentState={this.state}
                    parentSetState={this.setState}
                    parentSetDeepState={this.setDeepState}
                    parentGetDeepState={this.getDeepState}
                />
                <AddModal 
                    show={this.state.modals.add}
                    EID={this.props.EID}
                    parentState={this.state}
                    parentSetState={this.setState}
                    parentSetDeepState={this.setDeepState}
                    parentGetDeepState={this.getDeepState}
                />
            </div>
        )
    }
    
}

const mapStateToProps = (state) => ({ sejours : state.sejours });

export default connect(mapStateToProps, null)(PricesTab);