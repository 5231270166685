import * as registrationsGlobalsActions from '../../../constants/registrations.globals.actions';

export default function globals(state, action){
    const n_state = { ...state };
    switch(action.type){
        case registrationsGlobalsActions.GET_REGISTRATIONS_AND_REGISTRANTS_AND_PAYMENTS:
            return { ...state,
                registrants: { ...state.registrants, ...action.payload.registrants },
                registrations: { ...state.registrations, ...action.payload.registrations },
                payments: { ...state.payments, ...action.payload.payments }
            };

        case registrationsGlobalsActions.UPDATE_REGISTRATIONS_AND_REGISTRANTS_AND_PAYMENTS:
            n_state.registrants = { ...n_state.registrants, ...action.payload.registrants };
            n_state.registrations = { ...n_state.registrations, ...action.payload.registrations };
            n_state.payments = { ...n_state.payments, ...action.payload.payments };

            return n_state;

        case registrationsGlobalsActions.DELETE_REGISTRATIONS_AND_REGISTRANTS_AND_PAYMENTS:
            if(action.payload.registrants && n_state.registrants){
                action.payload.registrants.forEach((m) => {
                    delete n_state.registrants[m]
                });
            }

            if(action.payload.registrations && n_state.registrations){
                action.payload.registrations.forEach((e) => {
                    delete n_state.registrations[e]
                });
            }

            if (action.payload.payments && n_state.payments) {
                action.payload.payments.forEach((p) => {
                    delete n_state.payments[p];
                });
            }

            return n_state;

        default:
            return state;
    }
}
