import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter, generatePath } from "react-router";
import { getSejoursModels } from "../../../../api/sejours/models";
import { lengthObject, setPageTitle } from "../../../../functions/common";
import { updateSejoursModelsEventsPrices__action } from "../../../../actions/sejours.globals.actions";
import { pushNotification } from "../../../../functions/notifications";
import { Button, Tab, Tabs } from "react-bootstrap";
import EventTab from "./tabs/event";
import RegistrationsTab from "../event/tabs/registrations/";
import PricesTab from "./tabs/prices/index";
import LoaderBox from "../../../../components/shared/loaders/loader.box";
import { apiRequest } from "../../../../functions/api";
import CBreadcrumb from "../../../../components/custom-boostrap/CBreadcrumb/CBreadcrumb";
import CBreadcrumbItem from "../../../../components/custom-boostrap/CBreadcrumb/CBreadcrumb.Item";
import * as moment from "moment";
import { updateSejoursEvents__action } from "../../../../actions/sejours.events.actions";
import { Link } from "react-router-dom";
import { updateUsers__action } from "../../../../actions/users.actions";

class SejourEvent extends Component{
    tabs = {
        event : { tab : "event", name : "Événement" },
        prices : { tab : "prices", name: "Tarifs" },
        registrations : { tab : "registrations", name: "Inscriptions" }
    }

    tabsParamsID = {
        EID : "EID",
        tab : "tab"
    }
    constructor(props) {
        super(props);
        this.state = {
            loaded : false,
            loading : false,
            loadingMess : "",
            tab : (this.props.match.params[this.tabsParamsID.tab] ? (this.existTab(this.props.match.params[this.tabsParamsID.tab]) ? this.props.match.params[this.tabsParamsID.tab] : this.tabs.event.tab) : this.tabs.event.tab)
        }

        this.notified = false;
        this.found = false;
        this.EID = this.props.match.params[this.tabsParamsID.EID];

        this.setLoader = this.setLoader.bind(this);
        this.changeTab = this.changeTab.bind(this);
        this.onToggleVisibleClick = this.onToggleVisibleClick.bind(this);

        console.log(props);
    }

    componentDidMount(){
        apiRequest({
            url : '/sejours.events/get',
            data : {
                EIDS : this.EID,
                archived : true,
                prices : true,
                models : true,
                guides : true
            }
        })
        .then((res) => {
            if(res.data.http_code === 200 && res.data.status === "success"){
                if(res.data.data.events){
                    const { events, models, prices, users } = res.data.data;
                    if(lengthObject(res.data.data.events) > 0){
                        this.props.dispatch(updateSejoursModelsEventsPrices__action({ events, models, prices }));
                        this.props.dispatch(updateUsers__action(users));
                        this.found = true;
                        this.setState({ loaded : true }, () => this.changeTab(this.state.tab));
                    }else{
                        this.redirect();
                    }
                }
            }
        })
    }

    redirect(){
        if(!this.notified){
            this.props.history.push('/sejours/events');
            pushNotification({
                type : "error",
                title : "Événement introuvable",
                icon : "mdi mdi-close-circle",
                content : <p>L'événement demandé n'existe pas</p>
            });
            this.notified = true;
        }
    }

    existTab(t){
        return t in this.tabs;
    }

    changeTab(t){
        t = this.existTab(t) ? t : this.tabs.event.tab;

        let currentMatch = this.props.match.path;
        const paramTab = `/:${this.tabsParamsID.tab}`;
        const paramTabPos = currentMatch.indexOf(paramTab);
        let newPath = this.props.location.pathname;
        if(!(paramTabPos >= 0) && t !== this.tabs.event.tab){
            currentMatch = this.props.match.path + paramTab;
        }else if(paramTabPos >= 0 && t === this.tabs.event.tab){
            currentMatch = currentMatch.substring(0, paramTabPos) + currentMatch.substring(paramTabPos + paramTab.length)
        }
        newPath = generatePath(currentMatch, { [this.tabsParamsID.EID] : this.EID, [this.tabsParamsID.tab] : t });
        this.props.history.replace(newPath);

        if(t !== this.state.tab){
            this.setState({ tab : t });
        }
    }

    setLoader({ loading, mess = "" }){
        this.setState({
            loading : loading,
            loadingMess : mess
        });
    }

    onToggleVisibleClick(){
        this.setLoader({ loading : true, mess : "Modification en cours..."});
        apiRequest({
            url : '/sejours.events/put',
            data : {
                EID : this.EID,
                event : {
                    visible : !this.props.state.sejours.events[this.EID].visible
                }
            }
        })
        .then((res) => {
            const errorNotification = (code) => pushNotification({
                type : "error",
                title : "Erreur du serveur",
                icon : "mdi mdi-close",
                content : <p>Impossible de modifier l'état de l'événement ({code})</p>
            });

            if(res.data.http_code === 200){
                if(res.data.status === "success"){
                    if(res.data.data && res.data.data.event){
                        this.props.dispatch(updateSejoursEvents__action([res.data.data.event]));
                        pushNotification({
                            type : "success",
                            title : `Événement ${res.data.data.event.visible ? 'affiché' : 'masqué'}`,
                            icon : "mdi mdi-check",
                            content : <p>L'événement a été correctement modifié.</p>
                        });
                    }else{
                        errorNotification(res.data.status_code)
                    }
                }else{
                    errorNotification(res.data.status_code)
                }
            }else{
                errorNotification(res.data.status_code)
            }
        })
        .finally(() => this.setLoader({ loading : false }))
    }

    getTabs(){
        return (
            <Tabs activeKey={this.state.tab} onSelect={this.changeTab}>
                <Tab eventKey={this.tabs.event.tab} title={this.tabs.event.name}>
                    <EventTab EID={this.EID} setLoader={this.setLoader} selectedTab={this.tabs.event.tab === this.state.tab}/>
                </Tab>
                <Tab eventKey={this.tabs.prices.tab} title={this.tabs.prices.name}>
                    <PricesTab EID={this.EID} setLoader={this.setLoader} selectedTab={this.tabs.prices.tab === this.state.tab}/>
                </Tab>
                <Tab eventKey={this.tabs.registrations.tab} title={this.tabs.registrations.name}>
                    <RegistrationsTab EID={this.EID} setLoader={this.setLoader} selectedTab={this.tabs.registrations.tab === this.state.tab}/>
                </Tab>
            </Tabs>
        )
    }

    getHeader(){
        const { event, sourceModel } = this.getData();
        const getComplete = () => {
            if(event.registrants.length >= event.nbRegistrantsMax){
                return (
                    <span className="text-muted text-center m-0"><i className="mdi mdi-check-circle text-success" aria-hidden="true" /> Complet </span>
                )
            }else{
                return (
                    <span className="text-muted text-center m-0"><i className="mdi mdi-account-convert text-success" aria-hidden="true" /> Incomplet </span>
                )
            }
        }

        const getVisible = () => {
            if (event.archived) {
                return (<span className="text-muted text-center m-0"><i className="mdi mdi-archive text-danger"
                                                                aria-hidden="true"/> Archivé </span>)
            } else if (event.visible) {
                return (
                    <span className="text-muted text-center m-0"><i className="mdi mdi-eye text-warning" aria-hidden="true" /> Visible </span>
                )
            } else {
                return (
                    <span className="text-muted text-center m-0"><i className="mdi mdi-eye-off text-warning" aria-hidden="true" /> Masqué </span>
                )
            }
        }

        const getInscrits = () => {
            const nbRegistrants = event.registrants ? event.registrants.length : 0;
            return (
                <span className="text-muted text-center m-0"><i className="mdi mdi-account-check text-info" aria-hidden="true" /> { nbRegistrants > 0 ? nbRegistrants + (nbRegistrants > 1 ? " inscrits" : " inscrit") : "Pas d'inscrits"} </span>
            )
        }

        return (
            <div className="sejour-model-header">
                <h3 className="mb-0">{`Du ${event.startDate.format("DD MMMM YYYY")}`}</h3>
                <h3 className="mb-2">{`au ${event.endDate.format("DD MMMM YYYY")}`}</h3>
                <Link to={`/sejours/models/${sourceModel.SID}`}><h5 className="mb-0">{sourceModel.name}</h5></Link>
                <div className="sejour-model-infos">
                    <div>{ getComplete() }</div>
                    <div>{ getVisible() }</div>
                    <div>{ getInscrits() }</div>
                </div>
                <div className="btn-container-inline-left">
                    {event.archived ? null : <Button
                        size="sm" 
                        variant={event.visible ? "secondary" : "success"} 
                        onClick={this.onToggleVisibleClick}
                        disabled={!(event.prices.length > 0)}
                        title={event.prices.visible ? "Masquer l'événement" : ((event.prices.length > 0) ? "Rendre l'événement visible" : "Ajouter au moins un tarif pour pouvoir rendre visible")}
                    >
                        <i className="mdi mdi-sync" aria-hidden="true" />
                        {event.visible ? "Masquer" : "Rendre visible"}
                    </Button>}
                    <Button size="sm" variant="danger" disabled title="Option indisponible"><i className="mdi mdi-lock" aria-hidden="true" />Supprimer</Button>
                </div>
                {event.archived
                    ? <div className="alert alert-danger mt-3" role="alert">Les modifications que vous apporterez à cet événement ne seront pas visibles car l'événement est archivé.</div>
                    : null
                }
            </div>
        )
    }

    getData(){
        let event = { ...this.props.state.sejours.events[this.EID] }
        event.startDate = moment(event.startDate);
        event.endDate = moment(event.endDate);
        event.ticketingStartDate = moment(event.ticketingStartDate);
        event.ticketingEndDate = moment(event.ticketingEndDate);

        return {
            event,
            sourceModel : this.props.state.sejours.models[event.sejourRelationID]
        }
    }

    render(){
        if((this.EID in this.props.state.sejours.events) && this.state.loaded && this.found){
            const { event, sourceModel } = this.getData();
            setPageTitle(`${sourceModel.name} - Du ${event.startDate.format("DD/MM/YYYY")} au ${event.endDate.format("DD/MM/YYYY")} | Séjours`);
            return (
                <React.Fragment>
                    <CBreadcrumb className="bg-card no-border">
                        <CBreadcrumbItem to={"/sejours/events"}>Événements</CBreadcrumbItem>
                        <CBreadcrumbItem 
                            active={this.state.tab === this.tabs.event.tab} 
                            onClick={() => this.changeTab(this.tabs.event.tab)} 
                            to={`/sejours/events/${this.EID}`}>{`${sourceModel.name} - Du ${event.startDate.format("DD/MM/YYYY")} au ${event.endDate.format("DD/MM/YYYY")}`}
                        </CBreadcrumbItem>
                        {(this.state.tab && this.state.tab !== this.tabs.event.tab) ? 
                            <CBreadcrumbItem active to={`/sejours/events/${this.EID}/${this.state.tab}`}>{this.tabs[this.state.tab].name}</CBreadcrumbItem>
                        : null}
                    </CBreadcrumb>
                    <LoaderBox show={this.state.loading} mess={this.state.loadingMess}>
                        <div className="card sejour-model">
                            <div className="card-body">
                                { this.getHeader() }
                                { this.getTabs() }
                            </div>
                        </div>
                    </LoaderBox>
                </React.Fragment>
            )
        }else{
            return (<div></div>)
        }
    }
}

const mapStateToProps = (state) => ({ state });

export default connect(mapStateToProps, null)(withRouter(SejourEvent));