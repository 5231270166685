import * as sejoursEventsActions from '../../../constants/sejours.events.actions';

export default function events(state, action){
    const n_state = { ...state };
    switch(action.type){
        case sejoursEventsActions.GET_SEJOURS_EVENTS:
            return { ...state, events : { ...state.events, ...action.payload } }

        case sejoursEventsActions.UPDATE_SEJOURS_EVENTS:
            action.payload.forEach((event) => {
                n_state.events[event.EID] = event;
                const modelSID = event.sejourRelationID;
                if(modelSID in n_state.models){
                    const eventPos = n_state.models[modelSID].events.indexOf(event.EID);
                    if(!(eventPos >= 0)){
                        n_state.models[modelSID].events.push(event.EID);
                    }
                }
            });
            return n_state;

        case sejoursEventsActions.DELETE_SEJOURS_EVENTS:
            action.payload.forEach((EID) => {
                if(EID in n_state.events){
                    const modelSID = n_state.events[EID].sejourRelationID;
                    if(modelSID in n_state.models){
                        const eventPos = n_state.models[modelSID].events.indexOf(EID);
                        if(eventPos >= 0){
                            n_state.models[modelSID].events.splice(eventPos, 1);
                        }
                    }
                    delete n_state.events[EID];
                }
            });
            return n_state;
        
        default:
            return state;
    }
}