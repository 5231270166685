import React, { Component } from "react";

class RegistrantDetails extends Component {
    constructor(props) {
        super(props);
        this.state = {
            fold: true
        }
    }

    get registrant() {
        return this.props.registrant;
    }

    get model() {
        return this.props.model;
    }

    get price() {
        return this.props.price;
    }

    getPayerBadge() {
        if (this.registrant.isPayer) {
            return <span className="ml-2 badge bg-primary px-2 py-1">Payeur</span>;
        }
        return null;
    }

    getPriceBadge() {
        const amount = this.price.amount / 100;

        if (amount === 0) {
            return <span className="ml-2 badge bg-primary px-2 py-1">Gratuit</span>;
        }
        return <span className="ml-2 badge bg-primary px-2 py-1"><i className={"mdi mdi-tag"}/>{this.price.label} - {amount.toFixed(2)}€</span>;
    }

    renderOptions(RID, options, model) {
        return <ul>
            {(Object.entries(options)).map(([ key, value ]) => {
                const config = model.registrantOptions[key];
                return <li key={`${RID}_options_${key}`}>
                    <b>{config.shortLabel} :</b> {config.values[value].shortLabel}
                </li>
            })}
        </ul>;
    }

    render() {
        const registrant = this.registrant;
        const model = this.model;

        return (
            <div className={"registrant-details card card-second" + (this.state.fold ? " fold" : "")}>
                <div className="card-header registrant-details__header"
                     onClick={() => this.setState({fold: !this.state.fold})}>
                    <div className="registrant-details__header__name">
                        <i className={"mdi mdi-account pr-2"}/>{registrant.name.toUpperCase()} {registrant.firstName} {this.getPayerBadge()}
                    </div>
                    <div>
                        <i className={"mdi mdi-chevron-" + (this.state.fold ? "down" : "up")}/>
                    </div>
                </div>
                <div className="card-body registrant-details__body">
                    <div className={"row"}>
                        <div className={"col-xl-6 col-md-6 col-sm-12"}>
                            <div className="row mb-1">
                                <div className="col-xl-6 col-md-6 col-sm-12">
                                    <b>Nom&nbsp;:</b> {registrant.name}
                                </div>
                                <div className="col-xl-6 col-md-6 col-sm-12">
                                    <b>Prénom&nbsp;:</b> {registrant.firstName}
                                </div>
                            </div>
                            <div className="row mb-1">
                                <div className={"col-xl-6 col-md-6 col-sm-12"}>
                                    <b>Téléphone&nbsp;:</b> <a href={"tel:" + registrant.phone}>{registrant.phone}</a>
                                </div>
                                <div className="col-xl-6 col-md-6 col-sm-12">
                                    <b>Email&nbsp;:</b> <a href={"mailto:" + registrant.email}>{registrant.email}</a>
                                </div>
                            </div>
                            <div className={"row mb-1"}>
                                <div className="col-xl-6 col-md-6 col-sm-12">
                                    <b>Adresse :</b>
                                    <address className={"mt-1"}>
                                        <p>{registrant.address}</p>
                                        <p>{registrant.postalCode} - {registrant.city}</p>
                                        <p>{registrant.country}</p>
                                    </address>
                                </div>
                                <div className="col-xl-6 col-md-6 col-sm-12">
                                    <b>Tarif :</b> {this.getPriceBadge()}
                                </div>
                            </div>
                        </div>
                        <div className={"col-xl-6 col-md-6 col-sm-12"}>
                            <p className={"mb-1"}><b>Options:</b></p>
                            <div className={"ml-4"}>
                                {this.renderOptions(registrant.RID, registrant.options, model)}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default RegistrantDetails;