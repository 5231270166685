import { Component } from "react";

export default class PaymentDetailsTable extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        const total = this.props.total / 100;
        return <table className="table table-bordered table-striped">
            <thead>
            <tr>
                <th>Désignation</th>
                <th>Prix unitaire</th>
                <th>Quantité</th>
                <th>Total</th>
            </tr>
            </thead>
            <tbody>
            {this.props.details.map((line, index) => {
                const amount = line.amount / 100;
                const subTotal = line.amount * line.quantity / 100;
                return <tr key={index}>
                    <td>{line.label}</td>
                    <td>{parseFloat(amount).toFixed(2)}€</td>
                    <td>{line.quantity}</td>
                    <td>{parseFloat(subTotal).toFixed(2)}€</td>
                </tr>
            })}
            </tbody>
            <tfoot>
            <tr>
                <td colSpan={3}>Total</td>
                <td>{parseFloat(total).toFixed(2)}€</td>
            </tr>
            </tfoot>
        </table>
    }
}