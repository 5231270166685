import React, { Component } from 'react';
import RegistrationsRegistrantsSearcher from "../../components/pages/registrations/registrants";
import { setPageTitle } from "../../functions/common";

class RegistrationsRegistrants extends Component {
    constructor(props) {
        super(props);
        setPageTitle("Inscrits");
    }
    render() {
        return <div>
            <h2>Inscrits</h2>
            <RegistrationsRegistrantsSearcher />
        </div>
    }
}

export default RegistrationsRegistrants;