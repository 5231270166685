import React, { Component } from "react";
import EventCard from "./card";

class EventsContainer extends Component{
    constructor(props) {
        super(props);
        this.onSelect = this.onSelect.bind(this);
    }

    getLoader(EID){
        if(Object.keys(this.props.loaders).includes(EID)){
            return this.props.loaders[EID];
        }else{
            return { show : false, mess : "" }
        }
    }

    getSelection(){
        if(this.props.selection){
            return this.props.selection;
        }else{
            return [];
        }
    }

    onSelect(d){
        if(this.props.onSelect){
            this.props.onSelect(d);
        }
    }

    renderCards(){
        return Object.entries(this.props.events).map(([EID, event], i) => {
            const selected = this.props.selection.includes(EID);
            const model = (event.sejourRelationID in this.props.models) ? this.props.models[event.sejourRelationID] : false
            return <EventCard 
                        selected={selected} 
                        event={event} 
                        sourceModel={model ? model : { name : "", EID : ""}}
                        showSourceModel
                        loader={this.getLoader(EID)} 
                        onSelectChange={this.onSelect} 
                        key={i} 
            />
        });
    }

    render(){
        return (
            <div className="ml-0 mr-0 row">
                { this.renderCards() } 
            </div>
        )
    }
    
}

export default EventsContainer;