import { combineReducers } from "redux";
import users from './users.reducer';
import app from './app.reducer';
import sejours from './sejours.reducer';
import registrations from "./registrations.reducer";

export default combineReducers({
    users,
    app,
    sejours,
    registrations
})