import React, { Component } from "react";
import { Button, Modal } from "react-bootstrap";
import { mergeProps } from "../../../functions/react.utilities";
import LoaderBox from "../../shared/loaders/loader.box";

class ConfirmDialog extends Component{
    constructor(props) {
        super(props);
        this.state = {
            show : this.props.show
        }
        this.step = 0;

    }

    componentDidUpdate(){
        if(this.props.onHide && this.props.show != this.state.show){
            this.setState({ show : this.props.show });
        }else if(!this.props.onHide && this.props.show){
            if(this.step === 0){this.setState({ show : this.props.show })}

            if(this.step === 0 || this.step === 1) this.step++;
            else{this.step = 0}
        }
    }

    getConfirmProps(){
        return mergeProps({
            sameNameProps : ["active", "block", "variant", "size", "disabled", "text", "onClick"],
            props : this.props.confirm,
            defaultProps : {
                variant : "primary",
                className : "",
                disabled : false,
                onClick : this.onHide.bind(this),
                text : "Oui"
            }
        });
    }

    getDeclineProps(){
        return mergeProps({
            sameNameProps : ["active", "block", "variant", "size", "disabled", "text", "onClick"],
            props : this.props.decline,
            defaultProps : {
                variant : "secondary",
                className : "",
                disabled : false,
                onClick : this.onHide.bind(this),
                text : "Non"
            }
        });
    }

    getModalProps(){
        return mergeProps({
            sameNameProps : ["size", "centered", "backdrop", "backdropClassName", "keyboard", "animation", "dialogClassName", "dialogAs", "autoFocus", "enforceFocus", "restoreFocus", "onShow", "onHide", "container", "scrollable", "onEscapeKeyDown"],
            escapeProps : ["show"],
            props : this.props
        });
    }


    onHide(){
        if(this.props.onHide) {
            this.props.onHide();
        }else{
            this.setState({ show : false }); 
        }  
    }



    render(){
        const confirmProps = this.getConfirmProps();
        const declineProps = this.getDeclineProps();
        const modalProps = this.getModalProps();
        return (
            <Modal show={this.state.show} onHide={this.onHide.bind(this)} {...modalProps}>
                <Modal.Header closeButton={this.props.closeButton}>{this.props.modalTitle}</Modal.Header>
                <LoaderBox show={this.props.loading ? true : false} mess={this.props.loadingMess ? this.props.loadingMess : ""} inline={this.props.loadingInline ? true : false}>
                    <Modal.Body>{this.props.children}</Modal.Body>
                </LoaderBox>
                <Modal.Footer>
                    <Button className={declineProps.className} variant={declineProps.variant} onClick={declineProps.onClick} disabled={declineProps.disabled}>{declineProps.text}</Button>
                    <Button className={confirmProps.className} variant={confirmProps.variant} onClick={confirmProps.onClick} disabled={confirmProps.disabled}>{confirmProps.text}</Button>
                </Modal.Footer>
            </Modal>
        )
    }
    
}

export default ConfirmDialog