import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import { apiRequest } from "../../functions/api";
import { lengthObject } from "../../functions/common";
import { updateSejoursModelsEventsPrices__action } from "../../actions/sejours.globals.actions";
import { pushNotification } from "../../functions/notifications";
import { updateRegistrationsAndRegistrantsAndPayments__action } from "../../actions/registrations.globals.actions";
import { connect } from "react-redux";
import { RegistrationStatus } from "../../constants/api/registrations";
import moment from "moment/moment";
import RegistrantDetails from "../../components/pages/registrations/registrations/RegistrantDetails";
import { PaymentSummary } from "../../components/pages/registrations/shared/PaymentSummary";
import PaymentDetailsTable from "../../components/pages/registrations/shared/PaymentDetailsTable";

class RegistrationDetailsPage extends Component {
    constructor(props) {
        super(props);
        console.log(this);
    }

    get RFID() {
        return this.props.match.params['RFID'];
    }

    get event() {
        const EID = this.registration?.eventRelationID;
        return EID in this.props.state.sejours.events
            ? this.props.state.sejours.events[EID]
            : null;
    }

    get model() {
        const SID = this.registration?.sejourRelationID;
        return SID in this.props.state.sejours.models
            ? this.props.state.sejours.models[SID]
            : null;
    }

    get payment() {
        const PRID = this.registration?.paymentRelationID;

        return PRID in this.props.state.registrations.payments
            ? this.props.state.registrations.payments[PRID]
            : null;
    }

    get registration() {
        return this.RFID in this.props.state.registrations?.registrations
            ? this.props.state.registrations.registrations[this.RFID]
            : null;
    }

    get registrants() {
        if (!this.registration) {
            return [];
        }
        const registrants = [];

        for (const RID of this.registration.registrants) {
            if (RID in this.props.state.registrations.registrants) {
                registrants.push(this.props.state.registrations.registrants[RID]);
            }
        }
        return registrants;
    }

    get prices() {
        return this.props.state.sejours.prices;
    }

    componentDidMount() {
        apiRequest({
            url: '/sejours.registrations/get',
            data: {
                RFIDS: this.RFID
            }
        })
            .then((res) => {
                if (res.data.http_code === 200 && res.data.status === "success") {
                    if (res.data.data.events) {
                        const {events, models, prices, registrations, registrants, payments} = res.data.data;
                        if (registrations && lengthObject(registrations) > 0) {
                            this.props.dispatch(updateRegistrationsAndRegistrantsAndPayments__action({
                                registrants,
                                registrations,
                                payments
                            }));
                            this.props.dispatch(updateSejoursModelsEventsPrices__action({
                                models,
                                events,
                                prices
                            }));
                            this.found = true;
                        } else {
                            this.redirect();
                        }
                    }
                }
            })
    }

    redirect() {
        if (!this.notified) {
            this.props.history.push('/registrations/registrants');
            pushNotification({
                type: "error",
                title: "Inscription introuvable",
                icon: "mdi mdi-close-circle",
                content: <p>L'inscription demandée n'existe pas</p>
            });
            this.notified = true;
        }
    }

    getValidationBadge(status) {
        switch (status) {
            case RegistrationStatus.PENDING:
                return <span className="badge badge-warning ml-1">En attente</span>;
            case RegistrationStatus.VALIDATED:
                return <span className="badge badge-success ml-1">Validée</span>;
            case RegistrationStatus.CANCELLED:
                return <span className="badge badge-danger ml-1">Annulée</span>;
            default:
                return <span className="badge badge-info ml-1">Inconnu</span>;
        }
    }

    copyRefToClipboard() {
        navigator.clipboard.writeText(this.registration.RFID);
        pushNotification({
            type: "primary",
            title: "Référence copiée",
            icon: "mdi mdi-clipboard-check",
            content: <p>La référence a été copiée dans le presse-papier</p>
        });
    }

    render() {
        const registration = this.registration;
        const event = this.event;
        const model = this.model;
        const payment = this.payment;

        if (!registration || !event || !model || !payment) {
            return <div>Chargement...</div>
        }

        const startDate = event ? moment(event.startDate).format('DD/MM/YYYY') : "Inconnu";
        const endDate = event ? moment(event.endDate).format('DD/MM/YYYY') : "Inconnu";
        const registrants = this.registrants;
        return (
            <div>
                <h2>Détails de l'inscription</h2>
                <div className="row">
                    <div className="col grid-margin stretch-card">
                        <div className="card">
                            <div className="card-body">
                                <h4 className="card-title mb-3">Informations générales</h4>
                                <div className='registrations-infos'>
                                    <div className={"registrations-infos__event"}>
                                        <p className={"mb-1"}><i
                                            className={"mdi mdi-map-check pr-2"}/>Séjour&nbsp;: <Link
                                            to={`/sejours/models/${model.SID}`}>{model.name}</Link></p>
                                        <p className={"mb-1"}><i
                                            className={"mdi mdi-calendar pr-2"}/>Événement&nbsp;: <Link
                                            to={`/sejours/events/${event.EID}`}>Du {startDate} au {endDate}</Link></p>
                                        <p className={"mb-1"}><i
                                            className={"mdi mdi-account-group pr-2"}/>Nombre
                                            d'inscrits&nbsp;: {registrants.length}
                                        </p>
                                        <p className={"mb-1"}><i
                                            className={"mdi mdi-help-circle pr-2"}/>Statut&nbsp;: {this.getValidationBadge(registration.status)}
                                        </p>
                                        <p className={"mb-1"}>
                                            <i className={"mdi mdi-pound pr-2"}/>Référence interne: <b
                                            className={"text-primary cursor-pointer"} onClick={this.copyRefToClipboard.bind(this)}>{registration.RFID}</b>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col grid-margin stretch-card">
                        <div className="card">
                            <div className="card-body">
                                <h4 className="card-title mb-3">Paiement</h4>
                                <PaymentSummary payment={payment}/>
                                <div className={"mb-4"}/>
                                <PaymentDetailsTable details={payment.details} total={payment.amount}/>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col grid-margin stretch-card">
                        <div className="card">
                            <div className="card-body">
                                <h4 className="card-title mb-3">Participants</h4>
                                <div className='registrants-details-list'>
                                    {registrants.map((registrant, index) => {
                                        const price = this.prices[registrant.priceRelationID];

                                        return <RegistrantDetails key={index}
                                                                  registrant={registrant}
                                                                  model={model}
                                                                  price={price}

                                        />
                                    })}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({state});

export default connect(mapStateToProps, null)(withRouter(RegistrationDetailsPage));
