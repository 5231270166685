import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter, generatePath } from "react-router";
import { getSejoursModels } from "../../../../api/sejours/models";
import { lengthObject, setPageTitle } from "../../../../functions/common";
import { updateSejoursModelsEventsPrices__action } from "../../../../actions/sejours.globals.actions";
import { pushNotification } from "../../../../functions/notifications";
import { Button, Tab, Tabs } from "react-bootstrap";
import ModelTab from "../model/tabs/model";
import EventsTab from "../model/tabs/events";
import PricesTab from "./tabs/prices/index";
import LoaderBox from "../../../../components/shared/loaders/loader.box";
import { apiRequest } from "../../../../functions/api";
import { getSejoursModels__action } from "../../../../actions/sejours.models.actions";
import CBreadcrumb from "../../../../components/custom-boostrap/CBreadcrumb/CBreadcrumb";
import CBreadcrumbItem from "../../../../components/custom-boostrap/CBreadcrumb/CBreadcrumb.Item";

class SejourModel extends Component{
    tabs = {
        model : { tab : "model", name : "Modèle" },
        events : { tab : "events", name: "Événements" },
        prices : { tab : "prices", name: "Tarifs" },
        registrations : { tab : "registrations", name: "Inscriptions" }
    }

    tabsParamsID = {
        SID : "SID",
        tab : "tab"
    }
    constructor(props) {
        super(props);
        this.state = {
            loaded : false,
            loading : false,
            loadingMess : false,
            tab : (this.props.match.params[this.tabsParamsID.tab] ? (this.existTab(this.props.match.params[this.tabsParamsID.tab]) ? this.props.match.params[this.tabsParamsID.tab] : this.tabs.model.tab) : this.tabs.model.tab)
        }

        this.notified = false;
        this.found = false;
        this.SID = this.props.match.params[this.tabsParamsID.SID];

        this.setLoader = this.setLoader.bind(this);
        this.changeTab = this.changeTab.bind(this);
        this.onToggleActiveClick = this.onToggleActiveClick.bind(this);
    }

    componentDidMount(){
        apiRequest({
            url : '/sejours.models/get',
            data : {
                SIDS : this.SID,
                prices : true,
                events : true
            }
        })
        .then((res) => {
            if(res.data.http_code === 200 && res.data.status === "success"){
                if(res.data.data.models){
                    if(lengthObject(res.data.data.models) > 0){
                        this.props.dispatch(updateSejoursModelsEventsPrices__action(res.data.data));
                        this.found = true;
                        this.setState({ loaded : true }, () => this.changeTab(this.state.tab));
                    }else{
                        this.redirect();
                    }
                }
            }
        })
    }

    redirect(){
        if(!this.notified){
            this.props.history.push('/sejours/models');
            pushNotification({
                type : "error",
                title : "Modèle introuvable",
                icon : "mdi mdi-close-circle",
                content : <p>Le modèle demandé n'existe pas</p>
            });
            this.notified = true;
        }
    }

    existTab(t){
        return Object.keys(this.tabs).indexOf(t) >= 0
    }

    changeTab(t){
        t = this.existTab(t) ? t : this.tabs.model.tab;

        let currentMatch = this.props.match.path;
        const paramTab = `/:${this.tabsParamsID.tab}`;
        const paramTabPos = currentMatch.indexOf(paramTab);
        let newPath = this.props.location.pathname;
        if(!(paramTabPos >= 0) && t !== this.tabs.model.tab){
            currentMatch = this.props.match.path + paramTab;
        }else if(paramTabPos >= 0 && t === this.tabs.model.tab){
            currentMatch = currentMatch.substring(0, paramTabPos) + currentMatch.substring(paramTabPos + paramTab.length)
        }
        newPath = generatePath(currentMatch, { [this.tabsParamsID.SID] : this.SID, [this.tabsParamsID.tab] : t });
        this.props.history.replace(newPath);

        if(t !== this.state.tab){
            this.setState({ tab : t });
        }
    }

    setLoader({ loading, mess = "" }){
        this.setState({
            loading : loading,
            loadingMess : mess
        });
    }

    onToggleActiveClick(){
        this.setLoader({ loading : true, mess : "Modification en cours..."});
        apiRequest({
            url : '/sejours.models/put',
            data : {
                SID : this.SID,
                model : {
                    enabled : !this.props.state.sejours.models[this.SID].enabled
                }
            }
        })
        .then((res) => {
            const errorNotification = (code) => pushNotification({
                type : "error",
                title : "Erreur du serveur",
                icon : "mdi mdi-close",
                content : <p>Impossible de modifier l'état du modèle ({code})</p>
            });

            if(res.data.http_code === 200){
                if(res.data.status === "success"){
                    if(res.data.data && res.data.data.model){
                        this.props.dispatch(getSejoursModels__action({ [this.SID] : res.data.data.model }));
                        pushNotification({
                            type : "success",
                            title : `Modèle ${res.data.data.model.enabled ? 'activé' : 'désactivé'}`,
                            icon : "mdi mdi-check",
                            content : <p>Le modèle a été correctement modifié.</p>
                        });
                    }else{
                        errorNotification(res.data.status_code)
                    }
                }else{
                    errorNotification(res.data.status_code)
                }
            }else{
                errorNotification(res.data.status_code)
            }
        })
        .finally(() => this.setLoader({ loading : false }))
    }

    getTabs(){
        return (
            <Tabs activeKey={this.state.tab} onSelect={this.changeTab}>
                <Tab eventKey={this.tabs.model.tab} title={this.tabs.model.name}>
                    <ModelTab SID={this.SID} setLoader={this.setLoader} selectedTab={this.tabs.model.tab === this.state.tab}/>
                </Tab>
                <Tab eventKey={this.tabs.events.tab} title={this.tabs.events.name}>
                    <EventsTab SID={this.SID} setLoader={this.setLoader} selectedTab={this.tabs.events.tab === this.state.tab}/>
                </Tab>
                <Tab eventKey={this.tabs.prices.tab} title={this.tabs.prices.name}>
                    <PricesTab SID={this.SID} setLoader={this.setLoader} selectedTab={this.tabs.prices.tab === this.state.tab}/>
                </Tab>
            </Tabs>
        )
    }

    

    getHeader(){
        const sejourModel = this.props.state.sejours.models[this.SID];
        const getEnable = () => {
            if(sejourModel.enabled){
                return (
                    <span className="enable-status text-muted text-center m-0"><i className="mdi mdi-check-circle text-success" aria-hidden="true" /> Actif </span>
                )
            }else{
                return (
                    <span className="enable-status text-muted text-center m-0"><i className="mdi mdi-block-helper text-danger" aria-hidden="true" /> Inactif </span>
                )
            }
        }
        const getEvents = () => {
            const nbEnvent = sejourModel.events ? sejourModel.events.length : 0;
            return (
                <span className="text-muted text-center m-0"><i className="mdi mdi-calendar-check text-warning" aria-hidden="true" /> { nbEnvent > 0 ? nbEnvent + (nbEnvent > 1 ? " événements" : " événement") : "Pas d'événements"} </span>
            )
        }

        const getInscrits = () => {
            const nbEnvent = sejourModel.events ? sejourModel.events.length : 0;
            return (
                <span className="text-muted text-center m-0"><i className="mdi mdi-account-check text-info" aria-hidden="true" /> { nbEnvent > 0 ? nbEnvent + (nbEnvent > 1 ? " inscrits" : " inscrit") : "Pas d'inscrits"} </span>
            )
        }

        return (
            <div className="sejour-model-header">
                <h3 className="mb-0">{sejourModel.name}</h3>
                <div className="sejour-model-infos">
                    <div>{ getEnable() }</div>
                    <div>{ getEvents() }</div>
                    { /*<div>{ getInscrits() }</div>*/ }
                </div>
                <div className="btn-container-inline-left">
                    <Button size="sm" variant={sejourModel.enabled ? "secondary" : "success"} onClick={this.onToggleActiveClick}><i className="mdi mdi-sync" aria-hidden="true" />{sejourModel.enabled ? "Désactiver" : "Activer"}</Button>
                    <Button size="sm" variant="danger" disabled title="Option indisponible"><i className="mdi mdi-lock" aria-hidden="true" />Supprimer</Button>
                </div>
            </div>
        )
    }

    getPage(){
        if(this.props.state.sejours.models.hasOwnProperty(this.SID) && this.state.loaded && this.found){
            const sejourModel = this.props.state.sejours.models[this.SID];
            setPageTitle(`${sejourModel.name} | Séjours`);
            return (
                <React.Fragment>
                    <CBreadcrumb className="bg-card no-border">
                        <CBreadcrumbItem to={"/sejours/models"}>Modèles</CBreadcrumbItem>
                        <CBreadcrumbItem active={this.state.tab === this.tabs.model.tab} onClick={() => this.changeTab(this.tabs.model.tab)} to={`/sejours/models/${this.SID}`}>{this.props.state.sejours.models[this.SID].name}</CBreadcrumbItem>
                        {(this.state.tab && this.state.tab !== this.tabs.model.tab) ? 
                            <CBreadcrumbItem active to={`/sejours/models/${this.SID}/${this.state.tab}`}>{this.tabs[this.state.tab].name}</CBreadcrumbItem>
                        : null}
                    </CBreadcrumb>
                    <LoaderBox show={this.state.loading} mess={this.state.loadingMess}>
                        <div className="card sejour-model">
                            <div className="card-body">
                                { this.getHeader() }
                                { this.getTabs() }
                            </div>
                        </div>
                    </LoaderBox>
                </React.Fragment>
            )
        }else{
            return (<div></div>)
        }
    }

    render(){
        return this.getPage();
    }
}

const mapStateToProps = (state) => ({ state });

export default connect(mapStateToProps, null)(withRouter(SejourModel));