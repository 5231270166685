import React, { Component } from 'react';
import { Form, Row, Button, InputGroup } from 'react-bootstrap';
import { connect } from 'react-redux';
import { updateSejoursEvents__action } from '../../../../../../actions/sejours.events.actions';
import { apiRequest } from '../../../../../../functions/api';
import { isEmpty } from '../../../../../../functions/common';
import { pushNotification } from '../../../../../../functions/notifications';
import { getDeepState, setDeepState } from '../../../../../../functions/react.utilities';

class ConfigurationTab extends Component {
    constructor(props) {
        super(props);
        this.setDeepState = setDeepState(this);
        this.getDeepState = getDeepState(this);

        this.state = {
            edit : false,
            isValid : false,
            nbRegistrantsMax : this.getEvent().nbRegistrantsMax
        }

        this.launchSaveEdit = this.launchSaveEdit.bind(this);
        this.onCancel = this.onCancel.bind(this);
    }

    getEvent(){
        return { ...this.props.sejours.events[this.props.EID] };
    }

    launchSaveEdit(e){
        e.preventDefault();
        if(this.state.edit){
            this.launchSave();
        }else{
            this.launchEdit();
        }
    }

    launchEdit(){
        this.setState({ edit : true });
    }

    launchSave(){
        let state = this.getDeepState();
        apiRequest({
            url : '/sejours.events/put',
            data : {
                EID : this.props.EID,
                event : {
                    nbRegistrantsMax : !isNaN(this.state.nbRegistrantsMax) ? parseInt(this.state.nbRegistrantsMax) : 0
                }
            }
        })
        .then((res) => {
            const errorNotification = (m) => {
                pushNotification({
                    type : "error",
                    title : "Erreur",
                    icon : "mdi mdi-close",
                    content : <p>{m}</p>
                });
            }
            if(res.data.http_code === 200){
                if(res.data.status === "success"){
                    if(res.data.data.event){
                        this.props.dispatch(updateSejoursEvents__action([res.data.data.event]));
                        this.setDeepState(s => {
                            s.edit = false;
                            s.isValid = false;
                        });
                    }else{
                        errorNotification(`Erreur du serveur (UNKNOWN)`);
                    }
                }else{
                    if(res.data.errors){
                        errorNotification(`Erreur du serveur (UNKNOWN)`);
                    }else{
                        errorNotification(`Erreur du serveur (${res.data.status_code})`);
                    }
                }
            }else{
                errorNotification(`Erreur du serveur (HTTP : ${res.data.http_code})`);
            }
            this.props.setLoader({ loading : false });
        });

        this.props.setLoader({ loading : true, mess : "Enregistrement en cours..." });
    }

    onCancel(e){
        e.preventDefault();
        this.setState({ 
            edit : false,
            nbRegistrantsMax : this.getEvent().nbRegistrantsMax
        });
    }
    
    onChange(prop, value, e){
        e.preventDefault();
        let state = this.getDeepState();
        state.isValid = true;

        switch (prop) {
            case "nbRegistrantsMax":
                if(!isNaN(value)){
                    let nb = parseInt(value);
                    if(nb >= 0){
                        state.nbRegistrantsMax = nb;
                    }else if(isEmpty(e.target.value)){
                        state.nbRegistrantsMax = 0;
                    }
                    state.isValid = true;
                }
                break;
            default:
                break;
        }

        if(state.isValid){
            const event = this.getEvent();
            state.isValid = (
                state.nbRegistrantsMax !== event.nbRegistrantsMax
            )
        }

        this.setState(state);
    }

    render() {
        return (
            <React.Fragment>
                <Row>
                    <div className="col-7">
                        <p>Modifiez ici les paramètres relatifs aux inscriptions à cet événement.</p>
                    </div>
                    <div className="col-5 btn-container-inline-right wrap">
                        {this.state.edit ? 
                            <Button size="sm" variant="secondary" onClick={this.onCancel}>
                                <i className="mdi mdi-close"/>
                                Annuler
                            </Button> 
                        : null }
                        <Button size="sm" variant="primary" onClick={this.launchSaveEdit} disabled={!this.state.isValid && this.state.edit}>
                            <i className={this.state.edit ? "mdi mdi-content-save" : "mdi mdi-pencil"} />
                            {this.state.edit ? "Enregistrer" : "Modifier"}
                        </Button>
                    </div>
                </Row>
                <Form onSubmit={(e) => e.preventDefault()}>
                    <Row>
                        <div className="col-6">
                            <Form.Group className="form-group">
                                <Form.Label>Nombres d'inscrits maximum</Form.Label>
                                <InputGroup>
                                    <Form.Control type="text" className="text-right" readOnly={!this.state.edit} value={this.state.nbRegistrantsMax} onChange={(e) => this.onChange("nbRegistrantsMax", e.target.value, e)}/>
                                    <InputGroup.Text>inscrits maximum</InputGroup.Text>
                                </InputGroup>
                            </Form.Group>
                        </div>
                    </Row>
                </Form>
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state) => ({ sejours : state.sejours });

export default connect(mapStateToProps, null)(ConfigurationTab);