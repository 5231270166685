import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import LoaderBox from '../../../shared/loaders/loader.box';

const defaultDescriptionImage = process.env.REACT_APP_RESSOURCES_URL + '/images/placeholders/placeholder-image-picker.jpeg';

class SejourCard extends Component{
    constructor(props) {
        super(props);
        this.onSelectChange = this.onSelectChange.bind(this);
    }
    getEnable(){
        if(this.props.sejour.enabled){
            return (
                <p className="text-muted text-center m-0"><i className="mdi mdi-check-circle text-success" aria-hidden="true" /> Actif </p>
            )
        }else{
            return (
                <p className="text-muted text-center m-0"><i className="mdi mdi-block-helper text-danger" aria-hidden="true" /> Inactif </p>
            )
        }
    }

    onSelectChange(e){
        if(this.props.onSelectChange){
            e.persist();
            this.props.onSelectChange({ e, SID : this.props.sejour.SID });
        }
    }

    getEvents(){
        const nbEnvent = this.props.sejour.events ? this.props.sejour.events.length : 0;
        return (
            <p className="text-muted text-center m-0"><i className="mdi mdi-calendar-check text-warning" aria-hidden="true" /> { nbEnvent > 0 ? nbEnvent + (nbEnvent > 1 ? " événements" : " événement") : "Pas d'événements"} </p>
        )
    }

    getInscrits() {
        const nbEnvent = this.props.sejour.events ? this.props.sejour.events.length : 0;
        return (
            <p className="text-muted text-center m-0"><i className="mdi mdi-account-check text-info" aria-hidden="true" /> { nbEnvent > 0 ? nbEnvent + (nbEnvent > 1 ? " inscrits" : " inscrit") : "Pas d'inscrits"} </p>
        )
    }
    render(){
        return (
            <div className="pl-0 col-xl-3 col-lg-3 col-md-4 col-sm-6 grid-margin stretch-card">
                <div className={`card sejour-card selectable${this.props.selected ? " selected" : ""}`}>
                    <div className={`card-selector${this.props.loader.show ? " hidden" : ""}`}>
                        <div className="form-check">
                            <label className="form-check-label text-muted">
                                <input type="checkbox" className="form-check-input" checked={this.props.selected} onChange={this.onSelectChange}/>
                                <i className="input-helper"></i>
                            </label>
                        </div>
                    </div>
                    <LoaderBox { ...this.props.loader }>
                        <div className="card-body">
                            <h4 className="card-title">{this.props.sejour.name ? this.props.sejour.name : "Séjour 1"}</h4>
                            <div className="sejour-image">
                                <img src={this.props.sejour.descriptionImage ? this.props.sejour.descriptionImage : defaultDescriptionImage} />
                            </div>
                            <div className="sejour-infos">
                                { this.getEnable() }
                                { this.getEvents() }
                                { /*this.getInscrits()*/ }
                            </div>
                            <div className="btn-container-inline-center m-0 wrap">
                                <Link to={`/sejours/models/${this.props.sejour.SID}`} className="btn btn-primary"><i className="mdi mdi-pencil" aria-hidden="true" />Modifier</Link>
                                <Link to={`/sejours/models/${this.props.sejour.SID}/events`} className="btn btn-info"><i className="mdi mdi-calendar" aria-hidden="true" />Evénements</Link>
                            </div>
                        </div>
                    </LoaderBox>
                </div>
            </div>
        )
    }

}

export default SejourCard;
