import React, { Component } from "react";
import { Button } from "react-bootstrap";
import { connect } from "react-redux";
import EventCard from "../../../../../../components/pages/sejours/events/card";
import { lengthObject } from "../../../../../../functions/common";
import { getDeepState, setDeepState } from "../../../../../../functions/react.utilities";
import AddModal from "./add-modal";
import DeleteModal from "./delete-modal";

class EventsTab extends Component{
    constructor(props) {
        super(props);

        this.setDeepState = setDeepState(this).bind(this);
        this.getDeepState = getDeepState(this).bind(this);
        this.setState = this.setState.bind(this);

        this.state = {
            loaders : {},
            selection : [],
            modals : {
                add : false,
                delete : false
            },
            forms : {
                add : {
                    isValid : false,
                    errors : {

                    }
                },
                delete : {
                    errors : {

                    }
                }
            }
        }

        this.onSelectCard = this.onSelectCard.bind(this);
        this.toggleSelectAll = this.toggleSelectAll.bind(this);
        this.launchCreateEvent = this.launchCreateEvent.bind(this);
        this.launchDeleteEvents = this.launchDeleteEvents.bind(this);

    }

    componentDidMount(){
        this.loadersInit();
    }
    componentDidUpdate(){
        this.loadersInit();
    }
    loadersInit(events = false){
        events = events || this.getModelEventsFromStore();
        const state = this.getDeepState();
        let changed = false;
        Object.keys(events).forEach((EID) => {
            if(!(EID in state.loaders)){
                state.loaders[EID] = {
                    show : false,
                    mess : ""
                }
                changed = true;
            }
        });
        if(changed){
            this.setState(state);
        }
    }

    getModelEventsFromStore(){
        const events = {};
        Object.keys(this.props.sejours.events).forEach((EID) => {
            if(this.props.sejours.models[this.props.SID].events.indexOf(EID) >= 0){
                events[EID] = this.props.sejours.events[EID];
            }
        });
        return events;
    }

    getModelFormStore(){
        return this.props.sejours.models[this.props.SID];
    }

    launchCreateEvent(){
        this.setDeepState(s => s.modals.add = true);
    }
    
    launchDeleteEvents(){
        this.setDeepState(s => s.modals.delete = true);
    }

    onSelectCard({ e, EID }){
        this.setDeepState((s) => {
            if(s.selection.includes(EID)){
                s.selection.splice(s.selection.indexOf(EID), 1);
            }else{
                s.selection.push(EID);
            }
        });
    }

    toggleSelectAll(){
        if(this.state.selection.length === this.getModelFormStore().events.length){
            this.setState({ selection : [] })
        }else{
            this.setState({ selection : [...this.getModelFormStore().events] });
        }
    }

    getHeader(){
        const nbEvents = Object.keys(this.getModelEventsFromStore()).length;
        return(
            <div className="btn-container-inline-left wrap">
                <Button size="sm" onClick={this.launchCreateEvent}><i className="mdi mdi-plus"/>Ajouter</Button>
                <Button size="sm" variant="danger" disabled={!(this.state.selection.length > 0)} onClick={this.launchDeleteEvents}><i className="mdi mdi-delete-forever"/>Supprimer</Button>
                <Button size="sm" variant="secondary" disabled={!(nbEvents > 0)} onClick={this.toggleSelectAll}><i className="mdi mdi-checkbox-multiple-marked-outline"/>Tout sélectionner</Button>
            </div>
        )
    }

    getCards(){
        const events = this.getModelEventsFromStore();
        if(lengthObject(events) > 0){
            return Object.entries(events).map(([EID, event], i) => {
                const loader = this.state.loaders[EID] || { show : false, mess : "" }
                return (
                    <EventCard selected={(this.state.selection.indexOf(EID) >= 0)} event={event} loader={loader} onSelectChange={this.onSelectCard} key={i} cardSecond/>
                )
            });
        }else{
           return (<p className="col-12 text-muted text-center">Aucun événement</p>)
        }
    }
    render(){
        return (
            <React.Fragment>
                { this.getHeader() }
                <div className="row ml-0 mr-0">
                    { this.getCards() }
                </div>
                <DeleteModal 
                    show={this.state.modals.delete}
                    parentState={this.state}
                    parentSetState={this.setState}
                    parentSetDeepState={this.setDeepState}
                    parentGetDeepState={this.getDeepState}
                />
                <AddModal 
                    show={this.state.modals.add || false}
                    SID={this.props.SID}
                    parentState={this.state}
                    parentSetState={this.setState}
                    parentSetDeepState={this.setDeepState}
                    parentGetDeepState={this.getDeepState}
                />
            </React.Fragment>
        )
    }
    
}

const mapStateToProps = (state) => ({ sejours : state.sejours });

export default connect(mapStateToProps, null)(EventsTab);