//Commons codes
export const DEFAULT_CONST = "DEFAULT";
export const ALL_CONST = "ALL";
export const DELETE = "DELETE";
export const EMPTY = "EMPTY";

export const ALREADY_EMAIL = "ALREADY_EMAIL";
export const EMAIL_SYNTAXE = "EMAIL_SYNTAXE";
export const ALREADY_PHONE = "ALREADY_PHONE";
export const PHONE_SYNTAXE = "PHONE_SYNTAXE";
export const UNKNOWN_ERROR = "UNKNOWN_ERROR";

export const NO_EMAIL = "NO_EMAIL";
export const NO_EMAILS = "NO_EMAILS";
export const NO_PASSWORD = "NO_PASSWORD";
export const NO_ACCOUNT = "NO_ACCOUNT";
export const NO_FILE = "NO_FILE";
export const NO_DATA = "NO_DATA";
export const INVALID_DATA = "INVALID_DATA";

// Account types
export const ADMIN = "admin";
export const STANDARD = "standard";

//Services names
export const RESET_PASSWORD  = "RESET_PASSWORD";

//Get users list
export const NO_FILTERS = "NO_FILTERS";
export const FORBIDDEN_FILTERS = "FORBIDDEN_FILTERS";

//Users gestion
export const INVALID_USER_TYPE = "INVALID_USER_TYPE";
export const NO_USER_TYPE = "NO_USER_TYPE";
export const NO_UID = "NO_UID";

//Get token with code
export const NO_CONFIRMATION_CODE = "NO_CONFIRMATION_CODE";
export const ERR_SENT_CODE = "ERR_SENT_CODE";

//Files imports
export const UNAUTHORIZED_FILE_TYPE = "UNAUTHORIZED_FILE_TYPE";
export const INCORRECT_FILE_SIZE = "INCORRECT_FILE_SIZE";
export const FILE_MOVING_ERR = "FILE_MOVING_ERR";

//Tokens codes
export const NO_VALIDATION_TOKEN = "NO_VALIDATION_TOKEN";
export const EXPIRED_VALIDATION_TOKEN = "EXPIRED_VALIDATION_TOKEN";
export const INVALID_VALIDATION_TOKEN = "INVALID_VALIDATION_TOKEN";
export const INEXIST_VALIDATION_TOKEN = "INEXIST_VALIDATION_TOKEN";


