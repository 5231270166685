import React, { Component } from 'react';
import {
    Badge,
    Button,
    ButtonGroup,
    Form,
    Input,
    InputGroup,
    Modal,
    Row,
    ToggleButton,
} from 'react-bootstrap';
import * as moment from 'moment';
import LoaderBox from '../../../../shared/loaders/loader.box';
import { isEmpty, lengthObject } from '../../../../../functions/common';
import {
    getDeepState,
    setDeepState,
} from '../../../../../functions/react.utilities';
import { DateTimePicker } from '@material-ui/pickers';
import { MODEL_RELATION_TYPE } from '../../../../../constants/api/sejours';
import { connect } from 'react-redux';
import PricesSelector from '../../prices/PricesSelector';
import PricesTagsContainer from '../../prices/PricesTagsContainer';
import { apiRequest } from '../../../../../functions/api';
import { pushNotification } from '../../../../../functions/notifications';
import validator from 'validator';
import { updateUsers__action } from '../../../../../actions/users.actions';

class AddEventModal extends Component {
    constructor(props) {
        super(props);
        this.state = this.getInitState();

        this.getDeepState = getDeepState(this);
        this.setDeepState = setDeepState(this);

        this.cancel = this.cancel.bind(this);
        this.launchValid = this.launchValid.bind(this);
        this.addPrice = this.addPrice.bind(this);
        this.removePrice = this.removePrice.bind(this);
        this.onVisibleChange = this.onVisibleChange.bind(this);
        this.onCarPoolingLinkChange = this.onCarPoolingLinkChange.bind(this);
        this.onNbRegistrantsMaxChange =
            this.onNbRegistrantsMaxChange.bind(this);
        this.onGuideChange = this.onGuideChange.bind(this);

        this.requestControllers = [];
        this.selectPriceRef = React.createRef();
    }

    static modes = {
        edit: 'edit',
        add: 'add',
    };

    getInitState() {
        const newState = {
            startDate: moment().add(3, 'week'),
            endDate: moment().add(4, 'week'),
            ticketingStartDate: moment(),
            ticketingEndDate: moment().add(3, 'week'),
            model: this.props.definedModel
                ? this.props.SID
                : lengthObject(this.props.sejours.models) > 0
                ? Object.keys(this.props.sejours.models)[0]
                : '',
            prices: [],
            guides: [],
            guide: '0',
            visible: false,
            nbRegistrantsMax: 0,
            errors: {
                startDate: '',
                endDate: '',
                ticketingStartDate: '',
                ticketingEndDate: '',
                model: '',
                guide: '',
                prices: '',
                carPoolingLink: '',
            },
            loading: false,
            loadingMess: '',
            isValid: true,
        };

        return newState;
    }

    getErrors() {
        const propsError = this.props.errors ? this.props.errors : {};
        return { ...this.state.errors, ...propsError };
    }

    componentDidUpdate(prevProps) {
        if (this.props.show && this.props.show !== prevProps.show) {
            this.setState(this.getInitState());
            this.getGuides();
        }
        if (
            lengthObject(this.props.sejours.models) > 0 &&
            !Object.keys(this.props.sejours.models).includes(this.state.model)
        ) {
            this.setDeepState((s) => {
                s.model = this.props.definedModel
                    ? this.props.SID
                    : Object.keys(this.props.sejours.models)[0];
                s.prices = this.deleteForbiddenPrices(s.prices, s.model);
                s.visible = s.prices.length > 0 ? s.visible : false;
            });
        }
    }

    getGuides() {
        this.pendingRequest = apiRequest({
            url: '/sejours.guides/get',
        }).then((res) => {
            let state = { ...this.state };
            if (res) {
                if (
                    res.data.http_code === 200 &&
                    res.data.status === 'success'
                ) {
                    if (res.data.data.guides) {
                        this.props.dispatch(
                            updateUsers__action(res.data.data.guides)
                        );
                    }
                    state.guide = '0';
                } else {
                    pushNotification({
                        type: 'error',
                        title: 'Erreur',
                        content: (
                            <p>
                                Une erreur est survenue lors de la récupération
                                des accompagnateurs
                            </p>
                        ),
                        icon: 'mdi mdi-close',
                    });
                    this.cancel();
                }
            }
            state.loading = false;
            state.loadingMess = false;
            this.setState(state);
        });
    }

    onDateChange(prop, d) {
        const state = this.getDeepState();
        if (d) {
            state[prop] = d;
            if (d._isValid) {
                state.errors = {
                    startDate: '',
                    endDate: '',
                    ticketingStartDate: '',
                    ticketingEndDate: '',
                };
                state.isValid = true;
                const checkDates = () => {
                    if (state.startDate.isSameOrAfter(state.endDate)) {
                        state.errors.endDate =
                            'Date de fin avant date de début';
                        state.isValid = false;
                    }
                };
                const checkTicketingDates = () => {
                    if (
                        state.ticketingStartDate.isSameOrAfter(
                            state.ticketingEndDate
                        )
                    ) {
                        state.errors.ticketingEndDate =
                            "Date de clôture avant date d'ouverture";
                        state.isValid = false;
                    } else if (
                        state.ticketingEndDate.isAfter(state.startDate)
                    ) {
                        state.errors.ticketingEndDate =
                            'Date de clôture billetrie après date de début';
                        state.isValid = false;
                    }
                };
                checkDates();
                checkTicketingDates();
            } else {
                state.errors[prop] = 'Date invalide';
                state.isValid = false;
            }
            this.setState(state);
        }
        return state;
    }

    onVisibleChange(e) {
        e.preventDefault();
        if (e.target.checked) {
            this.setDeepState(
                (s) => (s.visible = this.state.prices.length > 0)
            );
        } else {
            this.setDeepState((s) => (s.visible = false));
        }
    }

    onNbRegistrantsMaxChange(e) {
        e.preventDefault();
        if (!isNaN(e.target.value)) {
            let nb = parseInt(e.target.value);
            if (nb >= 0) {
                this.setDeepState((s) => (s.nbRegistrantsMax = nb));
            } else if (isEmpty(e.target.value)) {
                this.setDeepState((s) => (s.nbRegistrantsMax = 0));
            }
        }
    }

    onModelChange(e) {
        if (
            e.target.value in this.props.sejours.models &&
            !this.props.definedModel
        ) {
            this.setDeepState((s) => {
                s.model = e.target.value;
                s.prices = this.deleteForbiddenPrices(s.prices, s.model);
                s.visible = s.prices.length > 0 ? s.visible : false;
            });
        }
    }

    onGuideChange(e) {
        if (e.target.value === '0') {
            this.setState({ guide: e.target.value });
        } else if (e.target.value in this.props.users) {
            if (this.props.users[e.target.value].guide)
                this.setState({ guide: e.target.value });
        }
    }

    onCarPoolingLinkChange(e) {
      e.preventDefault();
      if (validator.isURL(e.target.value, {requireProtocol: true, protocols: ['https', 'http']})
      || isEmpty(e.target.value)) {
        this.setDeepState((s) => {
          s.carPoolingLink = e.target.value
          s.errors.carPoolingLink = '';
          s.isValid = true;
        });
      } else {
          this.setDeepState((s) => {
            s.carPoolingLink = e.target.value
            s.errors.carPoolingLink = 'Format du lien invalide';
            s.isValid = false;
          });
      }
    }

    cancel() {
        this.requestControllers.forEach((s) => {
            s.cancel();
        });
        this.requestControllers = [];
        if (this.props.onCancel) {
            this.props.onCancel();
        }
    }

    launchValid(e) {
        if (e) e.preventDefault();
        const state = {
            ...this.onDateChange('startDate', this.state.startDate),
        };
        if (state.isValid) {
            if (this.props.onValid) {
                this.props.onValid({
                    startDate: this.state.startDate,
                    endDate: this.state.endDate,
                    ticketingStartDate: this.state.ticketingStartDate,
                    ticketingEndDate: this.state.ticketingEndDate,
                    nbRegistrantsMax: parseInt(this.state.nbRegistrantsMax)
                        ? parseInt(this.state.nbRegistrantsMax)
                        : 0,
                    prices: this.state.prices,
                    model: this.state.model,
                    guide: this.state.guide,
                    visible: this.state.visible,
                    carPoolingLink: this.state.carPoolingLink
                });
            }
        }
    }

    getAllowedPrices(SID) {
        let prices = { ...this.props.sejours.prices };
        Object.entries(prices).forEach(([PID, price]) => {
            if (price.sejourRelationType === MODEL_RELATION_TYPE) {
                if (!price.sejourRelationIDS.includes(SID)) {
                    delete prices[PID];
                }
            }
        });
        return prices;
    }

    deleteForbiddenPrices(p, SID) {
        const allowedPrices = this.getAllowedPrices(SID);
        let prices = [];
        p.forEach((PID) => {
            if (PID in allowedPrices) {
                prices.push(PID);
            }
        });
        return prices;
    }

    addPrice(PID) {
        this.setDeepState((s) => {
            if (!s.prices.includes(PID)) {
                s.prices.push(PID);
            }
        });
    }

    removePrice(PID) {
        this.setDeepState((s) => {
            if (s.prices.includes(PID)) {
                s.prices.splice(s.prices.indexOf(PID), 1);
            }
            s.visible = s.prices.length > 0 ? s.visible : false;
        });
    }

    getPricesAvailable() {
        let prices = {};
        Object.entries(this.getAllowedPrices(this.state.model)).forEach(
            ([PID, price]) => {
                if (!this.state.prices.includes(PID)) {
                    prices[PID] = price;
                }
            }
        );
        return prices;
    }

    getPricesSelected() {
        let prices = {};
        this.state.prices.forEach((PID) => {
            if (PID in this.props.sejours.prices) {
                prices[PID] = this.props.sejours.prices[PID];
            }
        });

        return prices;
    }

    pricesTagsRender() {
        if (this.state.prices.length > 0) {
            return this.state.prices.map((PID) => {
                const price = this.props.sejours.prices[PID];
                if (price) {
                    return (
                        <Badge className='price-tag' pill key={PID}>
                            <Badge
                                className={`price-relation-type-badge ${
                                    price.sejourRelationType ===
                                    MODEL_RELATION_TYPE
                                        ? 'relation-model'
                                        : 'relation-global'
                                }`}
                                pill
                            >
                                {price.sejourRelationType ===
                                MODEL_RELATION_TYPE
                                    ? 'M'
                                    : 'G'}
                            </Badge>
                            {price.label}&nbsp;-&nbsp;
                            {parseFloat(price.amount / 100).toFixed(2) > 0
                                ? `${parseFloat(price.amount / 100).toFixed(
                                      2
                                  )}€`
                                : 'Gratuit'}
                            <button
                                className='btn-close'
                                aria-label='Retirer de la liste'
                                title='Retirer le tarif'
                                onClick={(e) => this.removePrice(PID, e)}
                            />
                        </Badge>
                    );
                } else {
                    return null;
                }
            });
        } else {
            return (
                <p className='text-center text-muted m-2'>Aucun tarif ajouté</p>
            );
        }
    }

    renderGuideSelector() {
        return (
            <InputGroup>
                <Form.Select
                    className={`form-control${
                        this.props.classNameSelect
                            ? ` ${this.props.classNameSelect}`
                            : ''
                    }`}
                    onChange={this.onGuideChange}
                    value={this.state.guide}
                >
                    <option key={0} value={0}>
                        Non défini
                    </option>
                    {Object.values(this.props.users).map((v, i) =>
                        v.guide ? (
                            <option key={i + 1} value={v.UID}>
                                {v.firstName} {v.name}
                            </option>
                        ) : null
                    )}
                </Form.Select>
            </InputGroup>
        );
    }

    renderCarPoolingLinkInput() {
        return (
            <InputGroup>
                <Form.Control
                    type='text'
                    placeholder='Lien non défini'
                    value={this.state.carPoolingLink}
                    onChange={this.onCarPoolingLinkChange}
                />
                <Form.Control.Feedback type='invalid'>
                  {this.state.errors.carPoolingLink}
                </Form.Control.Feedback>
            </InputGroup>
        );
    }

    render() {
        const pricesAvailable = this.getPricesAvailable();
        return (
            <Modal show={this.props.show} onHide={this.cancel}>
                <Modal.Header
                    closeButton={this.props.closeButton ? true : false}
                >
                    Ajouter un événement
                </Modal.Header>
                <LoaderBox
                    show={this.props.loading || this.state.loading}
                    mess={
                        this.state.loadingMess
                            ? this.state.loadingMess
                            : this.props.loadingMess
                            ? this.props.loadingMess
                            : ''
                    }
                >
                    <Modal.Body>
                        <Form onSubmit={this.launchValid}>
                            {!this.props.definedModel ? (
                                <div className='mb-4'>
                                    <h4 className='mb-2'>Modèle</h4>
                                    <Form.Group className='form-group'>
                                        <Form.Label>
                                            Modèle auquel ajouter l'événement
                                        </Form.Label>
                                        <Form.Select
                                            className='form-control'
                                            onChange={(e) =>
                                                this.onModelChange(e)
                                            }
                                            value={this.state.model}
                                        >
                                            {Object.entries(
                                                this.props.sejours.models
                                            ).map(([SID, model]) => {
                                                return (
                                                    <option
                                                        value={SID}
                                                        key={SID}
                                                    >
                                                        {model.name}
                                                    </option>
                                                );
                                            })}
                                        </Form.Select>
                                    </Form.Group>
                                </div>
                            ) : null}
                            <div>
                                <h4 className='mb-2'>Dates</h4>
                                <Row>
                                    <Form.Group className='col-6 form-group'>
                                        <Form.Label>Date de début</Form.Label>
                                        <DateTimePicker
                                            ampm={false}
                                            format='DD MMMM yyyy [à] HH[h]mm'
                                            className='form-control'
                                            inputVariant='outlined'
                                            cancelLabel='Annuler'
                                            okLabel='Valider'
                                            onChange={(d) =>
                                                this.onDateChange(
                                                    'startDate',
                                                    d
                                                )
                                            }
                                            value={this.state.startDate}
                                            helperText={null}
                                        />
                                        <Form.Control.Feedback type='invalid'>
                                            {this.state.errors.startDate}
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                    <Form.Group className='col-6 form-group'>
                                        <Form.Label>Date de fin</Form.Label>
                                        <DateTimePicker
                                            ampm={false}
                                            format='DD MMMM yyyy [à] HH[h]mm'
                                            className='form-control'
                                            inputVariant='outlined'
                                            cancelLabel='Annuler'
                                            okLabel='Valider'
                                            onChange={(d) =>
                                                this.onDateChange('endDate', d)
                                            }
                                            helperText={null}
                                            value={this.state.endDate}
                                            minDate={this.state.startDate
                                                .clone()
                                                .add(1, 'day')}
                                        />
                                        <Form.Control.Feedback type='invalid'>
                                            {this.state.errors.endDate}
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                </Row>
                                <Row>
                                    <Form.Group className='col-6 form-group'>
                                        <Form.Label>
                                            Date d&apos;ouverture billeterie
                                        </Form.Label>
                                        <DateTimePicker
                                            ampm={false}
                                            format='DD MMMM yyyy [à] HH[h]mm'
                                            className='form-control'
                                            inputVariant='outlined'
                                            cancelLabel='Annuler'
                                            okLabel='Valider'
                                            onChange={(d) =>
                                                this.onDateChange(
                                                    'ticketingStartDate',
                                                    d
                                                )
                                            }
                                            helperText={null}
                                            value={
                                                this.state.ticketingStartDate
                                            }
                                            maxDate={this.state.startDate
                                                .clone()
                                                .subtract(1, 'day')}
                                        />
                                        <Form.Control.Feedback type='invalid'>
                                            {
                                                this.state.errors
                                                    .ticketingStartDate
                                            }
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                    <Form.Group className='col-6 form-group'>
                                        <Form.Label>
                                            Date de clôture billeterie
                                        </Form.Label>
                                        <DateTimePicker
                                            ampm={false}
                                            format='DD MMMM yyyy [à] HH[h]mm'
                                            className='form-control'
                                            inputVariant='outlined'
                                            cancelLabel='Annuler'
                                            okLabel='Valider'
                                            onChange={(d) =>
                                                this.onDateChange(
                                                    'ticketingEndDate',
                                                    d
                                                )
                                            }
                                            helperText={null}
                                            value={this.state.ticketingEndDate}
                                            minDate={this.state.ticketingStartDate
                                                .clone()
                                                .add(1, 'day')}
                                            maxDate={this.state.startDate}
                                        />

                                        <Form.Control.Feedback type='invalid'>
                                            {this.state.errors.ticketingEndDate}
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                </Row>
                            </div>
                            <div className='mt-4'>
                                <h4 className='mb-2'>Accompagnateur</h4>
                                {this.renderGuideSelector()}
                            </div>
                            <div className='mt-4'>
                                <h4 className='mb-2'>Covoiturage</h4>
                                <p className='text-primary' style={{ fontSize: 12.5, marginBottom: 5 }}>
                                    <i
                                        className='mdi mdi-information'
                                        style={{ marginRight: 5 }}
                                    />
                                    Si le lien n'est pas défini, le
                                    bouton&nbsp;
                                    <b>Covoiturer</b> ne sera pas affiché sur le
                                    site.
                                </p>
                                {this.renderCarPoolingLinkInput()}
                            </div>
                            <div className='mt-4'>
                                <h4 className='mb-2'>Inscriptions</h4>
                                <InputGroup>
                                    <Form.Control
                                        type='text'
                                        className='text-right'
                                        value={this.state.nbRegistrantsMax}
                                        onChange={this.onNbRegistrantsMaxChange}
                                    />
                                    <InputGroup.Text>
                                        inscrits maximum
                                    </InputGroup.Text>
                                </InputGroup>
                            </div>
                            <div className='mt-4'>
                                <h4 className='mb-2'>Tarifs</h4>
                                <PricesSelector
                                    prices={pricesAvailable}
                                    onAdd={this.addPrice}
                                />
                                <div className='mt-2'>
                                    <PricesTagsContainer
                                        prices={this.getPricesSelected()}
                                        onRemove={this.removePrice}
                                    />
                                </div>
                            </div>
                            <div className='mt-4'>
                                <div className='d-flex align-items-center justify-content-center'>
                                    <div className='form-check'>
                                        <label className='form-check-label text-muted'>
                                            <input
                                                type='checkbox'
                                                className='form-check-input'
                                                checked={this.state.visible}
                                                onChange={this.onVisibleChange}
                                            />
                                            <i className='input-helper'></i>
                                            Afficher l&apos;événement
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </Form>
                    </Modal.Body>
                </LoaderBox>
                <Modal.Footer>
                    <Button
                        variant='secondary'
                        onClick={this.cancel}
                        disabled={this.props.loading}
                    >
                        Annuler
                    </Button>
                    <Button
                        disabled={
                            !this.state.isValid ||
                            this.props.loading ||
                            this.state.loading
                        }
                        onClick={this.launchValid}
                    >
                        Créer
                    </Button>
                </Modal.Footer>
            </Modal>
        );
    }
}

const mapStateToProps = (state) => ({
    sejours: state.sejours,
    users: state.users,
});
export default connect(mapStateToProps, null)(AddEventModal);
