import React, { Component } from "react";
import { apiRequest } from "../../../functions/api";
import { connect } from 'react-redux';
import { Button, Form, Modal } from "react-bootstrap";
import { isEmpty } from '../../../functions/common';
import LoaderBox from "../loaders/loader.box";
import { CForm } from "../../custom-boostrap/CForm";

class PasswordConfirmModalComponent extends Component{
    constructor(props) {
        super(props);
        this.state = {
            show : props.show,
            isValid : false,
            cancelDisabled : false,
            feedBack : null,
            feedBackValidity : "invalid",
            inputDisabled : false,
            password : null,
            loading : false,
            loadingMessage : ""
        }
    }

    componentDidUpdate(){
        if(this.state.show != this.props.show){
            this.setState({
                show : this.props.show,
                isValid : false,
                cancelDisabled : false,
                feedBack : null,
                feedBackValidity : "invalid",
                inputDisabled : false,
                password : null
            });
        }
    }

    defaultOnValid(){}
    onValid(data){
        this.setState({
            loading : false
        });
        this.onValidCallBack(data);
    }

    defaultOnDecline(){}
    onDecline(){
        if(!this.state.cancelDisabled) this.onDeclineCallBack();
    }

    onClick(){
        if(this.state.isValid){
            this.verifyPassword();
        }
    }

    verifyPassword(){
        this.setState({ 
            isValid : false, 
            loading : true,
            loadingMess : "Vérification",
            inputDisabled : true, 
            cancelDisabled : true 
        });
        const onErr = () => {
            this.setState({ 
                isValid : true, 
                loading: false,
                feedBack : "Mot de passe incorrect",
                feedBackValidity : "invalid", 
                inputDisabled : false, 
                cancelDisabled : false 
            })
        }
        apiRequest({
            url : '/secure.token.password/post',
            data : {
                password : this.state.password
            }
        })
        .then((res) => {
            if(res.data.http_code == 200){
                if(res.data.status == "success" && res.data.data.token){
                    this.onValid(res.data.data);
                }else{
                    onErr();
                }
            }else{
                onErr();
            }
        })
        .catch((res) => {
            this.setState({ 
                isValid : true, 
                feedBack : "Erreur réseau",
                feedBackValidity : "invalid", 
                inputDisabled : false, 
                cancelDisabled : false 
            })
        })
    }

    render(){
        this.onValidCallBack = this.props.onValid ? this.props.onValid : this.defaultOnValid;
        this.onDeclineCallBack = this.props.onDecline ? this.props.onDecline : this.defaultOnDecline;
        return (
            <Modal show={this.props.show} onHide={() => {}}>
                <Modal.Header>Entrez votre mot de passe pour continuer</Modal.Header>
                <LoaderBox show={this.state.loading} mess={this.state.loadingMess}>
                    <Modal.Body>
                        <Form.Group className="form-group">
                            <Form.Label>Mot de passe</Form.Label>
                            <CForm.Control.Password disabled={this.state.inputDisabled} autoComplete="false" onChange={(e) => {this.setState({ password : e.target.value, isValid : !isEmpty(e.target.value), feedBack : false })}}></CForm.Control.Password>
                            {this.state.feedBack ? <Form.Control.Feedback type={`${this.state.feedBackValidity}`}>{this.state.feedBack}</Form.Control.Feedback> : null}
                        </Form.Group>
                    </Modal.Body>
                </LoaderBox>
                <Modal.Footer>
                    <Button disabled={!this.state.isValid} onClick={this.onClick.bind(this)}>Valider</Button>
                    <Button variant="secondary" onClick={this.onDecline.bind(this)} disabled={this.state.cancelDisabled}>Annuler</Button>
                </Modal.Footer>
            </Modal>
        );
    }
}

export default PasswordConfirmModalComponent