import React, { Component } from 'react';
import { Form, Button } from 'react-bootstrap';
import { withRouter } from 'react-router-dom';
import { apiRequest } from '../../../functions/api';
import { isEmpty, isValidEmail } from '../../../functions/common';
import * as apiCodes from '../../../constants/api/index'
import { getURLParams } from '../../../functions/react.utilities';
import LoaderBox from '../../shared/loaders/loader.box';
import { CForm } from '../../custom-boostrap/CForm';
import { pushNotification } from '../../../functions/notifications';

class ResetPasswordForm extends Component{
    constructor(props) {
        super(props);
        let params = getURLParams(this.props.location, "email");
        this.state = {
         email : params.email ? params.email : "",
         step1 : {
            errType : "invalid",
            err : ""
         },
         step2 : {
            errType : "invalid",
            err : "",
            code : ""
         },
         step3 : {
            newPassword: "",
            passwordConfirm : "",
            errType : "invalid",
            err : "",
            isValid : false
         },
         loading : false,
         loadingMess : "",
         step : 1
        }
        this.step1 = {
            token : null,
            tokenTimer : null,
            codeLength : 0,
            lastEmailSent : null
        }
        this.step2 = {
            serviceToken : null,
            tokenTimer : null
        }
        this.step3 = {
            isValid : false
        }
    }
    getStateStep1(state){
        return { ...this.state.step1, ...state }
    }
    getStateStep2(state){
        return { ...this.state.step2, ...state }
    }
    getStateStep3(state){
        return { ...this.state.step3, ...state }
    }

    setTokenStep1(token, duration, codeLength, at){
        const tokenIsExpired = () => {

        }
        clearTimeout(this.step1.tokenTimer);
        this.step1.tokenTimer = setTimeout(tokenIsExpired, duration / 60 * 1000);
        this.step1.token = token;
        this.step1.codeLength = codeLength;
        this.step1.lastEmailSent = at;
    }
    setTokenStep2(token, duration){
        const tokenIsExpired = () => {

        }
        clearTimeout(this.step2.tokenTimer);
        this.step2.tokenTimer = setTimeout(tokenIsExpired, duration / 60 * 1000);
        this.step2.serviceToken = token;
    }

    prevStep(){
        this.setState({ step : 1 });
    }
    nextStep(){
        let step = ((this.state.step + 1) > 3) ?  3 : (this.state.step + 1);
        this.setState({ step });
    }
    getStep1(){
        const getCode = () => {
            if(this.step1.lastEmailSent !== this.state.email){
                if(!isEmpty(this.state.email)){
                    if(isValidEmail(this.state.email)){
                        this.setState({ loading : true, loadingMess:"Vérification de l'email..." });
                        apiRequest({
                            url : '/secure.token.e-code/get',
                            data : {
                                service : apiCodes.RESET_PASSWORD,
                                email : this.state.email
                            }
                        })
                        .then((res) => {
                            if(res.data.http_code === 200){
                                if(res.data.status === "success" && res.data.data.token){
                                    this.setTokenStep1(
                                        res.data.data.token, 
                                        res.data.data.duration, 
                                        res.data.data.codeLength,
                                        this.state.email
                                    );
                                    this.setState({ 
                                        step : 2,
                                        loading : false,
                                        step1 : this.getStateStep1({ err : "", errType : "invalid" }) ,
                                        step2 : this.getStateStep2({ code : "" }) 
                                    });
                                }else{
                                    switch(res.data.status_code){
                                        case apiCodes.EMAIL_SYNTAXE :
                                            this.setState({ loading: false, step1 : this.getStateStep1({ err : "Email invalide", errType : "invalid" }) });
                                            break;
                                        case apiCodes.ERR_SENT_CODE :
                                            this.setState({ loading: false, step1 : this.getStateStep1({ err : "Erreur lors de l'envoi du code vers votre email. Réessayez", errType : "invalid" }) });
                                            break;
                                        
                                        case apiCodes.NO_ACCOUNT :
                                            this.setState({ loading: false, step1 : this.getStateStep1({ err : "Aucun compte trouvé pour cet email", errType : "invalid" }) });
                                            break;

                                        default :
                                            this.setState({ loading: false, step1 : this.getStateStep1({ err : "Erreur interne. Réessayez", errType : "invalid" }) });
                                            break;
                                    }
                                }
                            }else{
                                
                            }
                        })
                    }else{
                        this.setState({ loading : false, step1 : this.getStateStep1({ err : "Email invalide", errType : "invalid" }) });
                    }
                }else{
                    this.setState({ loading : false, step1 : this.getStateStep1({ err : "Entrez un email", errType : "invalid" }) });
                }
            }else{
                this.nextStep();
            }
        }
        const setEmail = (e) => {
            if(e.target.value !== this.state.email){
                this.setState({ email : e.target.value });
            }
        }
        return (
            <>
                <h4>Problème de mot de passe ?</h4>
                <h6 className="font-weight-light">Entrez votre email pour pouvoir réinitialiser votre mot de passe.</h6>
                <Form className="pt-3">
                    <Form.Group className="form-group search-field">
                        <Form.Control type="email" size="lg" placeholder="Email" id="email" className="h-auto" value={this.state.email} onChange={setEmail.bind(this)}/>
                        <Form.Control.Feedback type={`${this.state.step1.errType}`}>{this.state.step1.err}</Form.Control.Feedback>
                    </Form.Group>
                    <div className="btn-container-inline-right">
                        <Button className="btn btn-primary" type="button" onClick={getCode.bind(this)} disabled={isEmpty(this.state.email)}><i className="mdi mdi-arrow-right" />Suivant</Button>
                    </div>
                </Form>
            </>
        )
    }

    getStep2(){
        const setCode = (e) => {
            this.setState({
                step2 : this.getStateStep2({ code : e.target.value })
            })
        }
        const verifyCode = () => {
            const prevStepWithMess = (mess) => {
                this.step1.lastEmailSent = null;
                this.setState({
                    step : 1,
                    loading: false,
                    step1 : this.getStateStep1({
                        err : mess,
                        errType : "invalid"
                    }),
                    step2 : this.getStateStep2({
                        err : "",
                        errType : "",
                        code : ""
                    })
                });
            }
            if(this.state.step2.code.length === this.step1.codeLength){
                this.setState({
                    step2 : this.getStateStep2({
                        err : "",
                        errType : "valid"
                    }),
                    loading : true,
                    loadingMess : "Vérification du code..."
                });
                apiRequest({
                    url : '/secure.token.e-code/post',
                    data : {
                        validationToken : this.step1.token,
                        code : this.state.step2.code,
                        service : apiCodes.RESET_PASSWORD,
                        email : this.state.email
                    }
                }).then((res) => {
                    if(res.data.http_code === 200){
                        if(res.data.status === "success"){
                            if(res.data.data.verified){
                                clearTimeout(this.step1.tokenTimer);
                                this.step1.token = null;
                                this.step1.codeLength = 0;
                                this.step1.lastEmailSent = null;
                                this.step1.tokenTimer = null;
                                this.setState({
                                    loading : false,
                                    step2 : this.getStateStep2({
                                        code : "",
                                        err : "",
                                        errType : ""
                                    }),
                                    step : 3
                                });
                                this.setTokenStep2(res.data.data.serviceToken, res.data.data.duration);
                            }else{
                                this.setState({
                                    loading : false,
                                    step2 : this.getStateStep2({
                                        err : "Le code que vous avez entré est incorrect",
                                        errType : "invalid"
                                    })
                                });
                            }
                        }else{
                            switch (res.data.status_code) {
                                case apiCodes.EXPIRED_VALIDATION_TOKEN:
                                    prevStepWithMess("Le code que vous avez reçu n'est plus valide, veuillez recommencer");
                                    break;

                                default:
                                    prevStepWithMess("Une erreur est survenue, veuillez recommencer");
                                    break;
                            }
                        }
                    }else{
                        this.setState({
                            loading : false,
                            step2 : this.getStateStep2({
                                err : "Une erreur est survenue ",
                                errType : "invalid"
                            })
                        });
                    }
                })
            }else{

            }
        }
        return (
            <>
                <h4>Petite vérification...</h4>
                <h6 className="font-weight-light">Afin de vérifier qu'il s'agit bien de vous, entrez le code que nous vous avons envoyé par mail.</h6>
                <Form className="pt-3">
                    <Form.Group className="form-group search-field">
                        <Form.Control type="text" size="lg" placeholder="Code de vérification" className="h-auto" maxLength={this.step1.codeLength} value={this.state.step2.code} onChange={setCode.bind(this)}/>
                        <Form.Control.Feedback type={`${this.state.step2.errType}`}>{this.state.step2.err}</Form.Control.Feedback>
                    </Form.Group>
                    <div className="btn-container-inline-right">
                        <Button className="btn btn-secondary" type="button" onClick={this.prevStep.bind(this)}><i className="mdi mdi-arrow-left" />Précédent</Button>
                        <Button className="btn btn-primary" type="button" disabled={this.state.step2.code.length < this.step1.codeLength} onClick={verifyCode.bind(this)}><i className="mdi mdi-arrow-right" />Suivant</Button>
                    </div>
                </Form>
            </>
        )
    }

    getStep3(){
        const onChange = (prop, e) => {
            let state = { ...this.state.step3, [prop] : e.target.value };
            let empty = (isEmpty(state.newPassword) || isEmpty(state.passwordConfirm));
            let different = state.newPassword !== state.passwordConfirm;
            state.isValid = !(empty || different);

            if(!empty && different) {
                state.errType = "invalid";
                state.err = "Les mots de passe ne correspondent pas";
            }else{
                state.errType = "";
                state.err= "";
            }
            this.step3.isValid = state.isValid;
            this.setState({ step3 : state });
        }
        const changePassword = () => {
            const prevStepWithMess = (mess) => {
                this.step1.lastEmailSent = null;
                this.setState({
                    step : 1,
                    loading : false,
                    step1 : this.getStateStep1({
                        err : mess,
                        errType : "invalid"
                    }),
                    step2 : this.getStateStep2({
                        err : "",
                        errType : "",
                        code : ""
                    }),
                    step3 : this.getStateStep3({ 
                        err : "", 
                        errType : "", 
                        newPassword : "", 
                        passwordConfirm : "", 
                        isValid : false 
                    })
                });
            }
            if(this.step3.isValid){
                this.setState({ 
                    loading : true,
                    loadingMess : "Modification du mot de passe"
                });
                apiRequest({
                    url : '/user.password.reset/put',
                    data : {
                        validationToken : this.step2.serviceToken,
                        email : this.state.email,
                        password : this.state.step3.newPassword
                    }
                }).then((res) => {
                    if(res.data.http_code === 200){
                        if(res.data.status === "success"){
                            pushNotification({
                                title : "Mot de passe modifié",
                                icon : "mdi mdi-key",
                                content : <p>Votre mot de passe à été modifé avec succès !</p>,
                                type : "success",
                                autoClose : 5000,
                                closeOnClick : true,
                                draggable : true,
                                pauseOnHover : true
                            });
                            this.props.history.push('/login');
                        }else{
                            switch(res.data.status_code){
                                case apiCodes.EXPIRED_VALIDATION_TOKEN:
                                    prevStepWithMess("Vous avez trop attendu avant de valider votre nouveau mot de passe. Réessayez.")
                                    break;
                                
                                default:
                                    prevStepWithMess("Désolé, une erreur est survenue. Réessayez.")
                                    break;
                            }
                        }
                    }else{
                        prevStepWithMess("Désolé, une erreur est survenue. Réessayez.")
                    }
                })
            }
        }
        return (
            <>
                <h4>Nouveau mot de passe !</h4>
                <h6 className="font-weight-light">Choisissez votre nouveau mot de passe.</h6>
                <Form className="pt-3">
                    <Form.Group className="form-group search-field">
                        <Form.Label>Nouveau mot de passe</Form.Label>
                        <CForm.Control.Password placeholder="Nouveau mot de passe" name="new-password" size="lg" className="h-auto" onChange={(e) => { onChange('newPassword', e) }} value={this.state.step3.newPassword}/>
                    </Form.Group>
                    <Form.Group className="form-group search-field">
                        <Form.Label>Confirmer le mot de passe</Form.Label>
                        <CForm.Control.Password placeholder="Confirmer le mot de passe" name="confirm-new-password" size="lg" className="h-auto" onChange={(e) => { onChange('passwordConfirm', e) }} value={this.state.step3.passwordConfirmation}/>
                        <Form.Control.Feedback type={`${this.state.step3.errType}`}>{this.state.step3.err}</Form.Control.Feedback>
                    </Form.Group>
                    <div className="btn-container-inline-right">
                        <Button className="btn btn-secondary" type="button" onClick={this.prevStep.bind(this)}><i className="mdi mdi-close" />Annuler</Button>
                        <Button className="btn btn-primary" type="button" disabled={!this.state.step3.isValid} onClick={changePassword.bind(this)}><i className="mdi mdi-check" />Valider</Button>
                    </div>
                </Form>
            </>
        )
    }

    currentStep(){
        if(this.state.step === 2){
            return this.getStep2();
        }else if(this.state.step === 3){
            return this.getStep3();
        }else{
            return this.getStep1();
        }
    }
    render(){
        return (
            <div className="d-flex align-items-center auth px-0">
                <div className="row w-100 mx-0">
                    <div className="col-lg-4 mx-auto">
                        <LoaderBox show={this.state.loading} mess={this.state.loadingMess} className="card">
                            <div className="card text-left py-5 px-4 px-sm-5">
                                <div className="brand-logo">
                                    <img src={`${process.env.REACT_APP_RESSOURCES_URL}/gesprit/gesprit-logo.png`} alt="Logo Ges'prit" />
                                </div>
                                {this.currentStep()}
                            </div>
                        </LoaderBox>
                    </div>
                </div>
            </div>
        );
    }
}

export default withRouter(ResetPasswordForm);