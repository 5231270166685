import React, { Component } from "react";
import { Button, ButtonGroup, Form, Input, InputGroup, Modal, Row, ToggleButton } from "react-bootstrap";
import LoaderBox from "../../../../shared/loaders/loader.box";
import { isEmpty } from "../../../../../functions/common";
import { getDeepState, setDeepState } from "../../../../../functions/react.utilities";

class EditAddPriceModal extends Component {
  constructor(props){
    super(props);
    this.state = this.getInitState();

    this.getDeepState = getDeepState(this);
    this.setDeepState = setDeepState(this);

    this.cancel = this.cancel.bind(this);
    this.launchValid = this.launchValid.bind(this);
  }

  static modes = {
    edit : "edit",
    add : "add"
  };

  priceModes = {
    fixed : {
      label : "Prix fixe", 
      id : "fixed" 
    },
    free : {
      label : "Gratuit",
      id : "free"
    }
  }

  getInitState(){
    const newState = {
      label : "",
      description : "",
      priceMode : this.priceModes.fixed.id,
      amount : "",
      errors : {
        label : "",
        amount : "",
        description : "",
        priceMode : ""
      },
      isValid : false
    }
    if(this.props.mode === EditAddPriceModal.modes.edit){
      if(this.props.data){
        if(this.props.data.label) newState.label = this.props.data.label;
        if(this.props.data.description) newState.description = this.props.data.description;
        if(this.props.data.amount) newState.amount = this.props.data.amount;

        if(isEmpty(newState.amount) || (!isNaN(newState.amount) && parseFloat(newState.amount) === 0)){
          newState.priceMode = this.priceModes.free.id;
        }
      }
    }
    return newState;
  }

  getErrors(){
    const propsError = this.props.errors ? this.props.errors : {};
    return { ...this.state.errors, ...propsError }
  }

  componentDidUpdate(prevProps){
    if(this.props.show && (this.props.show !== prevProps.show)){
      this.setState(this.getInitState(),() => {
        if(this.props.mode === EditAddPriceModal.modes.edit){
          this.onChange("label", this.state.label, true);
        }
      });
    }
  }

  onChange(prop, value, force = false){
    const state = this.getDeepState();
    const prevState = this.getDeepState();

    state[prop] = value;
    state.errors = {
      label : "",
      description : "",
      priceMode : ""
    };
    state.isValid = true;

    //Libellé
    if(isEmpty(state.label)){
      if(state.label !== prevState.label || force){
          state.errors.label = "Veuillez entrer un libellé";
      }else{
        state.errors.label = prevState.errors.label
      }
      state.isValid = false;
    }

    //Price mode
    if(state.priceMode === this.priceModes.free.id){
      if(state.priceMode !== prevState.priceMode || force){
        state.isValid = state.isValid && true;
        state.amount = "0";
      }
    }

    //Price amount
    if(state.priceMode === this.priceModes.fixed.id){
      if(state.priceMode !== prevState.priceMode){
        state.amount = "";
      }
      if(prop === "amount" || force){
        //Montant non-vide
        if(isEmpty(state.amount)){
          state.errors.amount = "Entrez un montant";
          state.isValid = false;
        }else{
          //Le montant est un nombre valide
          if(isNaN(state.amount)){
              state.amount = prevState.amount;
              if(isEmpty(state.amount)){
                state.isValid = false;
              }
          
          //Le montant est supérieur à 0 lors de la validation forcée
          }else if(!(parseFloat(state.amount) > 0) && force){
            state.errors.amount = "Entrez un montant supérieur à 0€";
            state.isValid = false;

          //Le montant ne peut contenir qu'un seul 0 en première position
          }else if(state.amount.length > 1 && parseInt(state.amount) === 0 && !(state.amount.indexOf(".") >= 0)){
            if(parseInt(state.amount[0]) === 0){
              state.amount = prevState.amount;
            }

          //Le montant ne peut pas contenir plus que 2 décimales
          }else{
            let [ int, decimal ] = state.amount.split(".");
            const parsed = parseFloat(state.amount)
            if(!isEmpty(decimal) && decimal.length > 2){
              state.amount = parsed.toFixed(2);
            }

            //Ne valide pas si le montant est 0 || 0.
            if(!(parsed > 0)){
              state.isValid = false;
            }
          }
        }
      }else{
        state.isValid = !isEmpty(state.amount);
      }
    }

    if(state.isValid && this.props.mode === EditAddPriceModal.modes.edit){
      state.isValid = (state.label !== this.props.data.label ||
        state.description !== this.props.data.description ||
        state.amount !== this.props.data.amount
      );
    }

    this.setState(state);

    return state;
  }

  cancel(){
    if(this.props.onCancel){
      this.props.onCancel();
    }
  }

  launchValid(e){
    if(e) e.preventDefault();
    
    const { isValid } = this.onChange("label", this.state.label, true);
    if(isValid){
      if(this.props.onValid){
        this.props.onValid({
          label : this.state.label,
          description : this.state.description,
          amount : this.state.amount
        });
      }
    }
  }

  getAmountInput(){
    const errors = this.getErrors();
    if(this.state.priceMode === this.priceModes.fixed.id){
      return (
      <Form.Group className="form-group mt-3">
        <Form.Label>Montant</Form.Label>
        <InputGroup>
          <Form.Control type="text" className="text-right" placeholder="234.00" value={this.state.amount} onChange={(e) => this.onChange("amount", e.target.value)}/>
          <InputGroup.Text>€</InputGroup.Text>
        </InputGroup>
        <Form.Control.Feedback type="invalid">{errors.amount}</Form.Control.Feedback>
      </Form.Group>
      )
    }else{
      return null
    }
  }

  render() {
    const errors = this.getErrors();
    return (
      <Modal show={this.props.show} onHide={this.cancel}>
        <Modal.Header closeButton={this.props.closeButton ? true : false}>{this.props.modalTitle}</Modal.Header>
        <LoaderBox show={this.props.loading ? true : false} mess={this.props.loadingMess ? this.props.loadingMess : ""}>
          <Modal.Body>
            <Form onSubmit={this.launchValid}>
              <div>
                <h4 className="mb-2">Libellé et description</h4>
                <Row>
                  <Form.Group className="col-12 form-group">
                    <Form.Label>Libellé</Form.Label>
                    <Form.Control type="text" value={this.state.label} onChange={(e) => this.onChange("label", e.target.value)}/>
                    <Form.Control.Feedback type="invalid">{errors.label}</Form.Control.Feedback>
                  </Form.Group>
                </Row>
                <Row>
                  <Form.Group className="col-12 form-group">
                    <Form.Label>Description</Form.Label>
                    <Form.Control as="textarea" value={this.state.description} placeholder="Description du tarif..." onChange={(e) => this.onChange("description", e.target.value)}/>
                    <Form.Control.Feedback type="invalid">{errors.description}</Form.Control.Feedback>
                  </Form.Group>
                </Row>
              </div>
              <div className="mt-4">
                <h4 className="mb-3">Paiement</h4>
                <div className="text-center">
                    <ButtonGroup>
                      <Button variant={this.state.priceMode === this.priceModes.fixed.id ? "primary" : "outline-primary"} onClick={() => this.onChange("priceMode", this.priceModes.fixed.id)}>
                        <label className="m-0 p-3">
                          <input type="radio" className="btn-check" name="priceMode-radio" checked={this.state.priceMode === this.priceModes.fixed.id} value={this.priceModes.fixed.id} onChange={() => {}}/>
                          Prix fixe
                        </label>
                      </Button>
                      <Button variant={this.state.priceMode === this.priceModes.free.id ? "primary" : "outline-primary"}  onClick={() => this.onChange("priceMode", this.priceModes.free.id)}>
                        <label className="m-0 p-3">
                          <input type="radio" className="btn-check" name="priceMode-radio" checked={this.state.priceMode === this.priceModes.free.id} value={this.priceModes.free.id} onChange={() => {}}/>
                          Gratuit
                        </label>
                      </Button>
                    </ButtonGroup>
                </div>
                { this.getAmountInput() }
              </div>
            </Form>
          </Modal.Body>
        </LoaderBox>
        <Modal.Footer>
          <Button variant="secondary" onClick={this.cancel}>Annuler</Button>
          <Button disabled={!this.state.isValid || this.props.loading} onClick={this.launchValid}>{this.props.mode === EditAddPriceModal.modes.add ? "Créer" : "Enregistrer"}</Button>
        </Modal.Footer>
      </Modal>
    );
  }
}

export default EditAddPriceModal;