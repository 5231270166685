import React, {Component} from 'react';
import {
    Button,
    Form,
    InputGroup,
    Row,
} from 'react-bootstrap';
import {connect} from 'react-redux';
import * as moment from 'moment';
import {apiRequest} from '../../../../../functions/api';
import {pushNotification} from '../../../../../functions/notifications';
import {
    getDeepState,
    setDeepState,
} from '../../../../../functions/react.utilities';
import {DateTimePicker} from '@material-ui/pickers';
import {getSejoursEvents__action} from '../../../../../actions/sejours.events.actions';
import {
    END_DATE_BEFORE_START,
    END_DATE_TICKETING_AFTER_START,
    START_DATE_TICKETING_AFTER_START,
} from '../../../../../constants/api/sejours';
import {updateUsers__action} from '../../../../../actions/users.actions';
import validator from "validator"
import {isEmpty} from '../../../../../functions/common';

class EventTab extends Component {
    constructor(props) {
        super(props);

        this.getDeepState = getDeepState(this);
        this.setDeepState = setDeepState(this);

        this.state = {
            editDates: false,
            editGuide: false,
            editCarPooling: false,
            isValidDates: false,
            isValidCarPoolingLink: false,
            isValidGuide: false,
            event: {
                startDate: '',
                endDate: '',
                ticketingStartDate: '',
                ticketingEndDate: '',
                guide: '0',
                carPoolingLink: '',
            },
            errors: this.getClearedErrors(),
        };

        this.cancelEditDates = this.cancelEditDates.bind(this);
        this.launchEditDates = this.launchEditDates.bind(this);
        this.launchSaveDates = this.launchSaveDates.bind(this);

        this.cancelEditGuide = this.cancelEditGuide.bind(this);
        this.launchEditGuide = this.launchEditGuide.bind(this);
        this.launchSaveGuide = this.launchSaveGuide.bind(this);
        this.onGuideChange = this.onGuideChange.bind(this);

        this.cancelEditCarPooling = this.cancelEditCarPooling.bind(this);
        this.launchEditCarPooling = this.launchEditCarPooling.bind(this);
        this.onCarPoolingLinkChange = this.onCarPoolingLinkChange.bind(this);
        this.onCarPoolingSaveClick = this.onCarPoolingSaveClick.bind(this);

        this.onFormSubmit = this.onFormSubmit.bind(this);
        this.getValueDates = this.getValueDates.bind(this);

        this.setLoader = this.props.setLoader || function () {
        };
    }

    static errors = {
        END_DATE_BEFORE_START: 'Date de fin avant date de début',
        END_DATE_TICKETING_BEFORE_START_TICKETING:
            "Date de clôture billeterie avant date d'ouverture",
        START_DATE_TICKETING_AFTER_START:
            "Date d'ouverture billeterie après date de début",
        END_DATE_TICKETING_AFTER_START:
            'Date de clôture billetrie après date de début',
    };

    getEvent() {
        let event = this.props.state.sejours.events[this.props.EID];
        event.startDate = moment(event.startDate);
        event.endDate = moment(event.endDate);
        event.ticketingStartDate = moment(event.ticketingStartDate);
        event.ticketingEndDate = moment(event.ticketingEndDate);

        return event;
    }

    getClearedErrors() {
        return {
            startDate: '',
            endDate: '',
            ticketingStartDate: '',
            ticketingEndDate: '',
        };
    }

    getGuides() {
        this.pendingRequest = apiRequest({
            url: '/sejours.guides/get',
        }).then((res) => {
            let state = {...this.state};
            if (res) {
                if (
                    res.data.http_code === 200 &&
                    res.data.status === 'success'
                ) {
                    this.props.dispatch(
                        updateUsers__action(res.data.data.guides)
                    );
                } else {
                    pushNotification({
                        type: 'error',
                        title: 'Erreur',
                        content: (
                            <p>
                                Une erreur est survenue lors de l'actualisation
                                des accompagnateurs
                            </p>
                        ),
                        icon: 'mdi mdi-close',
                    });
                }
            }
            this.setState(state);
        });
    }

    onGuideChange(e) {
        if (
            e.target.value === '0' ||
            e.target.value in this.props.state.users
        ) {
            let {guide} = this.getEvent();
            if (e.target.value !== guide) {
                this.setDeepState((s) => {
                    s.event.guide = e.target.value;
                    s.isValidGuide = true;
                });
            } else {
                this.setState({isValidGuide: false});
            }
        }
    }

    onCarPoolingLinkChange(e) {
        this.setState({
            isValidCarPoolingLink: e.target.value !== this.getEvent().carPoolingLink,
            errors: {...this.state.errors, carPoolingLink: ''},
            event: {...this.state.event, carPoolingLink: e.target.value}
        });
    }

    saveCarPooling(link) {
        if (this.state.editCarPooling && this.state.isValidCarPoolingLink) {
            this.setLoader({loading: true, mess: 'Modification en cours...'});

            apiRequest({
                url: '/sejours.events/put',
                data: {
                    EID: this.props.EID,
                    event: {
                        carPoolingLink: this.state.event.carPoolingLink,
                    },
                },
            }).then((res) => {
                let state = this.getDeepState();
                if (res.data) {
                    if (
                        res.data.http_code === 200 &&
                        res.data.status === 'success'
                    ) {
                        if (res.data.data.event) {
                            state.editCarPooling = false;
                            this.props.dispatch(
                                getSejoursEvents__action({
                                    [res.data.data.event.EID]:
                                    res.data.data.event,
                                })
                            );
                            pushNotification({
                                type: 'success',
                                title: 'Événement enregistré',
                                icon: 'mdi mdi-check',
                                content: (
                                    <p>
                                        Les modifications apportées à
                                        l'événement ont été enregistrées.
                                    </p>
                                ),
                            });
                        }
                    } else {
                        pushNotification({
                            type: 'error',
                            title: 'Erreur du serveur',
                            icon: 'mdi mdi-close',
                            content: (
                                <p>
                                    Une erreur du serveur est survenue (
                                    {res.data.status_code})
                                </p>
                            ),
                        });
                    }
                }
                this.setState(state, this.setLoader({loading: false}));
            });
        }
    }

    onCarPoolingSaveClick() {
        if (validator.isURL(this.state.event.carPoolingLink, {requireProtocol: true, protocols: ['https', 'http']})
            || isEmpty(this.state.event.carPoolingLink)) {
            if (this.state.event.carPoolingLink !== this.getEvent().carPoolingLink) {
                this.saveCarPooling(this.state.event.carPoolingLink)
            } else {
                this.setState({editCarPooling: false});
            }
        } else {
            pushNotification({
                type: "warning",
                title: "Lien non valide",
                content: <p>Le lien que vous avez entré n'est pas valide. Veuillez le vérifier.</p>,
                icon: "mdi mdi-link-variant-remove"
            });
        }
    }

    onDateChange(prop, d) {
        const state = this.getDeepState();
        const event = this.getEvent();
        if (d) {
            state.event[prop] = d;
            console.log(d);
            if (d._isValid) {
                state.errors = {
                    startDate: '',
                    endDate: '',
                    ticketingStartDate: '',
                    ticketingEndDate: '',
                };
                state.isValidDates = true;
                const checkDates = () => {
                    if (
                        state.event.startDate.isSameOrAfter(state.event.endDate)
                    ) {
                        state.errors.endDate =
                            EventTab.errors.END_DATE_BEFORE_START;
                        state.isValidDates = false;
                    }
                };
                const checkTicketingDates = () => {
                    if (
                        state.event.ticketingStartDate.isSameOrAfter(
                            state.event.ticketingEndDate
                        )
                    ) {
                        state.errors.ticketingEndDate =
                            EventTab.errors.END_DATE_TICKETING_BEFORE_START_TICKETING;
                        state.isValidDates = false;
                    } else if (
                        state.event.ticketingEndDate.isAfter(
                            state.event.startDate
                        )
                    ) {
                        state.errors.ticketingEndDate =
                            EventTab.errors.END_DATE_TICKETING_AFTER_START;
                        state.isValidDates = false;
                    }
                };
                checkDates();
                checkTicketingDates();

                if (state.isValidDates) {
                    state.isValidDates =
                        !state.event.startDate.isSame(event.startDate) ||
                        !state.event.endDate.isSame(event.endDate) ||
                        !state.event.ticketingStartDate.isSame(
                            event.ticketingStartDate
                        ) ||
                        !state.event.ticketingEndDate.isSame(
                            event.ticketingEndDate
                        );
                }
            } else {
                state.errors[prop] = 'Date invalide';
                state.isValidDates = false;
            }
            this.setState(state);
        }
        return state;
    }

    launchEditDates() {
        this.setDeepState((s) => {
            const {startDate, endDate, ticketingStartDate, ticketingEndDate} =
                this.getEvent();
            s.editDates = true;
            s.isValidDates = false;
            s.errors = this.getClearedErrors();
            s.event = {
                ...s.event,
                startDate,
                endDate,
                ticketingStartDate,
                ticketingEndDate,
            };
        });
    }

    cancelEditDates() {
        this.setDeepState((s) => {
            const {startDate, endDate, ticketingStartDate, ticketingEndDate} =
                this.getEvent();
            s.editDates = false;
            s.isValidDates = true;
            s.errors = this.getClearedErrors();
            s.event = {
                ...s.event,
                startDate,
                endDate,
                ticketingStartDate,
                ticketingEndDate,
            };
        });
    }

    launchSaveDates() {
        if (this.state.editDates && this.state.isValidDates) {
            this.setLoader({loading: true, mess: 'Modification en cours...'});

            apiRequest({
                url: '/sejours.events/put',
                data: {
                    EID: this.props.EID,
                    event: {
                        startDate: this.state.event.startDate.format(),
                        endDate: this.state.event.endDate.format(),
                        ticketingStartDate:
                            this.state.event.ticketingStartDate.format(),
                        ticketingEndDate:
                            this.state.event.ticketingEndDate.format(),
                    },
                },
            }).then((res) => {
                let state = this.getDeepState();
                if (res.data) {
                    if (res.data.http_code === 200) {
                        if (res.data.status === 'success') {
                            if (res.data.data.event) {
                                state.editDates = false;
                                this.props.dispatch(
                                    getSejoursEvents__action({
                                        [res.data.data.event.EID]:
                                        res.data.data.event,
                                    })
                                );
                                pushNotification({
                                    type: 'success',
                                    title: 'Événement enregistré',
                                    icon: 'mdi mdi-check',
                                    content: (
                                        <p>
                                            Les modifications apportées à
                                            l'événement ont été enregistrées.
                                        </p>
                                    ),
                                });
                            }
                        } else {
                            if (res.data.errors) {
                                Object.entries(res.data.errors).forEach(
                                    ([prop, code]) => {
                                        switch (prop) {
                                            case 'endDate':
                                                if (
                                                    code ===
                                                    END_DATE_BEFORE_START
                                                ) {
                                                    state.errors.endDate =
                                                        EventTab.errors.END_DATE_BEFORE_START;
                                                }
                                                break;
                                            case 'ticketingStartDate':
                                                if (
                                                    code ===
                                                    START_DATE_TICKETING_AFTER_START
                                                ) {
                                                    state.errors.startDate =
                                                        EventTab.errors.START_DATE_TICKETING_AFTER_START;
                                                }
                                                break;

                                            case 'ticketingEndDate':
                                                if (
                                                    code ===
                                                    END_DATE_BEFORE_START
                                                ) {
                                                    state.errors.ticketingEndDate =
                                                        EventTab.errors.END_DATE_TICKETING_BEFORE_START_TICKETING;
                                                } else if (
                                                    code ===
                                                    END_DATE_TICKETING_AFTER_START
                                                ) {
                                                    state.errors.ticketingEndDate =
                                                        EventTab.errors.END_DATE_TICKETING_AFTER_START;
                                                }
                                                break;
                                            default:
                                                break;
                                        }
                                    }
                                );
                            } else {
                                pushNotification({
                                    type: 'error',
                                    title: 'Erreur du serveur',
                                    icon: 'mdi mdi-close',
                                    content: (
                                        <p>
                                            Une erreur du serveur est survenue (
                                            {res.data.status_code})
                                        </p>
                                    ),
                                });
                            }
                        }
                    } else {
                        pushNotification({
                            type: 'error',
                            title: 'Erreur du serveur',
                            icon: 'mdi mdi-close',
                            content: (
                                <p>
                                    Une erreur du serveur est survenue (
                                    {res.data.status_code})
                                </p>
                            ),
                        });
                    }
                }
                this.setState(state, this.setLoader({loading: false}));
            });
        }
    }

    cancelEditGuide() {
        this.setDeepState((s) => {
            s.editGuide = false;
        });
    }

    launchEditGuide() {
        this.getGuides();
        let {guide} = this.getEvent();
        this.setDeepState((s) => {
            s.isValidGuide = false;
            s.editGuide = true;
            s.event.guide = guide;
        });
    }

    launchSaveGuide() {
        if (this.state.editGuide && this.state.isValidGuide) {
            this.setLoader({loading: true, mess: 'Modification en cours...'});

            apiRequest({
                url: '/sejours.events/put',
                data: {
                    EID: this.props.EID,
                    event: {
                        guide: this.state.event.guide,
                    },
                },
            }).then((res) => {
                let state = this.getDeepState();
                if (res.data) {
                    if (
                        res.data.http_code === 200 &&
                        res.data.status === 'success'
                    ) {
                        if (res.data.data.event) {
                            state.editGuide = false;
                            this.props.dispatch(
                                getSejoursEvents__action({
                                    [res.data.data.event.EID]:
                                    res.data.data.event,
                                })
                            );
                            pushNotification({
                                type: 'success',
                                title: 'Événement enregistré',
                                icon: 'mdi mdi-check',
                                content: (
                                    <p>
                                        Les modifications apportées à
                                        l'événement ont été enregistrées.
                                    </p>
                                ),
                            });
                        }
                    } else {
                        pushNotification({
                            type: 'error',
                            title: 'Erreur du serveur',
                            icon: 'mdi mdi-close',
                            content: (
                                <p>
                                    Une erreur du serveur est survenue (
                                    {res.data.status_code})
                                </p>
                            ),
                        });
                    }
                }
                this.setState(state, this.setLoader({loading: false}));
            });
        }
    }

    launchEditCarPooling() {
        let {carPoolingLink} = this.getEvent();
        this.setDeepState((s) => {
            s.editCarPooling = true;
            s.event.carPoolingLink = carPoolingLink;
        });
    }

    cancelEditCarPooling() {
        this.setDeepState((s) => {
            s.editCarPooling = false;
        });
    }

    getValueDates(prop) {
        if (this.state.editDates) {
            return this.state.event[prop];
        } else {
            const event = this.getEvent();
            return event[prop];
        }
    }

    getValueGuide(prop) {
        if (this.state.editGuide) {
            return this.state.event[prop];
        } else {
            const event = this.getEvent();
            return event[prop];
        }
    }

    getValueCarPoolingLink(prop) {
        if (this.state.editCarPooling) {
            return this.state.event[prop];
        } else {
            const event = this.getEvent();
            return event[prop];
        }
    }

    onFormSubmit(e) {
        e.preventDefault();
        this.onClickEditSave();
    }

    componentDidUpdate() {
        if (!this.props.selectedTab && this.state.editDates) {
            this.cancelEditDates();
            this.cancelEditCarPooling();
            this.cancelEditGuide();
        }
    }

    render() {
        return (
            <div className='event__event-tab'>
                <div className='event__event-tab__dates'>
                    <div className='event-tab__title'>
                        <h3>Dates</h3>
                        <div className='btn-container-inline-right wrap'>
                            {this.state.editDates ? (
                                <React.Fragment>
                                    <Button
                                        size='sm'
                                        variant='secondary'
                                        onClick={this.cancelEditDates}
                                    >
                                        <i className='mdi mdi-close'/>
                                        Annuler
                                    </Button>
                                    <Button
                                        size='sm'
                                        variant='primary'
                                        onClick={this.launchSaveDates}
                                        disabled={!this.state.isValidDates}
                                    >
                                        <i className='mdi mdi-content-save'/>
                                        Enregistrer
                                    </Button>
                                </React.Fragment>
                            ) : (
                                <Button
                                    size='sm'
                                    variant='primary'
                                    onClick={this.launchEditDates}
                                >
                                    <i className='mdi mdi-pencil'/>
                                    Modifier
                                </Button>
                            )}
                        </div>
                    </div>
                    <Form
                        className='model-tab-form'
                        onSubmit={this.onFormSubmit}
                    >
                        <Row>
                            <Form.Group className='col-6 form-group'>
                                <Form.Label>Date de début</Form.Label>
                                <DateTimePicker
                                    ampm={false}
                                    format='DD MMMM yyyy [à] HH[h]mm'
                                    className='form-control'
                                    inputVariant='outlined'
                                    cancelLabel='Annuler'
                                    okLabel='Valider'
                                    onChange={(d) =>
                                        this.onDateChange('startDate', d)
                                    }
                                    disabled={!this.state.editDates}
                                    value={this.getValueDates('startDate')}
                                    helperText={null}
                                />
                                <Form.Control.Feedback type='invalid'>
                                    {this.state.errors.startDate}
                                </Form.Control.Feedback>
                            </Form.Group>
                            <Form.Group className='col-6 form-group'>
                                <Form.Label>Date de fin</Form.Label>
                                <DateTimePicker
                                    ampm={false}
                                    format='DD MMMM yyyy [à] HH[h]mm'
                                    className='form-control'
                                    inputVariant='outlined'
                                    cancelLabel='Annuler'
                                    okLabel='Valider'
                                    onChange={(d) =>
                                        this.onDateChange('endDate', d)
                                    }
                                    disabled={!this.state.editDates}
                                    helperText={null}
                                    value={this.getValueDates('endDate')}
                                    minDate={this.getValueDates('startDate')
                                        .clone()
                                        .add(1, 'day')}
                                />
                                <Form.Control.Feedback type='invalid'>
                                    {this.state.errors.endDate}
                                </Form.Control.Feedback>
                            </Form.Group>
                        </Row>
                        <Row>
                            <Form.Group className='col-6 form-group'>
                                <Form.Label>
                                    Date d&apos;ouverture billeterie
                                </Form.Label>
                                <DateTimePicker
                                    ampm={false}
                                    format='DD MMMM yyyy [à] HH[h]mm'
                                    className='form-control'
                                    inputVariant='outlined'
                                    cancelLabel='Annuler'
                                    okLabel='Valider'
                                    onChange={(d) =>
                                        this.onDateChange(
                                            'ticketingStartDate',
                                            d
                                        )
                                    }
                                    disabled={!this.state.editDates}
                                    helperText={null}
                                    value={this.getValueDates(
                                        'ticketingStartDate'
                                    )}
                                    maxDate={this.getValueDates('startDate')
                                        .clone()
                                        .subtract(1, 'day')}
                                />
                                <Form.Control.Feedback type='invalid'>
                                    {this.state.errors.ticketingStartDate}
                                </Form.Control.Feedback>
                            </Form.Group>
                            <Form.Group className='col-6 form-group'>
                                <Form.Label>
                                    Date de clôture billeterie
                                </Form.Label>
                                <DateTimePicker
                                    ampm={false}
                                    format='DD MMMM yyyy [à] HH[h]mm'
                                    className='form-control'
                                    inputVariant='outlined'
                                    cancelLabel='Annuler'
                                    okLabel='Valider'
                                    onChange={(d) =>
                                        this.onDateChange('ticketingEndDate', d)
                                    }
                                    disabled={!this.state.editDates}
                                    helperText={null}
                                    value={this.getValueDates(
                                        'ticketingEndDate'
                                    )}
                                    minDate={this.getValueDates(
                                        'ticketingStartDate'
                                    )
                                        .clone()
                                        .add(1, 'day')}
                                    maxDate={this.getValueDates('startDate')}
                                />

                                <Form.Control.Feedback type='invalid'>
                                    {this.state.errors.ticketingEndDate}
                                </Form.Control.Feedback>
                            </Form.Group>
                        </Row>
                    </Form>
                </div>
                <div className='event__event-tab__carpooling'>
                    <div className='event-tab__title'>
                        <h3>Covoiturage</h3>
                        <div className='btn-container-inline-right wrap'>
                            {this.state.editCarPooling ? (
                                <React.Fragment>
                                    <Button
                                        size='sm'
                                        variant='secondary'
                                        onClick={this.cancelEditCarPooling}
                                    >
                                        <i className='mdi mdi-close'/>
                                        Annuler
                                    </Button>
                                    <Button
                                        size='sm'
                                        variant='primary'
                                        onClick={this.onCarPoolingSaveClick}
                                        disabled={
                                            !this.state.isValidCarPoolingLink
                                        }
                                    >
                                        <i className='mdi mdi-content-save'/>
                                        Enregistrer
                                    </Button>
                                </React.Fragment>
                            ) : (
                                <Button
                                    size='sm'
                                    variant='primary'
                                    onClick={this.launchEditCarPooling}
                                >
                                    <i className='mdi mdi-pencil'/>
                                    Modifier
                                </Button>
                            )}
                        </div>
                    </div>
                    <div>
                        <Form.Group className='form-group mt-4 mb-0'>
                            <Form.Label>
                                Lien vers la plateforme de covoiturage
                            </Form.Label>
                            <p className='text-primary'>
                                <i className='mdi mdi-information' style={{marginRight: 5}}/>
                                Si le lien n'est pas défini, le bouton&nbsp;
                                <b>Covoiturer</b> ne sera pas affiché sur le
                                site.
                            </p>
                            <Form.Control
                                readOnly={!this.state.editCarPooling}
                                type='text'
                                placeholder='Lien non défini'
                                value={this.getValueCarPoolingLink('carPoolingLink') || ''}
                                onChange={this.onCarPoolingLinkChange}
                                name='carPoolingLink'
                                autoComplete='off'
                            />
                            {this.getValueCarPoolingLink('carPoolingLink') ? (
                                <a
                                    href={this.getValueCarPoolingLink('carPoolingLink')}
                                    target='_blank'
                                    rel='noreferrer'
                                    className='btn btn-sm btn-secondary mt-2'
                                >
                                    <i className='mdi mdi-open-in-new'/>
                                    Tester le lien
                                </a>
                            ) : null}
                            <Form.Control.Feedback type='invalid'>
                                {this.state.errors.ticketingStartDate}
                            </Form.Control.Feedback>
                        </Form.Group>
                    </div>
                </div>
                <div className='event__event-tab__guide'>
                    <div className='event-tab__title'>
                        <h3>Accompagnateur</h3>
                        <div className='btn-container-inline-right wrap'>
                            {this.state.editGuide ? (
                                <React.Fragment>
                                    <Button
                                        size='sm'
                                        variant='secondary'
                                        onClick={this.cancelEditGuide}
                                    >
                                        <i className='mdi mdi-close'/>
                                        Annuler
                                    </Button>
                                    <Button
                                        size='sm'
                                        variant='primary'
                                        onClick={this.launchSaveGuide}
                                        disabled={!this.state.isValidGuide}
                                    >
                                        <i className='mdi mdi-content-save'/>
                                        Enregistrer
                                    </Button>
                                </React.Fragment>
                            ) : (
                                <Button
                                    size='sm'
                                    variant='primary'
                                    onClick={this.launchEditGuide}
                                >
                                    <i className='mdi mdi-pencil'/>
                                    Modifier
                                </Button>
                            )}
                        </div>
                    </div>
                    <div>
                        <p>Selectionnez l'accompagnateur sur cet événement</p>
                        <InputGroup>
                            <Form.Select
                                className='form-control event-tab__guide__select'
                                onChange={this.onGuideChange}
                                value={this.getValueGuide('guide')}
                                disabled={!this.state.editGuide}
                            >
                                {Object.values(this.props.state.users).map(
                                    (g, i) => {
                                        return g.guide ? (
                                            <option value={g.UID} key={i + 1}>
                                                {g.firstName} {g.name}
                                            </option>
                                        ) : null;
                                    }
                                )}
                                <option value={0} key={0}>
                                    Non défini
                                </option>
                            </Form.Select>
                        </InputGroup>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({state});

export default connect(mapStateToProps, null)(EventTab);
