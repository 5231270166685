import * as paymentsActions from '../../../constants/registrations.payments.actions';

export default function payments(state, action) {
    const n_state = {...state};
    switch (action.type) {
        case paymentsActions.UPDATE_PAYMENTS:
        case paymentsActions.GET_PAYMENTS:
            return {...state, payments: {...state.payments, ...action.payload}}

        case paymentsActions.DELETE_PAYMENTS:
            action.payload.forEach((PRID) => {
                delete n_state.payments[PRID];
            });
            return n_state;

        default:
            return state;
    }
}