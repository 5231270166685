import React, { Component } from "react";
import { Badge, Button, Form, FormControl, FormGroup, FormLabel, Modal, ProgressBar } from "react-bootstrap";
import { connect } from "react-redux";
import { isEmpty, isValidEmail, isValidPhone } from '../../../functions/common'
import { updateUsers__action } from '../../../actions/users.actions';
import { apiRequest, apiUploadRequest } from "../../../functions/api";
import * as apiCodes from '../../../constants/api/index';
import { pushNotification } from "../../../functions/notifications";
import FieldsetCard from "../../custom-boostrap/Fieldeset/fieldset.card";
import { withRouter } from "react-router-dom/cjs/react-router-dom";
import LoaderBox from "../../shared/loaders/loader.box";
import validator from "validator"

class userGuideComponent extends Component {

  constructor(props) {
    super(props);
    this.state = {
        inEdition : false,
        saving : false,
        savingMess : "",
        error : "",
        isValid : true,
        link : ""
    }

    this.testBioLink = this.testBioLink.bind(this);
    this.onEditClick = this.onEditClick.bind(this);
    this.onCancelClick = this.onCancelClick.bind(this);
    this.onChangeLink = this.onChangeLink.bind(this);
    this.onSaveClick = this.onSaveClick.bind(this);
  }

  get user() {
    if (this.props.app.user) {
        return ({...this.props.users[this.props.app.user] });
    } else {
        return {};
    }
  }

  get isGuide() {
    return (this.user.guide ? true : false);
  }

  get linkIsEmpty() {
    return (this.state.inEdition ? isEmpty(this.state.link) : isEmpty(this.user.bioLink));
  }

  onEditClick() {
    this.setState({
        inEdition : !this.state.inEdition,
        link : this.user.bioLink,
        error : "",
        isValid : false
    });
  }

  onCancelClick() {
    this.setState({ inEdition : false, isValid : true })
  }
  
  onChangeLink(e) {
    this.setState({
        isValid : e.target.value !== this.user.bioLink,
        error : "",
        link : e.target.value
    });
  }

  testBioLink() {
    if (this.state.inEdition && !this.linkIsEmpty) {
        window.open(this.state.link, "_blank");
    } else if (this.user.bioLink) {
        window.open(this.user.bioLink, "_blank");
    }
  }

  saveModifications(link) {
    this.setState({ saving : true, savingMess : "Enregistrement en cours..." });
    apiRequest({
        url : '/user.infos/put',
        data : {
            user : {
                UID : this.user.UID,
                bioLink : link
            }
        }
    })
    .then((res) => {
        const state = {...this.state, isValid : true };
        const errNotif = (title, content) => {
            pushNotification({
                title : title,
                type : "error",
                icon : "mdi mdi-close",
                content : <p>{content}</p>
            });
        }
        if (res.data.http_code === 200 && res.data.status === "success") {
            if (res.data.data) {
                if (res.data.data.user) {
                    this.props.dispatch(updateUsers__action({ [res.data.data.user.UID] : {...res.data.data.user} }));
                    state.inEdition = false;
                } else {
                    errNotif("Erreur inconnue","Une erreur inconnue du serveur est survenue. Veuillez réessayer.");
                }
            } else {
                errNotif("Erreur inconnue","Une erreur inconnue du serveur est survenue. Veuillez réessayer.");
            }
        } else if (res.data.errors) {
            if (res.data.errors.bioLink) {
                state.error = "Lien invalide";
            } else {
                errNotif("Erreur inconnue","Une erreur inconnue du serveur est survenue. Veuillez réessayer.");
            }
        } else {
            errNotif("Erreur inconnue","Une erreur inconnue du serveur est survenue. Veuillez réessayer.");
        }
        state.saving = false;
        this.setState(state);
    })
  }

  onSaveClick() {
    if (validator.isURL(this.state.link, { requireProtocol : true, protocols : ['https', 'http'] })
        || this.linkIsEmpty) {
        if (this.state.link !== this.user.bioLink) {
            this.saveModifications(this.state.link)
        } else {
            this.setState({ inEdition : false });
        }
    } else {
        pushNotification({
            type : "warning",
            title : "Lien non valide",
            content : <p>Le lien que vous avez entré n'est pas valide. Veuillez le vérifier.</p>,
            icon : "mdi mdi-link-variant-remove"
        });
    }
  }

  editor() {
    const error = () =>  {
        if (this.state.error) {
            return (<Form.Control.Feedback type="invalid">{this.state.error}</Form.Control.Feedback>)
        } else {
            return null;
        }
    }
    return (
        <div className="row">
            <div className="col u-infos-editor">
            <Form autoComplete="off" onSubmit={() => {}}>
                <Form.Group className="form-group mt-4 mb-0">
                    <Form.Control type="text" placeholder="Lien" value={this.state.link} onChange={this.onChangeLink} name="name"  autoComplete="off"/>
                    {error()}
                </Form.Group>
            </Form>
            </div>
        </div>
    );
  }

  classicButtons() {
    return (
        <div className="btn-container-inline-left no-margin">   
            <Button size="sm" onClick={this.onEditClick}>{this.linkIsEmpty ? "Définir un lien" : "Modifier"}</Button>
            {(!this.linkIsEmpty ?
                <Button
                    size="sm" variant="secondary"
                    onClick={this.testBioLink}
                >
                    Tester le lien actuel
                    &nbsp;<i className="mdi mdi-open-in-new"/>
                </Button>
            : null)}
        </div>
    )
  }

  editionButtons() {
    return (
        <div className="btn-container-inline-left no-margin">   
            <Button size="sm" onClick={this.onSaveClick} disabled={!this.state.isValid}>Enregistrer</Button>
            <Button size="sm" variant="secondary" onClick={this.onCancelClick}>Annuler</Button>
            <Button
                size="sm" variant="secondary"
                onClick={this.testBioLink}
                disabled={this.linkIsEmpty}
            >
                Tester le lien
                &nbsp;<i className="mdi mdi-open-in-new"/>
            </Button>
        </div>
    )
  }

  render() {
    return (
        (this.isGuide ?
        <div className="row">
            <div className="col grid-margin stretch-card">
                <div className="card profile-editor">
                    <LoaderBox show={this.state.saving} mess={this.state.savingMess} inline={true}>
                        <div className="card-body">
                            <h4 className="card-title">Accompagnement</h4>
                            <div className="row">
                                <div className="col u-infos-editor">
                                    <FieldsetCard fieldsetTitle="Ma présentation">
                                        <p>Personnalisez le lien vers lequel les inscrits pourront découvrir qui vous êtes.</p>
                                        {this.state.inEdition ? this.editionButtons() : this.classicButtons()}
                                    </FieldsetCard>
                                </div>
                            </div>
                            {this.state.inEdition ? this.editor() : null}
                        </div>
                    </LoaderBox>
                </div>
            </div>
        </div>
        : null)
    );
  }
}

const mapStateToProps = (state) => ({ app : state.app, users : state.users });

const UserGuide = connect(mapStateToProps, null)(userGuideComponent);
export default withRouter(UserGuide);
