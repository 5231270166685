import React, { Component } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { getSejoursModelsEventsPrices__action } from "../../../../actions/sejours.globals.actions";
import { getSejoursModels } from "../../../../api/sejours/models";
import { ALL_CONST } from "../../../../constants/api";
import { lengthObject, setPageTitle } from "../../../../functions/common";
import { apiRequest } from '../../../../functions/api';
import { pushNotification } from '../../../../functions/notifications';
import ConfirmDialog from "../../../../components/custom-boostrap/ConfirmDialog";
import AddModal from "./add-modal";
import { getDeepState, setDeepState } from "../../../../functions/react.utilities";
import EventsContainer from "../../../../components/pages/sejours/events/container";
import { deleteSejoursEvents__action } from "../../../../actions/sejours.events.actions";

class SejoursEvents extends Component{
    static filters = {
        all : "all"
    }
    constructor(props) {
        super(props);

        this.setDeepState = setDeepState(this);
        this.getDeepState = getDeepState(this);

        this.setDeepState = this.setDeepState.bind(this);
        this.getDeepState = this.getDeepState.bind(this);
        this.setState = this.setState.bind(this);

        this.state = {
            selection : [],
            pendingSelection : {},
            loading : false,
            modals : {
                add : false,
                delete : false
            },
            filter : SejoursEvents.filters.all,
            loaders : {}
        }

        setPageTitle("Événements | Séjours");
        this.toggleSelectAll = this.toggleSelectAll.bind(this);
        this.onSelectCard = this.onSelectCard.bind(this);
        this.onFilterChange = this.onFilterChange.bind(this);
        this.launchEditEvent = this.launchEditEvent.bind(this);
        this.launchCreateEvent = this.launchCreateEvent.bind(this);
        this.launchDeleteEvents= this.launchDeleteEvents.bind(this);
        this.cancelDeleteEvents = this.cancelDeleteEvents.bind(this);
        this.deleteEvents = this.deleteEvents.bind(this);
    }

    componentDidMount(){
        getSejoursModels({
            SIDS : ALL_CONST,
            events : true,
            prices : true
        })
        .then((res) => {
            const state = { ...this.state };
            this.props.dispatch(getSejoursModelsEventsPrices__action(res));

            Object.keys(res.events).forEach((s) => {
                state.loaders[s] = {
                    show : false,
                    mess : ""
                }
            });
            this.setState(state);
        });
    }

    loadersInit(events = false){
        events = events || this.props.sejours.events;
        const state = this.getDeepState();
        let changed = false;
        Object.keys(events).forEach((EID) => {
            if(!(EID in state.loaders)){
                state.loaders[EID] = {
                    show : false,
                    mess : ""
                }
                changed = true;
            }
        });
        if(changed){
            this.setState(state);
        }
    }

    launchCreateEvent(){
        this.setDeepState((s) => {
            s.modals.add = true;
        });
    }
    
    launchEditEvent(){

    }

    deleteEvents(){
        if(this.state.selection.length > 0){
            const state = this.getDeepState();
            this.state.selection.forEach((EID) => {
                state.loaders[EID] = { show : true, mess : "Suppression en cours..." }
            });
            state.selection = [];

            const pendingKeys = Object.keys(state.pendingSelection);
            const pendingSelectionID = (pendingKeys.length > 0) ? (parseInt(pendingKeys[pendingKeys.length - 1]) + 1).toString() : "0";
            state.pendingSelection[pendingSelectionID] = this.state.selection;
        
            state.modals.delete = false;

            apiRequest({
                url : "/sejours.events/delete",
                data : {
                    EIDS : state.pendingSelection[pendingSelectionID]
                }
            })
            .then((res) => {
                const state = this.getDeepState();
                const selection = [...this.state.pendingSelection[pendingSelectionID]];
                const errorNotification = (m) => pushNotification({
                    type : "error",
                    title : `Suppression ${(selection.length > 1) ? "des événements" : "de l'événement"} impossible`,
                    icon : "mdi mdi-close",
                    content : <p>{m}</p>
                });

                if(res.data.http_code === 200){
                    if(res.data.status === "success"){
                        if(res.data.data.deleted){
                            this.props.dispatch(deleteSejoursEvents__action(selection));
                            pushNotification({
                                type : "success",
                                title : (selection.length > 1) ? "Événements supprimés" : "Événement supprimé",
                                icon : "mdi mdi-delete-forever",
                                content : <p>{`${(selection.length > 1) ? "Événements supprimés" : "Événement supprimé"} avec succès !`}</p>
                            });
                        }else{
                            errorNotification(`Erreur du serveur (UNKNOWN)`);
                        }
                    }else{
                        errorNotification(`Erreur du serveur (${res.data.status_code})`);
                    }
                }else{
                    errorNotification(`Erreur du serveur (${res.data.status_code})`);
                }

                //Annulation des loaders
                selection.forEach((s) => {
                    state.loaders[s] = {
                        show : false,
                        mess : ""
                    }
                });
                //Nettoyage de la sélection d'attente
                delete state.pendingSelection[pendingSelectionID];
                this.setState(state);
            });

            this.setState(state);
        }
    }

    launchDeleteEvents(){
        this.setDeepState(s => s.modals.delete = true);
    }

    cancelDeleteEvents(){
        this.setDeepState(s => s.modals.delete = false);
    }

    onSelectCard({ e, EID }){
        this.setDeepState((s) => {
            if(s.selection.includes(EID)){
                s.selection.splice(s.selection.indexOf(EID), 1);
            }else{
                s.selection.push(EID);
            }
        });
    }
    toggleSelectAll(){
        const events = this.getDisplayedEvents();
        if(this.state.selection.length >= lengthObject(events)){
            this.setState({ selection : [] });
        }else{
            const selection = Object.keys(events);
            this.setState({ selection });
        }
    }

    getDisplayedEvents(filter = false){
        let events = {}
        if(!filter){
            filter = this.state.filter;
        }
        if(filter === SejoursEvents.filters.all){
            events = {...this.props.sejours.events}
        }else{
            Object.entries(this.props.sejours.events).forEach(([EID, event]) => {
                if(filter === event.sejourRelationID){
                    events[EID] = event;
                }
            });
        }
        return events;
    }

    eventsContainerRender(){
        const events = this.getDisplayedEvents();
        if(lengthObject(events) > 0){
            return (
                <EventsContainer 
                    selection={this.state.selection} 
                    loaders={this.state.loaders} 
                    onSelect={this.onSelectCard} 
                    events={events} 
                    models={this.props.sejours.models}
                /> 
            )
        }else{
            return <p className="no-models text-center"><i className="mdi mdi-information text-primary mr-2" />Aucun événement</p>
        }
    }

    filterSelectRender(){
        let options = [];
        Object.entries(this.props.sejours.models).forEach(([SID, model]) => {
            if(model.events.length > 0){
                options.push(<option value={model.SID} key={model.SID}>{model.name}</option>)
            }
        })
        return (<Form.Select className="filter-selector" value={this.state.filter} onChange={this.onFilterChange}>
            <optgroup label="Filtres généraux">
                <option value={SejoursEvents.filters.all} key="allFilter">Afficher tout</option>
            </optgroup>
            {options.length > 0 ?
                <optgroup label="Filtrer par modèle">
                    {options}
                </optgroup>
            : null }
        </Form.Select>)
    }

    onFilterChange(e){
        this.setDeepState(s => {
            s.filter = e.target.value;
            const events = this.getDisplayedEvents(s.filter);
            s.selection.forEach((PID, i) => {
                if(!(PID in events)){
                    s.selection.splice(i, 1);
                }
            });
        });
    }

    render(){
        const events = this.getDisplayedEvents();
        return (
            <React.Fragment>
                <h2 className="pb-3 mb-3 border-bottom">Événements</h2>
                <p className="text-muted font-italic"><i className="mdi mdi-alert text-danger"/> Attention, la supression d'événements étant encore icomplète et en construction, si vous supprimez un événement qui possède des inscrits, il ne sera plus récupérable.</p>
                <div className="btn-container-inline-left pb-3 mb-3 border-bottom">
                    <Button onClick={this.launchCreateEvent} disabled={this.state.loading || lengthObject(this.props.sejours.models) === 0} title={(lengthObject(this.props.sejours.models) > 0) ? "Ajouter un modèle" : "Ajoutez d'abord au moins modèle pour créer un événement"}><i className="mdi mdi-plus"/>Ajouter</Button>
                    <Button variant="info" disabled={this.state.selection.length !== 1 || this.state.modals.add} onClick={this.editEvent}><i className="mdi mdi-pencil"/>Modifier</Button>
                    <Button variant="info" disabled={!(this.state.selection.length > 0) || true} title="Indisponible"><i className="mdi mdi-content-copy"/>Dupliquer</Button>
                    <Button variant="danger" disabled={this.state.selection.length < 1 || this.state.modals.delete} onClick={this.launchDeleteEvents}><i className="mdi mdi-delete-forever"/>Supprimer</Button>
                </div>
                <div className="btn-container-inline-right pb-3 mb-3">
                    { this.filterSelectRender() }
                    <Button variant="secondary" disabled={!(lengthObject(events) > 0)} onClick={this.toggleSelectAll}><i className="mdi mdi-checkbox-multiple-marked-outline"/>Tout sélectionner</Button>
                </div>
                <div>
                    { this.eventsContainerRender() }
                </div>
                <AddModal 
                    show={this.state.modals.add}
                    parentGetDeepState={this.getDeepState}
                    parentSetDeepState={this.setDeepState}
                    parentSetState={this.setState}
                    parentState={this.state}

                />
                <ConfirmDialog 
                    show = {this.state.modals.delete}
                    modalTitle = "Supprimer des événements"
                    decline = {{ text : "Annuler", variant : "secondary", onClick : this.cancelDeleteEvents }}
                    confirm = {{ text : "Valider", variant : "danger", onClick : this.deleteEvents }}
                    onHide = {this.cancelDeleteEvents}
                >
                    <p><i className="mdi mdi-alert text-danger"/> Attention, la supression d'événements étant encore icomplète et en construction, si vous supprimez un événement qui possède des inscrits, il ne sera plus récupérable.</p>
                    <p>Supprimez des événements seulement si vous êtes certains de ce que vous faites.</p>
                    <p>Supprimer {`${(this.state.selection.length > 1) ? `les ${this.state.selection.length} événements sélectionnés` : "l'événement sélectionné"} ?`}</p>
                </ConfirmDialog>
            </React.Fragment>
        )
    }
}

const mapStateToProps = (state) => ({ sejours : state.sejours });

export default connect(mapStateToProps, null)(withRouter(SejoursEvents));