import * as registrantsActions from '../../../constants/registrations.registrants.actions';

export default function registrants(state, action){
    const n_state = { ...state };
    switch(action.type){
        case registrantsActions.GET_REGISTRANTS:
            return { ...state, registrants : { ...state.registrants, ...action.payload } }

        case registrantsActions.UPDATE_REGISTRANTS:
            action.payload.forEach((registrant) => {
                n_state.registrants[registrant.RID] = registrant;
                const RFID = registrant.registrationRelationID;
                if(RFID in n_state.registrations){
                    const registrantPos = n_state.registrations[RFID].registrants.indexOf(registrant.RID);
                    if(!(registrantPos >= 0)){
                        n_state.registrations[RFID].registrants.push(registrant.RID);
                    }
                }
            });
            return n_state;

        case registrantsActions.DELETE_REGISTRANTS:
            action.payload.forEach((RID) => {
                if(RID in n_state.registrants){
                    const RFID = n_state.registrants[RID].registrationRelationID;
                    if(RFID in n_state.registrations){
                        const registrantPos = n_state.registrations[RFID].registrants.indexOf(RID);
                        if(registrantPos >= 0){
                            n_state.registrations[RFID].registrants.splice(registrantPos, 1);
                        }
                    }
                    delete n_state.registrants[RID];
                }
            });
            return n_state;
        
        default:
            return state;
    }
}