import React, { Component } from 'react';
import { Button } from 'react-bootstrap';
import ResetPasswordForm from '../components/pages/reset-password/reset-password.form';
import { setPageTitle } from '../functions/common';

class resetPassword extends Component {
    constructor(props) {
        super(props);
        setPageTitle("Mot de passe oublié");
    }
    
    render(){
        return (
            <ResetPasswordForm />
        )
    }
};

export default resetPassword;