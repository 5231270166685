import axios from "axios";

export function apiRequest({ url, data = {}, upload = false , config = {}}){
    if(upload){
        const convertedData = new FormData();
        Object.keys(data).forEach((k) => {
            convertedData.append(k, JSON.stringify(data[k]));
        });

        if(typeof upload !== "object") {
            upload = {}
        }

        Object.keys(upload).forEach((k) => {
            convertedData.append(k, upload[k]);
        });

        data = convertedData;
    }
    return axios(
        {
            method : "post",
            withCredentials : true,
            url:`${process.env.REACT_APP_API_URL}${url}`,
            data : data,
            ...config
        }
    )
    .catch((e) => {console.error(e)})
}

export function apiUploadRequest({ url, data }, onProgressFunc, config = {}){
    return apiRequest({ 
            url, 
            data,
            config : {
                ...config,
                onUploadProgress : onProgressFunc
            }
        })
}
