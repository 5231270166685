import React, { Component } from 'react';
import { isEmpty } from '../../../functions/common';

class LoaderBox extends Component{
    variants = ["xs", "sm", "md", "lg", "xl", "xxl"];

    getLoadingMess(){
        if(!isEmpty(this.props.mess)){
            return (
                <div className="loading-message">
                    {this.props.mess}
                </div>
            )
        }
    }

    getClasses(){
        let classes = "loader-box";
        if(this.props.variant && this.variants.indexOf(this.props.variant) >= 0){
            classes += ` ${this.props.variant}`;
        }

        if(this.props.inline){
            classes += " inline";
        }

        if(this.props.radialGradient){
            classes += " radial-gradient";
        }

        if(this.props.show){
            classes += " show";
        }

        if(this.props.className && this.props.show){
            classes += ` ${this.props.className}`;
        }
        

        return classes;
    }

    render(){
        return (
            <div className={this.getClasses()}>
                {this.props.children}
                <div className={`loading-screen${this.props.show ? " show" : ""}`}>
                    <div className="spinner" />
                    {this.getLoadingMess()}
                </div>
            </div>
        );
    }
    
}

export default LoaderBox;