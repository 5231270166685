import React, { Component } from 'react'
import { Button, Form } from 'react-bootstrap';
import LoaderBox from '../../../shared/loaders/loader.box';
import { apiRequest } from "../../../../functions/api";
import ReactJson from 'react-json-view';
import { pushNotification } from '../../../../functions/notifications';
import { isEmpty } from '../../../../functions/common';
import { PaymentSummary } from "../shared/PaymentSummary";
import PaymentDetailsTable from "../shared/PaymentDetailsTable";

class RegistrationsPaymentsSearcher extends Component {
    constructor(props) {
        super(props);

        this.onChange = this.onChange.bind(this);
        this.onValid = this.onValid.bind(this);

        this.state = {
            payementRef: "",
            loading: false,
            loadingMess: "Recherche en cours",
            paymentData: {},
            results: -1
        };
    }

    getReference() {
        this.setState({
            loading: true,
            loadingMess: "Recherche en cours"
        });
        apiRequest({
            url: '/payments.reference/get',
            data: {
                reference: this.state.payementRef
            }
        })
            .then((res) => {
                const newState = {...this.state, loading: false};
                if (res.data.http_code === 200 && res.data.status === "success") {
                    newState.results = res.data.data.results;
                    newState.paymentData = res.data.data.paymentData;
                } else {
                    pushNotification({
                        type: "error",
                        title: "Erreur du serveur",
                        content: <p>Une erreur inconnue est survenue. Veuillez réessayer.</p>,
                        icon: "mdi mdi-close"
                    });
                }
                this.setState(newState);
            })
    }

    onChange(e) {
        this.setState({payementRef: e.target.value});
    }

    onValid(e) {
        e.preventDefault();
        if (!isEmpty(this.state.payementRef))
            this.getReference();
    }

    renderPayer(data) {
        return (<div className='card card-second mt-4 registrations-payment-payer'>
            <div className="card-body">
                <h5><i className='mdi mdi-account-circle'/>Identité du payeur</h5>
                <div className="row">
                    <div className="col-xl-3 col-sm-6">
                        <b>Prénom :</b> {data.payer.firstName}
                    </div>
                    <div className="col-xl-3 col-sm-6">
                        <b>Nom :</b> {data.payer.name}
                    </div>
                </div>
                <div className="row mt-2">
                    <div className="col-xl-3 col-sm-6">
                        <b>Téléphone :</b> <a className='no-link'
                                              href={`tel:+33${data.payer.phone}`}>{data.payer.phone}</a>
                    </div>
                    <div className="col-xl-3 col-sm-6">
                        <b>Email :</b> <a className='no-link' href={`mailto:${data.payer.email}`}>{data.payer.email}</a>
                    </div>
                </div>
                <div className="row mt-2">
                    <div className="col-xl-3 col-sm-6">
                        <b>Adresse :</b>
                        <address>
                            <p>{data.payer.address}</p>
                            <p>{data.payer.postalCode} - {data.payer.city}</p>
                            <p>{data.payer.country}</p>
                        </address>
                    </div>
                    <div className="col-xl-3 col-sm-6">
                        <b>Inscription associée : </b>
                        {data.associatedRFID ?
                            <a target="_blank"
                               href={`${process.env.REACT_APP_WWW_URL}/inscriptions/status/${data.associatedRFID}`}>{data.associatedRFID}</a>
                            :
                            <span className='text-muted'>aucune</span>
                        }
                    </div>
                </div>
            </div>
        </div>)
    }

    renderPaymentData(data) {

        return (
            <div className="row">
                <div className="col grid-margin stretch-card">
                    <div className="card profile-editor">
                        <div className="card-body">
                            <h4 className="card-title mb-3">Détails du paiement</h4>
                            <PaymentSummary payment={data}/>
                            {this.renderPayer(data)}
                            <div className={"mb-4"}/>
                            <PaymentDetailsTable details={data.details} total={data.amount}/>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    renderNotFound() {
        return (
            <div className="row">
                <div className="col grid-margin stretch-card">
                    <div className="card profile-editor">
                        <div className="card-body">
                            <p className='text-center'>Aucun résultat pour cette référence</p>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    render() {
        return (
            <div>
                <div className="row">
                    <div className="col grid-margin stretch-card">
                        <div className="card profile-editor">
                            <LoaderBox show={this.state.loading} mess={this.state.loadingMess} className="card" inline>
                                <div className="card-body">
                                    <h4 className="card-title">Rechercher</h4>
                                    <Form onSubmit={this.onValid}>
                                        <Form.Group className="form-group">
                                            <Form.Label>Rechercher un paiement par sa référence</Form.Label>
                                            <Form.Control
                                                type="text"
                                                placeholder="Référence du paiement"
                                                name="paymentRef"
                                                value={this.state.payementRef}
                                                onChange={this.onChange}
                                                autoComplete="off"
                                            />
                                        </Form.Group>
                                        {this.state.results === 0 ?
                                            <Form.Control.Feedback type="invalid">Aucun résultat pour cette
                                                référence</Form.Control.Feedback>
                                            :
                                            null
                                        }
                                        <div className="btn-container-inline-right mb-0">
                                            <Button
                                                variant="primary"
                                                type="button"
                                                disabled={!this.state.payementRef || this.state.loading}
                                                onClick={this.onValid}
                                            >
                                                <i className="mdi mdi-magnify"/>Rechercher
                                            </Button>
                                        </div>
                                    </Form>
                                </div>
                            </LoaderBox>
                        </div>

                    </div>
                </div>
                {this.state.results >= 1 ? this.renderPaymentData(this.state.paymentData) : null}
            </div>
        );
    };
}

export default RegistrationsPaymentsSearcher;
