import React, { Component } from "react";
import { Button, Modal } from "react-bootstrap";
import { connect } from "react-redux";
import prices from ".";
import { getSejoursEvents__action, updateSejoursEvents__action } from "../../../../../../actions/sejours.events.actions";
import { deleteSejoursPrices__action } from "../../../../../../actions/sejours.prices.actions";
import ConfirmDialog from "../../../../../../components/custom-boostrap/ConfirmDialog";
import PricesSelector from "../../../../../../components/pages/sejours/prices/PricesSelector";
import PricesTagsContainer from "../../../../../../components/pages/sejours/prices/PricesTagsContainer";
import LoaderBox from "../../../../../../components/shared/loaders/loader.box";
import { MODEL_RELATION_TYPE } from "../../../../../../constants/api/sejours";
import { apiRequest } from "../../../../../../functions/api";
import { pushNotification } from "../../../../../../functions/notifications";
import { getDeepState, setDeepState } from "../../../../../../functions/react.utilities";

class AddModal extends Component {
  constructor(props){
    super(props);
    this.state = {
      loading : false,
      loadingMess : "",
      prices : []
    }

    this.getDeepState = getDeepState(this);
    this.setDeepState = setDeepState(this);

    this.pState = () => ({ ...this.props.parentState });
    this.pSetState = this.props.parentSetState;
    this.pSetDeepState = this.props.parentSetDeepState;
    this.pGetDeepState = this.props.parentGetDeepState;

    this.pSetState = this.pSetState.bind(this);
    this.pSetDeepState = this.pSetDeepState.bind(this);
    this.pGetDeepState = this.pGetDeepState.bind(this);

    this.cancel = this.cancel.bind(this);
    this.addPrices = this.addPrices.bind(this);

    this.addPrice = this.addPrice.bind(this);
    this.removePrice = this.removePrice.bind(this);
  }

  componentDidUpdate(prevProps){
    if(this.props.show && (this.props.show !== prevProps.show)){
      this.setState({ 
        loading : false, 
        loadingMess : "",
        prices : []
      }); 
    }
  }

  getEvent(){
    return this.props.sejours.events[this.props.EID];
  }

  addPrices(){
    if(this.state.prices.length > 0){
      const newState = this.getDeepState();
      const event = this.getEvent();

      newState.loading = true;
      newState.loadingMess = "Ajout des tarifs en cours...";

      apiRequest({
          url : "/sejours.events/put",
          data : {
              EID : this.props.EID,
              event : { 
                prices : [ ...event.prices, ...this.state.prices ]
              }
          }
      })
      .then((res) => {
          const newParentState = this.pGetDeepState();
          const newState = this.getDeepState();
          const errorNotification = (m) => pushNotification({
              type : "error",
              title : `Ajout ${(this.state.prices.length > 1) ? "des tarifs" : "du tarif"} impossible`,
              icon : "mdi mdi-close",
              content : <p>{m}</p>
          });

          if(res.data.http_code === 200){
              if(res.data.status === "success"){
                  if(res.data.data.event){
                    newParentState.configs = res.data.data.event.pricesConfig;
                    this.props.dispatch(getSejoursEvents__action({ [res.data.data.event.EID] : res.data.data.event }));
                    pushNotification({
                        type : "success",
                        title : (this.state.prices.length > 1) ? "Tarifs ajoutés" : "Tarif ajouté",
                        icon : "mdi mdi-plus",
                        content : <p>{`${(this.state.prices.length > 1) ? "Tarifs ajouté" : "Tarif ajouté"} avec succès !`}</p>
                    });
                  }else{
                      errorNotification(`Erreur du serveur (UNKNOWN)`);
                  }
              }else{
                  errorNotification(`Erreur du serveur (${res.data.status_code})`);
              }
          }else{
              errorNotification(`Erreur du serveur (${res.data.status_code})`);
          }

          newState.loading = false;
          newState.loadingMess = "";

          newParentState.modals.add = false;

          this.setState(newState);
          this.pSetState(newParentState);
        });

      this.setState(newState);
    }
  }

  addPrice(PID){
    this.setDeepState((s) => {
      if(!s.prices.includes(PID)){
        s.prices.push(PID);
      }
    })
  }

  removePrice(PID){
    this.setDeepState((s) => {
      if(s.prices.includes(PID)){
        s.prices.splice(s.prices.indexOf(PID), 1);
      }
    })
  }

  getSelectedPrices(){
    let prices = {}
    this.state.prices.forEach((PID) => {
      if(PID in this.props.sejours.prices){
        prices[PID] = this.props.sejours.prices[PID];
      }
    });
    return prices;
  }

  getPricesAvailable(){
    const event = this.getEvent();
    let prices = {}
    Object.entries(this.props.sejours.prices).forEach(([PID, price]) => {
      if(!this.state.prices.includes(PID) && !event.prices.includes(PID)){
        if(price.sejourRelationType === MODEL_RELATION_TYPE){
          if(price.sejourRelationIDS.includes(event.sejourRelationID)){
            prices[PID] = price;
          }
        }else{
          prices[PID] = price;
        }
      }
    });

    return prices;
  }

  cancel(){
    this.pSetDeepState(s => s.modals.add = false);
  }

  render() {
    return (
      <Modal show={this.props.show} onHide={this.cancel}>
        <Modal.Header>Ajouter des tarifs</Modal.Header>
        <LoaderBox loading={this.state.loading} loadingMess={this.state.loadingMess} loadingInline>
            <Modal.Body>
                <p>Sélectionnez des tarifs à ajouter à l'évévement</p>
                <PricesSelector prices={this.getPricesAvailable()} onAdd={this.addPrice} />
                <div className="mt-2">
                  <PricesTagsContainer prices={this.getSelectedPrices()} onRemove={this.removePrice}/>
                </div>
            </Modal.Body>
        </LoaderBox>
        <Modal.Footer>
            <Button variant="secondary" onClick={this.cancel} disabled={this.state.loading}>Annuler</Button>
            <Button variant="primary" onClick={this.addPrices} disabled={this.state.loading || !(this.state.prices.length > 0)}>Ajouter</Button>
        </Modal.Footer>
      </Modal>
    );
      /*<ConfirmDialog 
          show = {this.props.show}
          loading={this.state.loading}
          loadingMess={this.state.loadingMess}
          loadingInline={true}
          modalTitle = "Retirer des tarifs"
          decline = {{ text : "Annuler", variant : "secondary", onClick : this.cancel, disabled : this.state.loading }}
          confirm = {{ text : "Valider", onClick : this.removePrices, disabled : this.state.loading }}
          onHide = {this.cancel}
      >
          Retirer {`${(this.props.PIDS.length > 1) ? `les ${this.props.PIDS.length} tarifs sélectionnés` : "le tarif sélectionné"} ?`}
      </ConfirmDialog>*/
  }
}

const mapStateToProps = (state) => ({ sejours : state.sejours });
export default connect(mapStateToProps, null)(AddModal);